import { Checkbox as MuiCheckbox } from '@mui/material';
import React from 'react';
import { StyledCheckboxWrapper } from './styles';
import { CheckboxProps } from './types';

export const Checkbox = (props: CheckboxProps) => {
  const { label, ...otherProps } = props;

  return (
    <StyledCheckboxWrapper>
      <MuiCheckbox {...otherProps} />
      {label}
    </StyledCheckboxWrapper>
  );
};
