import { Avatar as MuiAvatar } from '@mui/material';
import { last, split } from 'lodash';
import * as React from 'react';
import { useSelector } from '../../stores';

export const Avatar = () => {
  const { name } = useSelector(
    (state: { profileReducer: any }) => state.profileReducer,
  );

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar() {
    const firstName = split(name, ' ');
    const lastName = last(split(name, ' '));

    return {
      sx: {
        bgcolor: stringToColor(name || ''),
      },
      children: `${firstName[0][0]}${lastName ? lastName[0][0] : ''}`,
    };
  }

  return <MuiAvatar {...stringAvatar()} />;
};
