import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import {
  DASHBOARD,
  HISTORY,
  IRREGULARITIES,
  LOGIN,
  PAYMENT_METHODS,
  PIX,
  PROFILE,
  SUPPORT,
  SIGNUP,
  TICKET_PURCHASE,
  VEHICLES,
} from '.';
import { PageWrapper } from '../components';
import {
  Dashboard,
  History,
  Irregularities,
  Login,
  NotFound,
  PaymentMethods,
  TicketPurchase,
  Pix,
  Profile,
  Support,
  Signup,
  Vehicles,
} from '../pages';
import { useSelector } from '../stores';

export const RouterConfig: React.FC = () => {
  function RequireAuth({ children }: { children: JSX.Element }) {
    const navigate = useNavigate();

    const logout = () => {
      if (
        localStorage.getItem('NITROT:driverId') === null ||
        localStorage.getItem('NITROT:token') === null
      ) {
        window.removeEventListener('storage', logout);
        navigate(LOGIN);
      }
    };

    window.addEventListener('storage', logout);

    if (
      localStorage.getItem('NITROT:driverId') === null ||
      localStorage.getItem('NITROT:token') === null
    ) {
      navigate(LOGIN);
    }

    return children;
  }

  return (
    <Router>
      {/* <AuthProvider> */}
      <Routes>
        <Route index element={<Login />} />

        <Route path={SIGNUP} element={<Signup />} />

        <Route
          path={DASHBOARD}
          element={
            <RequireAuth>
              <PageWrapper>
                <Dashboard />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={HISTORY}
          element={
            <RequireAuth>
              <PageWrapper>
                <History />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={IRREGULARITIES}
          element={
            <RequireAuth>
              <PageWrapper>
                <Irregularities />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={PAYMENT_METHODS}
          element={
            <RequireAuth>
              <PageWrapper>
                <PaymentMethods />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={PIX}
          element={
            <RequireAuth>
              <PageWrapper>
                <Pix />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={PROFILE}
          element={
            <RequireAuth>
              <PageWrapper>
                <Profile />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={SUPPORT}
          element={
            <RequireAuth>
              <PageWrapper>
                <Support />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={TICKET_PURCHASE}
          element={
            <RequireAuth>
              <PageWrapper>
                <TicketPurchase />
              </PageWrapper>
            </RequireAuth>
          }
        />

        <Route
          path={VEHICLES}
          element={
            <RequireAuth>
              <PageWrapper>
                <Vehicles />
              </PageWrapper>
            </RequireAuth>
          }
        />

        {/* Using path="*"" means "match anything", so this route
        acts like a catch-all for URLs that we don't have explicit
        routes for. */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
};
