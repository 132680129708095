import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Store, authSlice, useDispatch, useSelector } from '../../stores';
import { fetchCompliance } from '../../stores/slices/Legal';
import useWindowDimensions from '../../utils/useWidowDimensions';
import { Footer } from './components/Footer';
import { FrameBars } from './components/FrameBars';
import { PixBar } from './components/PixBar';
import { TicketBar } from './components/TicketBar';
import { LGPDModal } from './modals/LGPDModal';
import { ManagePixModal } from './modals/ManagePixModal';
import { StyledContainer } from './styles';

export const drawerWidth = 260;
export const ticketBarHeight = 64;

export const PageWrapper = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { token, tokenExpiration } = useSelector((state) => state.authReducer);
  const { driverId } = useSelector((state) => state.profileReducer);
  const { ticketTimeLeft, ticketRenovate } = useSelector(
    (state) => state.ticketPurchaseReducer,
  );
  const { hasOpenPix } = useSelector((state) => state.pixReducer);
  const { browserHeight, browserWidth } = useWindowDimensions();
  const [paddingTopModifier, setPaddingTopModifier] = useState(0);

  useEffect(() => {
    if (token && tokenExpiration) {
      const intervalId = setInterval(() => {
        if (moment(tokenExpiration).isBefore(moment())) {
          localStorage.removeItem('NITROT:token');
          localStorage.removeItem('NITROT:driverId');
          window.dispatchEvent(new Event('storage'));
          Store.dispatch(authSlice.actions.logout());
          window.location.reload();
        }
        return () => clearInterval(intervalId);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [token, tokenExpiration]);

  useEffect(() => {
    const hasTicket = ticketTimeLeft > 0 || ticketRenovate ? 1 : 0;
    const hasPix = hasOpenPix ? 1 : 0;
    setPaddingTopModifier(hasTicket + hasPix);
  }, [ticketTimeLeft, ticketRenovate, hasOpenPix]);

  useEffect(() => {
    if (token && driverId) {
      dispatch(
        fetchCompliance({
          driverId: driverId.toString(),
        }),
      );
    }
  }, [driverId, token]);

  return (
    <FrameBars drawerWidth={drawerWidth}>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <TicketBar />
        <PixBar />
        <StyledContainer
          disableGutters={useMediaQuery(theme.breakpoints.only('xs'))}
          sx={{
            height: browserHeight,
            paddingTop: {
              xs: `${50 + ticketBarHeight * paddingTopModifier}px`,
              sm: `${64 + ticketBarHeight * paddingTopModifier}px`,
            },
            paddingBottom: {
              xs: 0,
              md: '40px',
            },
            width: {
              md: `${browserWidth - drawerWidth}px`,
            },
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {props.children}
        </StyledContainer>
      </div>
      <Footer />
      <ManagePixModal />
      <LGPDModal />
    </FrameBars>
  );
};
