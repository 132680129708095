// All user related database operations can be defined here.
import { api } from '..';
import {
  AcceptTermsResponse,
  ComplianceForSignupResponse,
  ComplianceResponse,
} from './types';

export * from './types';

export const fetchComplianceService = async (params: { driverId: string }) => {
  const { driverId } = params;
  return await api
    .get<ComplianceResponse>(`drivers/${driverId}/compliance`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchComplianceForSignupService = async () => {
  return await api
    .get<ComplianceForSignupResponse>(`drivers/terms`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAcceptTermsService = async (params: { driverId: string }) => {
  const { driverId } = params;
  return await api
    .put<AcceptTermsResponse>(`drivers/${driverId}/accept/terms`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
