export const vehicleTypes = [
  { label: 'Carro', value: 0 },
  { label: 'Moto', value: 1 },
  { label: 'Caminhão 2', value: 3 },
  { label: 'Caminhão 3', value: 4 },
  { label: 'Caminhão 4', value: 5 },
  { label: 'Ônibus 4', value: 6 },
];

export const vehicleTypesOptions = [
  'Carro',
  'Moto',
  'Caminhão 2',
  'Caminhão 3',
  'Caminhão 4',
  'Ônibus 4',
];
