import * as Yup from 'yup';

export const validationSchema = Yup.object({
  email: Yup.string()
    .required('Insira seu login')
    .required('O login é obrigatório'),
  password: Yup.string()
    .required('Insira sua senha')
    .required('A senha é obrigatória'),
});

export const validationRecoverySchema = Yup.object({
  email: Yup.string()
    .required('Insira seu login')
    .required('O login é obrigatório'),
});
