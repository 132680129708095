import { TextField, Autocomplete } from '@mui/material';
import React, { useState } from 'react';

export const Dropdown = (props: {
  options: string[];
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onClear?: () => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  mapRef?: React.RefObject<HTMLDivElement>;
  [x: string]: any;
}) => {
  const {
    options,
    margin,
    errorMessage,
    label,
    onChange,
    onClear,
    ...otherProps
  } = props;

  return (
    <Autocomplete
      disablePortal={false}
      options={options}
      sx={{ width: '100%', xIndex: 1000 }}
      onChange={(event, newValue: string | null, reason: string) => {
        if (reason === 'clear' && onClear) onClear();
        if (onChange) {
          newValue ? onChange(newValue) : onChange('');
        }
      }}
      renderInput={(params) => (
        <TextField
          color='primary'
          variant='standard'
          margin={margin === undefined ? 'normal' : margin}
          error={errorMessage !== undefined}
          helperText={errorMessage}
          style={{ height: 40 }}
          label={label}
          {...params}
        />
      )}
      {...otherProps}
    />
  );
};
