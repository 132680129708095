import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { orderBy } from 'lodash';
import {
  fetchCreatePaymentMethodService,
  fetchDeletePaymentMethodService,
  fetchGetBankListService,
  fetchGetPaymentMethodListService,
  fetchSetDefaultPaymentMethodService,
} from '../../services';
import { PaymentMethodsStateType } from '../types';

const initialState: PaymentMethodsStateType = {
  paymentMethodsLoading: false,
  paymentMethods: [],
  deletePaymentMethodIsOpen: false,
  createPaymentMethodIsOpen: false,
  banks: [],
};

export const fetchCreatePaymentMethod = createAsyncThunk(
  'createPaymentMethod',
  async (params: {
    driverId: string;
    number: string;
    cvv: string;
    brand: string;
    validity: string;
    holder: string;
    driverName: string;
    bankId: number;
  }) => {
    return await fetchCreatePaymentMethodService(params);
  },
);

export const fetchSetDefaultPaymentMethod = createAsyncThunk(
  'setDefaultPaymentMethod',
  async (params: { driverId: string; paymentMethodId: string }) => {
    return await fetchSetDefaultPaymentMethodService(params);
  },
);

export const fetchDeletePaymentMethod = createAsyncThunk(
  'deletePaymentMethod',
  async (params: { driverId: string; paymentMethodId: string }) => {
    return await fetchDeletePaymentMethodService(params);
  },
);

export const fetchGetPaymentMethodList = createAsyncThunk(
  'getPaymentMethodList',
  async (params: { driverId: string }) => {
    return await fetchGetPaymentMethodListService(params);
  },
);

export const fetchGetBankList = createAsyncThunk(
  'getBankList',
  async (params: { driverId: string }) => {
    return await fetchGetBankListService(params);
  },
);

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    togglePaymentMethodsLoading(state) {
      state.paymentMethodsLoading = !state.paymentMethodsLoading;
    },
    setPaymentMethods(
      state,
      action: PayloadAction<
        {
          id: number;
          number: string;
          brand: string;
          bankId: number | null;
          default: 0 | 1;
        }[]
      >,
    ) {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    },
    toggleDeletePaymentMethodIsOpen(state) {
      state.deletePaymentMethodIsOpen = !state.deletePaymentMethodIsOpen;
    },
    toggleCreatePaymentMethodIsOpen(state) {
      state.createPaymentMethodIsOpen = !state.createPaymentMethodIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreatePaymentMethod.pending, (state) => {
      return {
        ...state,
        paymentMethodsLoading: true,
      };
    }),
      builder.addCase(fetchCreatePaymentMethod.fulfilled, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      }),
      builder.addCase(fetchCreatePaymentMethod.rejected, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      });

    builder.addCase(fetchSetDefaultPaymentMethod.pending, (state) => {
      return {
        ...state,
        paymentMethodsLoading: true,
      };
    }),
      builder.addCase(fetchSetDefaultPaymentMethod.fulfilled, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      }),
      builder.addCase(fetchSetDefaultPaymentMethod.rejected, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      });

    builder.addCase(fetchDeletePaymentMethod.pending, (state) => {
      return {
        ...state,
        paymentMethodsLoading: true,
      };
    }),
      builder.addCase(fetchDeletePaymentMethod.fulfilled, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      }),
      builder.addCase(fetchDeletePaymentMethod.rejected, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      });

    builder.addCase(fetchGetPaymentMethodList.pending, (state) => {
      return {
        ...state,
        paymentMethodsLoading: true,
      };
    }),
      builder.addCase(fetchGetPaymentMethodList.fulfilled, (state, res) => {
        return {
          ...state,
          paymentMethods: orderBy(res.payload.data, ['default'], ['desc']),
          paymentMethodsLoading: false,
        };
      }),
      builder.addCase(fetchGetPaymentMethodList.rejected, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      });

    builder.addCase(fetchGetBankList.pending, (state) => {
      return {
        ...state,
        paymentMethodsLoading: true,
      };
    }),
      builder.addCase(fetchGetBankList.fulfilled, (state, res) => {
        return {
          ...state,
          banks: res.payload.data,
        };
      }),
      builder.addCase(fetchGetBankList.rejected, (state, res) => {
        return {
          ...state,
          paymentMethodsLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  togglePaymentMethodsLoading,
  toggleDeletePaymentMethodIsOpen,
  toggleCreatePaymentMethodIsOpen,
} = paymentMethodsSlice.actions;
