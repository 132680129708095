// All user related database operations can be defined here.
import { api } from '..';
import {
  AuthenticatePaymentResponse,
  BuyTicketWithPixResponse,
  CancelPixResponse,
  OpenPixResponse,
  RegularizeListWithPixResponse,
  RegularizeWithPixResponse,
} from './types';

export * from './types';

export const fetchBuyTicketWithPixService = async (params: {
  driverId: string;
  vehicleId: string;
  stayTime: string;
  price: string;
  paidPrice: string;
  routeId: string;
  paymentMethodId: string;
  driverDocument: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<BuyTicketWithPixResponse>(
      `serviceorders/driver/${driverId}/buyticket/pix`,
      otherParams,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchRegularizeWithPixService = async (params: {
  driverId: string;
  irregularityId: string;
  plate: string;
  type: string;
  entryDateTime: string;
  exitDateTime: string;
  stayTime: string;
  price: string;
  routeId: number;
  driverDocument: string;
}) => {
  const { driverId, irregularityId, ...otherParams } = params;
  return await api
    .post<RegularizeWithPixResponse>(
      `notifications/driver/${driverId}/regularize/${irregularityId}/pix`,
      otherParams,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchRegularizeListWithPixService = async (params: {
  driverId: string;
  plate: string;
  type: string;
  entryDateTime: string;
  exitDateTime: string;
  stayTime: string;
  price: number;
  routeId: number;
  vehicleId: number | null;
  irregularities: number[];
  observation: string;
  driverDocument: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<RegularizeListWithPixResponse>(
      `notifications/driver/${driverId}/regularizeall/pix`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchOpenPixService = async (params: { driverId: string }) => {
  const { driverId } = params;
  return await api
    .get<OpenPixResponse>(`drivers/${driverId}/openpayment`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAuthenticatePaymentService = async (params: {
  driverId: string;
  serviceOrderId: string;
  vehicleId: string;
}) => {
  const { driverId, serviceOrderId } = params;
  return await api
    .put<AuthenticatePaymentResponse>(
      `serviceorders/driver/${driverId}/authenticatepayment/${serviceOrderId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const fetchCancelPixService = async (params: {
  driverId: string;
  serviceOrderId: string;
}) => {
  const { driverId, serviceOrderId } = params;
  return await api
    .put<CancelPixResponse>(
      `serviceorders/driver/${driverId}/cancel/pix/payment/${serviceOrderId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
