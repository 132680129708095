import { Backdrop, BoxProps, CircularProgress } from '@mui/material';
import React from 'react';
import { StyledInnerBox, StyledOuterBox } from './styles';

interface ContentBoxProps extends BoxProps {
  loading?: boolean;
}

export const ContentBox = (props: ContentBoxProps) => {
  const { children, loading, ...otherProps } = props;

  return (
    <StyledOuterBox
      sx={{
        width: '100%',
        height: {
          xs: `100%`,
          sm: `calc(100% - 42px)`,
          md: `calc(100% - 62px)`,
        },
        marginTop: {
          xs: 0,
          sm: '20px',
        },
        padding: {
          xs: 0,
          sm: '10px',
        },
      }}
      {...otherProps}
    >
      <StyledInnerBox
        sx={{
          padding: '10px',
        }}
      >
        {children}
      </StyledInnerBox>
      <Backdrop
        sx={{ color: '#fff', zIndex: 10000 }}
        open={loading ? loading : false}
      >
        <CircularProgress color='primary' />
      </Backdrop>
    </StyledOuterBox>
  );
};
