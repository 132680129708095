import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Grid,
  Link,
  Typography,
  Button as MuiButton,
  IconButton,
  InputAdornment,
  Box,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Input, Checkbox } from '../../../components';
import { DASHBOARD } from '../../../routes';
import { TERMS_OF_USE } from '../../../services';
import {
  fetchSignup,
  profileSlice,
  useDispatch,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import { StyledStepWrapper, StyledButtonsWrapper } from './styles';
import { validationSchema } from './validations';

export const StepThree = (props: {
  handleBack: () => any;
  code: string;
  fbData: {
    facebookId: string;
    name: string;
    email: string;
  } | null;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validationType, email, phone, authLoading } = useSelector(
    (state) => state.authReducer,
  );
  const { termsOfUse } = useSelector((state) => state.legalReducer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [allTermsChecked, setAllTermsChecked] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [formData, setFormData] = useState({
    name: props.fbData ? props.fbData.name : '',
    email: props.fbData
      ? props.fbData.email
      : validationType === 'email'
      ? email
      : '',
    emailConfirmation: props.fbData
      ? props.fbData.email
      : validationType === 'email'
      ? email
      : '',
    phone:
      validationType === 'phone' && phone
        ? VMasker.toPattern(phone, '(99)99999-9999')
        : '',
    birthdate: '',
    password: '',
    passwordConfirmation: '',
    // acceptedTerms: Array<boolean>(termsOfUse.length),
    acceptedTerms: false,
  });
  const [passwordHasEightChars, setPasswordHasEightChars] = useState(false);
  const [passwordHasUpperLowerChars, setPasswordHasUpperLowerChars] =
    useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchSignup({
            name: formData.name,
            email: formData.email || '',
            cellphone: formData.phone && VMasker.toNumber(formData.phone),
            birth: moment(formData.birthdate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            ),
            password: formData.password,
            // validationId: signupId,
            validationCode: props.code,
            facebookId: props.fbData ? props.fbData.facebookId : null,
            emailValidation: validationType === 'email' ? 1 : 0,
          }),
        ).then(
          (res: {
            payload: {
              data: {
                name: string;
                birth: string;
                cellphone: string;
                email: string;
                discount: { id: number; absolut: number; percentual: number };
                id: number;
                token: string;
              };
            };
          }) => {
            handleApiReturn(
              res,
              () => {
                localStorage.setItem('NITROT:token', res.payload.data.token);
                localStorage.setItem(
                  'NITROT:driverId',
                  res.payload.data.id.toString(),
                );
                dispatch(
                  profileSlice.actions.setUser({
                    driverId: res.payload.data.id,
                    name: res.payload.data.name,
                    cellphone: res.payload.data.cellphone,
                    email: res.payload.data.email,
                    birth: res.payload.data.birth,
                    firstTicket: '',
                    picture: '',
                    termsOfUseDateTime: '',
                    passwordUpdateDateTime: '',
                  }),
                );
                navigate(DASHBOARD);
                toast.success('Bem vindo!');
              },
              null,
            );
          },
        );
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    props.handleBack();
  };

  function checkPasswordStrength(password: string) {
    // Initialize variables
    let strength = 0;

    // Check password length
    if (password.length < 8) {
      setPasswordHasEightChars(false);
    } else {
      setPasswordHasEightChars(true);
      strength += 1;
    }

    // Check for mixed case
    if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
      setPasswordHasUpperLowerChars(false);
    } else {
      setPasswordHasUpperLowerChars(true);
      strength += 1;
    }

    // Check for numbers
    if (!password.match(/\d/)) {
      setPasswordHasNumber(false);
    } else {
      setPasswordHasNumber(true);
      strength += 1;
    }

    // Check for special character
    if (!password.match(/\W/)) {
      setPasswordHasSpecialChar(false);
    } else {
      setPasswordHasSpecialChar(true);
      strength += 1;
    }

    setPasswordStrength(strength);
  }

  // useEffect(() => {
  //   let allChecked = true;
  //   map(formData.acceptedTerms, (item) => {
  //     if (!item) allChecked = false;
  //   });
  //   setAllTermsChecked(allChecked);
  // }, [formData]);

  return (
    <StyledStepWrapper>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Input
            data-testid='name'
            type='text'
            margin='normal'
            label='Nome'
            disabled={props.fbData !== null}
            value={formData.name}
            onChange={(value) => setFormData({ ...formData, name: value })}
            errorMessage={errors && errors.name}
          />
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            data-testid='email'
            type='text'
            margin='normal'
            label='Email'
            value={formData.email || ''}
            disabled={props.fbData !== null || validationType === 'email'}
            onChange={(value) => setFormData({ ...formData, email: value })}
            errorMessage={errors && errors.email}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            data-testid='emailConfirmation'
            type='text'
            margin='normal'
            label='Confirme seu email'
            disabled={props.fbData !== null || validationType === 'email'}
            value={formData.emailConfirmation || ''}
            onChange={(value) =>
              setFormData({ ...formData, emailConfirmation: value })
            }
            errorMessage={errors && errors.emailConfirmation}
          />
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            data-testid='phone'
            type='text'
            margin='normal'
            label='Telefone'
            mask='phone'
            disabled={validationType === 'phone'}
            value={formData.phone}
            onChange={(value) => setFormData({ ...formData, phone: value })}
            errorMessage={errors && errors.phone}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            data-testid='birthdate'
            type='text'
            margin='normal'
            label='Data de nascimento'
            mask='date'
            value={formData.birthdate}
            onChange={(value) => {
              setFormData({ ...formData, birthdate: value });
            }}
            errorMessage={errors && errors.birthdate}
          />
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Input
            data-testid='password'
            margin='normal'
            label='Senha'
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={(value) => {
              setFormData({ ...formData, password: value });
              checkPasswordStrength(value);
            }}
            errorMessage={errors && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            passwordStrength={passwordStrength}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Input
            data-testid='passwordConfirmation'
            type={showPasswordConfirmation ? 'text' : 'password'}
            margin='normal'
            label='Confirme sua senha'
            value={formData.passwordConfirmation}
            onChange={(value) =>
              setFormData({ ...formData, passwordConfirmation: value })
            }
            errorMessage={errors && errors.passwordConfirmation}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                    onMouseDown={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  >
                    {showPasswordConfirmation ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component='span'
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop: '20px',
            }}
          >
            <Typography
              color={
                passwordHasEightChars ? palette.success.main : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos 8 caracteres
            </Typography>
            <Typography
              color={
                passwordHasUpperLowerChars
                  ? palette.success.main
                  : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Letras maiúsculas e minúsculas
            </Typography>
            <Typography
              color={
                passwordHasNumber ? palette.success.main : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos um número
            </Typography>
            <Typography
              color={
                passwordHasSpecialChar
                  ? palette.success.main
                  : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos um caracter especial
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <StyledButtonsWrapper>
        {/* {termsOfUse.length > 0 &&
          map(termsOfUse, (item, index) => {
            return (
              <Checkbox
                label={
                  <Typography color={palette.text.main}>
                    Li e aceito
                    <MuiButton component={Link}>
                      <Typography
                        sx={{ margin: 0, padding: 0 }}
                        onClick={() =>
                          window.open(item.url, '_blank', 'noreferrer')
                        }
                      >
                        {item.title}
                      </Typography>
                    </MuiButton>
                  </Typography>
                }
                checked={formData.acceptedTerms[index]}
                errorMessage={errors && errors.acceptedTerms}
                onChange={() => {
                  const newTerms = formData.acceptedTerms;
                  newTerms[index] = !newTerms[index];
                  setFormData({
                    ...formData,
                    acceptedTerms: newTerms,
                  });
                }}
              />
            );
          })} */}
        <Checkbox
          label={
            <Typography color={palette.text.main}>
              Li e aceito os
              <MuiButton component={Link}>
                <Typography
                  sx={{ margin: 0, padding: 0 }}
                  onClick={() =>
                    window.open(TERMS_OF_USE, '_blank', 'noreferrer')
                  }
                >
                  Termos de Uso
                </Typography>
              </MuiButton>
            </Typography>
          }
          checked={formData.acceptedTerms}
          errorMessage={errors && errors.acceptedTerms}
          onChange={() =>
            setFormData({
              ...formData,
              acceptedTerms: !formData.acceptedTerms,
            })
          }
        />
        <Button
          variant='contained'
          label='Cadastrar'
          type='submit'
          // disabled={!allTermsChecked}
          disabled={!formData.acceptedTerms}
          onClick={handleSubmit}
          color='secondary'
          loading={authLoading}
          style={{ width: '90%', marginTop: 10 }}
        />
        <Button
          label='Voltar'
          style={{ width: '90%' }}
          onClick={handleReturn}
        />
      </StyledButtonsWrapper>
    </StyledStepWrapper>
  );
};
