import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { map } from 'lodash';
import moment from 'moment';
import {
  fetchComplianceService,
  fetchComplianceForSignupService,
  fetchAcceptTermsService,
} from '../../services/Legal';
import { LegalStateType } from '../types';

const initialState: LegalStateType = {
  legalLoading: false,
  passwordUpdateDateTime: null,
  termsOfUseDateTime: null,
  strongPasswordDateTime: null,
  termsOfUse: [],
  LGPDIsOpen: false,
  needsToUpdatePassword: false,
  needsToAcceptTerms: false,
};

export const fetchCompliance = createAsyncThunk(
  'compliance',
  async (params: { driverId: string }) => {
    return await fetchComplianceService(params);
  },
);

export const fetchComplianceForSignup = createAsyncThunk(
  'complianceForSignup',
  async () => {
    return await fetchComplianceForSignupService();
  },
);

export const fetchAcceptTerms = createAsyncThunk(
  'acceptTerms',
  async (params: { driverId: string }) => {
    return await fetchAcceptTermsService(params);
  },
);

export const legalSlice = createSlice({
  name: 'legal',
  initialState,
  reducers: {
    toggleLGPDIsOpen(state) {
      state.LGPDIsOpen = !state.LGPDIsOpen;
    },
    toggleNeedsToUpdatePassword(state) {
      state.needsToUpdatePassword = !state.needsToUpdatePassword;
    },
    toggleNeedsToAcceptTerms(state) {
      state.needsToAcceptTerms = !state.needsToAcceptTerms;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompliance.pending, (state) => {
      return {
        ...state,
        legalLoading: true,
      };
    }),
      builder.addCase(fetchCompliance.fulfilled, (state, res) => {
        const {
          passwordUpdateDateTime,
          termsOfUseDateTime,
          strongPasswordDateTime,
          termsOfUse,
        } = res.payload.data;

        const needsToUpdatePassword = false;
        const needsToAcceptTerms = false;

        // if (passwordUpdateDateTime === '0001-01-01 00:00:00') {
        //   needsToUpdatePassword = true;
        // } else {
        //   needsToUpdatePassword = moment(passwordUpdateDateTime).isBefore(
        //     moment(strongPasswordDateTime),
        //   );
        // }

        // if (termsOfUseDateTime === '0001-01-01 00:00:00') {
        //   needsToAcceptTerms = true;
        // } else {
        //   map(termsOfUse, (item) => {
        //     if (moment(termsOfUseDateTime).isBefore(item.updatedAt)) {
        //       needsToAcceptTerms = true;
        //     }
        //   });
        // }

        return {
          ...state,
          passwordUpdateDateTime: passwordUpdateDateTime,
          strongPasswordDateTime: strongPasswordDateTime,
          termsOfUseDateTime: termsOfUseDateTime,
          needsToUpdatePassword: needsToUpdatePassword,
          needsToAcceptTerms: needsToAcceptTerms,
          termsOfUse: termsOfUse,
          LGPDIsOpen: needsToUpdatePassword || needsToAcceptTerms,
          legalLoading: false,
        };
      }),
      builder.addCase(fetchCompliance.rejected, (state, res) => {
        return {
          ...state,
          legalLoading: false,
        };
      });

    builder.addCase(fetchComplianceForSignup.pending, (state) => {
      return {
        ...state,
        legalLoading: true,
      };
    }),
      builder.addCase(fetchComplianceForSignup.fulfilled, (state, res) => {
        return {
          ...state,
          termsOfUse: res.payload.data,
          legalLoading: false,
        };
      }),
      builder.addCase(fetchComplianceForSignup.rejected, (state, res) => {
        return {
          ...state,
          legalLoading: false,
        };
      });

    builder.addCase(fetchAcceptTerms.pending, (state) => {
      return {
        ...state,
        legalLoading: true,
      };
    }),
      builder.addCase(fetchAcceptTerms.fulfilled, (state, res) => {
        return {
          ...state,
          legalLoading: false,
        };
      }),
      builder.addCase(fetchAcceptTerms.rejected, (state, res) => {
        return {
          ...state,
          legalLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleLGPDIsOpen,
  toggleNeedsToUpdatePassword,
  toggleNeedsToAcceptTerms,
} = legalSlice.actions;
