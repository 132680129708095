import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchGetIrregularityByIdService,
  fetchGetIrregularityByPlateService,
  fetchGetIrregularityListByIdsService,
  fetchRegularizeService,
  fetchRegularizeListService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { IrregularitiesStateType } from '../types';

const initialState: IrregularitiesStateType = {
  irregularitiesLoading: false,
  irregularityDetails: null,
  irregularityListText: null,
  irregularityList: [],
  irregularityListDetails: null,
  showDetails: false,
  showListDetails: false,
  searchIsOpen: false,
  searchingByCode: true,
  searchedPlate: null,
  regularizeIsOpen: false,
  regularizeListIsOpen: false,
  createVehicleForIrregularityIsOpen: false,
  paymentMethod: null,
  goBackToList: false,
  editCPFIsOpen: false,
  editCPFLoading: false,
  selectedIds: [],
  allIdsSelected: false,
};

export const fetchGetIrregularityById = createAsyncThunk(
  'getIrregularityById',
  async (params: { driverId: string; irregularityId: string }) => {
    return await fetchGetIrregularityByIdService(params);
  },
);

export const fetchGetIrregularityByPlate = createAsyncThunk(
  'getIrregularityByPlate',
  async (params: { driverId: string; plate: string }) => {
    return await fetchGetIrregularityByPlateService(params);
  },
);

export const fetchGetIrregularityListByIds = createAsyncThunk(
  'getIrregularityListByIds',
  async (params: { driverId: string; irregularityListIds: string[] }) => {
    return await fetchGetIrregularityListByIdsService(params);
  },
);

export const fetchRegularize = createAsyncThunk(
  'regularize',
  async (params: {
    irregularityId: string;
    driverId: string;
    plate: string;
    type: string;
    entryDateTime: string;
    exitDateTime: string;
    stayTime: string;
    price: number;
    routeId: number;
    vehicleId: number | null;
    paymentMethodId: number;
    ipAddress: string;
  }) => {
    return await fetchRegularizeService(params);
  },
);

export const fetchRegularizeList = createAsyncThunk(
  'regularizeList',
  async (params: {
    driverId: string;
    plate: string;
    type: string;
    entryDateTime: string;
    exitDateTime: string;
    stayTime: string;
    price: number;
    routeId: number;
    vehicleId: number | null;
    irregularities: number[];
    paymentMethodId: number;
    observation: string;
    ipAddress: string;
  }) => {
    return await fetchRegularizeListService(params);
  },
);

export const irregularitiesSlice = createSlice({
  name: 'irregularities',
  initialState,
  reducers: {
    toggleIrregularitiesLoading(state) {
      state.irregularitiesLoading = !state.irregularitiesLoading;
    },
    toggleShowDetails(state) {
      state.showDetails = !state.showDetails;
    },
    toggleShowListDetails(state) {
      state.showListDetails = !state.showListDetails;
    },
    setGoBackToList(state) {
      state.goBackToList = !state.goBackToList;
      state.selectedIds = [];
    },
    toggleSearchIsOpen(state) {
      return {
        ...state,
        searchIsOpen: !state.searchIsOpen,
      };
    },
    toggleSearchingByCode(state) {
      return {
        ...state,
        searchingByCode: !state.searchingByCode,
      };
    },
    toggleRegularizeIsOpen(state) {
      return {
        ...state,
        regularizeIsOpen: !state.regularizeIsOpen,
      };
    },
    toggleRegularizeListIsOpen(state) {
      return {
        ...state,
        regularizeListIsOpen: !state.regularizeListIsOpen,
      };
    },
    toggleCreateVehicleForIrregularityIsOpen(state) {
      return {
        ...state,
        createVehicleForIrregularityIsOpen:
          !state.createVehicleForIrregularityIsOpen,
      };
    },
    setPaymentMethod(
      state,
      action: PayloadAction<
        | 'PIX'
        | {
            id: number;
            number: string;
            brand: string;
            bankId: number | null;
            default: 0 | 1;
          }
        | null
      >,
    ) {
      state.paymentMethod = action.payload;
    },
    toggleEditCPFIrregularitiesIsOpen(state) {
      return {
        ...state,
        editCPFIsOpen: !state.editCPFIsOpen,
      };
    },
    toggleEditCPFIrregularitiesLoading(state) {
      return {
        ...state,
        editCPFLoading: !state.editCPFLoading,
      };
    },
    setSelectedIds(state, action: PayloadAction<number[]>) {
      state.selectedIds = action.payload;
    },
    resetIrregularityListDetails(state) {
      return {
        ...state,
        selectedIds: [],
        irregularityListDetails: null,
      };
    },
    resetSearchedPlate(state) {
      return {
        ...state,
        irregularityList: [],
        selectedIds: [],
        searchedPlate: null,
      };
    },
    resetIrregularitiesList(state) {
      return {
        ...state,
        irregularityList: [],
      };
    },
    setAllIdsSelected(state, action: PayloadAction<boolean>) {
      state.allIdsSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetIrregularityById.pending, (state) => {
      return {
        ...state,
        irregularitiesLoading: true,
        irregularityDetails: null,
      };
    }),
      builder.addCase(fetchGetIrregularityById.fulfilled, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
          irregularityDetails: res.payload.data,
          selectedIds: [],
          allIdsSelected: false,
        };
      }),
      builder.addCase(fetchGetIrregularityById.rejected, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      });

    builder.addCase(fetchGetIrregularityByPlate.pending, (state) => {
      return {
        ...state,
        irregularitiesLoading: true,
        irregularityList: [],
        selectedIds: [],
        searchedPlate: null,
      };
    }),
      builder.addCase(fetchGetIrregularityByPlate.fulfilled, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
          irregularityListText: res.payload.data.text,
          irregularityList: res.payload.data.list,
          searchedPlate: res.meta.arg.plate,
          selectedIds: [],
          allIdsSelected: false,
        };
      }),
      builder.addCase(fetchGetIrregularityByPlate.rejected, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
          searchedPlate: res.meta.arg.plate,
        };
      });

    builder.addCase(fetchGetIrregularityListByIds.pending, (state) => {
      return {
        ...state,
        irregularitiesLoading: true,
        irregularityListDetails: null,
      };
    }),
      builder.addCase(fetchGetIrregularityListByIds.fulfilled, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
          irregularityListDetails: res.payload.data,
          selectedIds: [],
          allIdsSelected: false,
        };
      }),
      builder.addCase(fetchGetIrregularityListByIds.rejected, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      });

    builder.addCase(fetchRegularize.pending, (state) => {
      return {
        ...state,
        irregularitiesLoading: true,
      };
    }),
      builder.addCase(fetchRegularize.fulfilled, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      }),
      builder.addCase(fetchRegularize.rejected, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      });

    builder.addCase(fetchRegularizeList.pending, (state) => {
      return {
        ...state,
        irregularitiesLoading: true,
      };
    }),
      builder.addCase(fetchRegularizeList.fulfilled, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      }),
      builder.addCase(fetchRegularizeList.rejected, (state, res) => {
        return {
          ...state,
          irregularitiesLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIrregularitiesLoading,
  toggleShowDetails,
  setGoBackToList,
  setPaymentMethod,
  toggleSearchIsOpen,
  toggleSearchingByCode,
  toggleRegularizeIsOpen,
  toggleRegularizeListIsOpen,
  toggleEditCPFIrregularitiesIsOpen,
  toggleEditCPFIrregularitiesLoading,
  setSelectedIds,
  resetIrregularityListDetails,
  resetSearchedPlate,
  resetIrregularitiesList,
  setAllIdsSelected,
} = irregularitiesSlice.actions;
