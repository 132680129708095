import * as Yup from 'yup';

const strongPasswordRegExp =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W]).{8,}$/;

export const validationCPFSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Insira um cpf')
    .min(14, 'Insira um cpf válido')
    .test('cpf-test', 'Insira um cpf válido', function (value) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      const cpf = value?.replace(/[^\d]+/g, '');
      if (cpf == '') return false;

      // Elimina CPFs invalidos conhecidos
      if (
        cpf?.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      ) {
        return false;
      }
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(10))) return false;
      return true;
    }),
});

export const validationPasswordSchema = Yup.object({
  oldPassword: Yup.string().required('Insira uma senha'),
  newPassword: Yup.string()
    .required('A nova senha é obrigatória')
    .matches(strongPasswordRegExp, {
      message: 'A senha precisa ter formato especificado',
      excludeEmptyString: true,
    }),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas devem ser iguais')
    .required('Confirme a senha'),
});
