import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { fetchSendMessageService } from '../../services/Support';
import { SupportStateType } from '../types';

const initialState: SupportStateType = {
  supportLoading: false,
};

export const fetchSendMessage = createAsyncThunk(
  'sendMessage',
  async (params: { driverId: string; message: string }) => {
    return await fetchSendMessageService(params);
  },
);

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    toggleSupportLoading(state) {
      state.supportLoading = !state.supportLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSendMessage.pending, (state) => {
      return {
        ...state,
        supportLoading: true,
      };
    }),
      builder.addCase(fetchSendMessage.fulfilled, (state, res) => {
        return {
          ...state,
          supportLoading: false,
        };
      }),
      builder.addCase(fetchSendMessage.rejected, (state, res) => {
        return {
          ...state,
          supportLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { toggleSupportLoading } = supportSlice.actions;
