export const DASHBOARD = '/dashboard';
export const LOGIN = '/';
export const HISTORY = '/historico_de_uso';
export const IRREGULARITIES = '/irregularidades';
export const PAYMENT_METHODS = '/meios_de_pagamento';
export const PIX = '/pix';
export const PROFILE = '/perfil';
export const SUPPORT = '/suporte';
export const SIGNUP = '/cadastro';
export const TICKET_PURCHASE = '/compra';
export const VEHICLES = '/veiculos';
