import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchGetHistoryListService,
  fetchGetServiceOrderService,
  fetchSendReceiptService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { HistoryStateType } from '../types';
import { toast } from 'react-toastify';

const initialState: HistoryStateType = {
  historyLoading: false,
  history: [],
  pageOffset: 1,
  pageSize: 3,
  ticketDetails: null,
  ticketDetailsIsLoading: false,
  sendEmailIsOpen: false,
  ticketDetailsIsOpen: false,
  sendTicketByEmailLoading: false,
};

export const fetchGetHistoryList = createAsyncThunk(
  'getHistoryList',
  async (
    params: { driverId: string; size?: number; reset?: boolean },
    { getState },
  ) => {
    const state = getState() as RootState;
    return await fetchGetHistoryListService({
      ...params,
      offset: params.reset ? 1 : state.historyReducer.pageOffset + 1,
      size: state.historyReducer.pageSize,
    });
  },
);

export const fetchGetTicketDetails = createAsyncThunk(
  'getTicketDetails',
  async (params: { driverId: string; ticketId: string }) => {
    return await fetchGetServiceOrderService(params);
  },
);

export const fetchSendReceipt = createAsyncThunk(
  'sendReceipt',
  async (params: {
    driverId: string;
    ticketId: string;
    email: string;
    name: string;
  }) => {
    return await fetchSendReceiptService(params);
  },
);

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    toggleHistoryLoading(state) {
      state.historyLoading = !state.historyLoading;
    },
    toggleSendEmailIsOpen(state) {
      state.sendEmailIsOpen = !state.sendEmailIsOpen;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    resetOffset(state) {
      state.pageOffset = 1;
    },
    toggleTicketDetailsIsOpen(state) {
      state.ticketDetailsIsOpen = !state.ticketDetailsIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetHistoryList.pending, (state) => {
      return {
        ...state,
        historyLoading: true,
      };
    }),
      builder.addCase(fetchGetHistoryList.fulfilled, (state, res) => {
        if (res.meta.arg.reset) {
          return {
            ...state,
            pageOffset: 1,
            history: res.payload.data,
            historyLoading: false,
          };
        } else {
          return {
            ...state,
            pageOffset: state.pageOffset + 1,
            history: [...state.history, ...res.payload.data],
            historyLoading: false,
          };
        }
      }),
      builder.addCase(fetchGetHistoryList.rejected, (state, res) => {
        return {
          ...state,
          historyLoading: false,
        };
      });

    builder.addCase(fetchGetTicketDetails.pending, (state) => {
      return {
        ...state,
        ticketDetailsIsLoading: true,
      };
    }),
      builder.addCase(fetchGetTicketDetails.fulfilled, (state, res) => {
        return {
          ...state,
          ticketDetails: res.payload.data,
          ticketDetailsIsLoading: false,
        };
      }),
      builder.addCase(fetchGetTicketDetails.rejected, (state, res) => {
        return {
          ...state,
          ticketDetailsIsLoading: false,
        };
      });

    builder.addCase(fetchSendReceipt.pending, (state) => {
      return {
        ...state,
        historyLoading: true,
      };
    }),
      builder.addCase(fetchSendReceipt.fulfilled, (state, res) => {
        toast.success(res.payload.data);
        return {
          ...state,
          historyLoading: false,
        };
      }),
      builder.addCase(fetchSendReceipt.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          historyLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleHistoryLoading,
  toggleSendEmailIsOpen,
  resetOffset,
  toggleTicketDetailsIsOpen,
} = historySlice.actions;
