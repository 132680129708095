import { toUpper, uniq } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  irregularitiesSlice,
  fetchCreateVehicle,
  fetchGetVehicleList,
  fetchGetIrregularityById,
} from '../../../stores';
import { handleApiReturn, handleErrors } from '../../../utils';
import { vehicleTypesOptions } from '../../Vehicles/resources';
import { validationSchema } from '../../Vehicles/validations';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledModalWrapper,
} from '../styles';

const vehicleModelOptions = require('../../../assets/brands.json');

export const CreateVehicleForIrregularityModal = (props: {
  resetIrregularities: () => void;
}) => {
  const dispatch = useDispatch();
  const {
    createVehicleForIrregularityIsOpen,
    irregularityDetails,
    irregularitiesLoading,
  } = useSelector((state) => state.irregularitiesReducer);
  const { driverId } = useSelector((state) => state.profileReducer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    plate: irregularityDetails?.irregularity.plate || '',
    model: '',
    type: '',
  });

  const handleClose = () => {
    setFormData({
      plate: irregularityDetails?.irregularity.plate || '',
      model: '',
      type: '',
    });
    dispatch(
      irregularitiesSlice.actions.toggleCreateVehicleForIrregularityIsOpen(),
    );
    props.resetIrregularities();
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchCreateVehicle({
            driverId: localStorage.getItem('NITROT:driverId') || '',
            plate: formData.plate,
            model: formData.model,
            type: formData.type,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(
                fetchGetVehicleList({
                  driverId: localStorage.getItem('NITROT:driverId') || '',
                }),
              );
              toast.success('Veículo criado com sucesso.');
              if (irregularityDetails?.irregularity?.id) {
                dispatch(
                  fetchGetIrregularityById({
                    driverId: driverId.toString(),
                    irregularityId:
                      irregularityDetails.irregularity.id.toString(),
                  }),
                ).then((res: any) => {
                  handleApiReturn(
                    res,
                    () => {
                      dispatch(irregularitiesSlice.actions.toggleShowDetails());
                    },
                    null,
                  );
                });
              }
              handleClose();
            },
            null,
          );
          props.resetIrregularities();
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <Modal
      open={createVehicleForIrregularityIsOpen}
      onClose={handleClose}
      title='Cadastro de veículo'
    >
      <StyledModalWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='plate'
            margin='normal'
            label='Placa'
            type='text'
            mask='plate'
            value={formData.plate}
            onChange={(value) => {
              setFormData({ ...formData, plate: value });
            }}
            errorMessage={errors && errors.plate}
            disabled
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            options={uniq(vehicleModelOptions.brands)}
            data-testid='model'
            margin='normal'
            label='Marca/ modelo'
            type='text'
            value={formData.model}
            onChange={(value) => setFormData({ ...formData, model: value })}
            errorMessage={errors && errors.model}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            options={vehicleTypesOptions}
            data-testid='type'
            margin='normal'
            label='Tipo de veículo'
            type='text'
            value={formData.type}
            onChange={(value) => setFormData({ ...formData, type: value })}
            errorMessage={errors && errors.type}
          />
        </StyledInputWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={irregularitiesLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledModalWrapper>
    </Modal>
  );
};
