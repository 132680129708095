import { Grid, Typography } from '@mui/material';
import { map } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import packageJson from '../../../package.json';
import { Button, ContentBox, Dropdown, Input } from '../../components';
import { fetchSendMessage, useDispatch, useSelector } from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn, handleErrors } from '../../utils';
import { supportOptions } from './resources';
import { validationSchema } from './validations';

export const Support: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { supportLoading } = useSelector((state) => state.supportReducer);
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const MESSAGE_LIMIT = 255;

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const message = formData.subject
          ? `${formData.subject} - ${formData.message} (website - v${packageJson.version})`
          : '';
        dispatch(
          fetchSendMessage({
            driverId: driverId.toString(),
            message: message,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              toast.success('Mensagem enviada com sucesso.');
            },
            null,
          );
          setFormData({
            subject: '',
            message: '',
          });
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <ContentBox loading={supportLoading}>
      <Grid
        container
        rowSpacing={1}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            color='primary'
            sx={{ fontSize: '20px', fontWeight: 'bold' }}
          >
            Precisa de ajuda?
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            color={palette.text.main}
            sx={{
              fontSize: {
                xs: '15px',
                md: '18px',
              },
            }}
          >
            Escolha o assunto e faça uma breve descrição para nos ajudar a
            solucionar o problema.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography
            color={palette.text.main}
            sx={{
              fontSize: {
                xs: '13px',
                md: '16px',
              },
            }}
          >
            niteroirotativo@niteroirotativo.com.br
            <br />
            (21) 97673-4294
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            options={supportOptions}
            data-testid='subject'
            margin='normal'
            label='Assunto'
            type='text'
            value={formData.subject}
            onChange={(value) => {
              setErrors({});
              setFormData({ ...formData, subject: value });
            }}
            errorMessage={errors && errors.subject}
          />
        </Grid>
        <Grid item xs={12} sx={{ height: 168, marginBottom: '20px' }}>
          <Input
            data-testid='message'
            type='text'
            margin='normal'
            label='Mensagem'
            multiline
            counter
            inputProps={{ maxLength: MESSAGE_LIMIT }}
            rows={6}
            value={formData.message}
            variant='standard'
            onChange={(value) => {
              setErrors({});
              setFormData({ ...formData, message: value });
            }}
            errorMessage={errors && errors.message}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          margin: 'auto',
          paddingTop: '20px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant='contained'
          label='Enviar'
          type='submit'
          onClick={handleSubmit}
          color='secondary'
          loading={supportLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
    </ContentBox>
  );
};
