// All user related database operations can be defined here.
import { api } from '..';
import {
  GetHistoryListResponse,
  GetServiceOrderResponse,
  SendReceiptResponse,
} from './types';

export * from './types';

export const fetchGetHistoryListService = async (params: {
  driverId: string;
  offset: number;
  size: number;
}) => {
  const { driverId, offset, size } = params;
  return await api
    .get<GetHistoryListResponse>(
      `serviceorders/driver/${driverId}?offset=${offset}&size=${size}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetServiceOrderService = async (params: {
  driverId: string;
  ticketId: string;
}) => {
  const { driverId, ticketId } = params;
  return await api
    .get<GetServiceOrderResponse>(
      `serviceorders/driver/${driverId}/${ticketId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchSendReceiptService = async (params: {
  driverId: string;
  ticketId: string;
  email: string;
  name: string;
}) => {
  const { driverId, ticketId, ...otherParams } = params;
  return await api
    .post<SendReceiptResponse>(
      `serviceorders/driver/${driverId}/sendreceipt/${ticketId}`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
