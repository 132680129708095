import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Modal } from '../../../components';
import { PIX } from '../../../routes';
import {
  useDispatch,
  useSelector,
  irregularitiesSlice,
  fetchRegularizeListWithPix,
  fetchRegularizeList,
  fetchGetIrregularityByPlate,
} from '../../../stores';
import { handleApiReturn, handleDateTime, handleMoney } from '../../../utils';
import { StyledModalWrapper, StyledBox } from '../styles';
import 'react-credit-cards/es/styles-compiled.css';
import { map } from 'lodash';

export const RegularizeListModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const {
    irregularityListDetails,
    paymentMethod,
    regularizeListIsOpen,
    irregularitiesLoading,
    searchedPlate,
  } = useSelector((state) => state.irregularitiesReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);

  const handleClose = () => {
    dispatch(irregularitiesSlice.actions.toggleRegularizeListIsOpen());
  };

  const handleSubmit = async () => {
    if (driverId && irregularityListDetails && paymentMethod) {
      if (paymentMethod === 'PIX') {
        dispatch(
          fetchRegularizeListWithPix({
            driverId: driverId.toString(),
            plate: irregularityListDetails.payment.plate,
            type: irregularityListDetails.payment.type,
            entryDateTime: irregularityListDetails.payment.entryDateTime,
            exitDateTime: irregularityListDetails.payment.exitDateTime,
            stayTime: irregularityListDetails.payment.stayTime,
            price: irregularityListDetails.payment.price,
            routeId: irregularityListDetails.payment.routeId,
            vehicleId: irregularityListDetails.payment.vehicleId,
            irregularities: map(
              irregularityListDetails.irregularities,
              (item) => {
                return item.id;
              },
            ),
            observation: irregularityListDetails.payment.observation,
            driverDocument: VMasker.toNumber(cpf).toString(),
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(
                irregularitiesSlice.actions.toggleRegularizeListIsOpen(),
              );
              dispatch(irregularitiesSlice.actions.toggleShowListDetails());
              toast.success('Pix gerado com sucesso.');
              navigate(PIX);
            },
            null,
          );
        });
      } else {
        await axios.get('https://api.ipify.org/?format=json').then((res) => {
          const ipAddress = res.data.ip;
          dispatch(
            fetchRegularizeList({
              driverId: driverId.toString(),
              plate: irregularityListDetails.payment.plate,
              type: irregularityListDetails.payment.type,
              entryDateTime: irregularityListDetails.payment.entryDateTime,
              exitDateTime: irregularityListDetails.payment.exitDateTime,
              stayTime: irregularityListDetails.payment.stayTime,
              price: irregularityListDetails.payment.price,
              routeId: irregularityListDetails.payment.routeId,
              vehicleId: irregularityListDetails.payment.vehicleId,
              paymentMethodId: paymentMethod.id,
              irregularities: map(
                irregularityListDetails.irregularities,
                (item) => {
                  return item.id;
                },
              ),
              observation: irregularityListDetails.payment.observation,
              ipAddress: ipAddress,
            }),
          ).then((res: any) => {
            searchedPlate &&
              handleApiReturn(
                res,
                () => {
                  dispatch(
                    fetchGetIrregularityByPlate({
                      driverId: driverId.toString(),
                      plate: searchedPlate,
                    }),
                  ).then((res: any) => {
                    handleApiReturn(
                      res,
                      () => {
                        dispatch(
                          irregularitiesSlice.actions.toggleRegularizeListIsOpen(),
                        );
                        dispatch(
                          irregularitiesSlice.actions.toggleShowListDetails(),
                        );
                        toast.success('Irregularidades pagas com sucesso.');
                      },
                      null,
                    );
                  });
                },
                null,
              );
          });
        });
      }
    }
  };

  return (
    <Modal
      open={regularizeListIsOpen}
      onClose={handleClose}
      title='Regularização das irregularidades'
    >
      <StyledModalWrapper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Placa:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityListDetails?.payment?.plate}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Entrada:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityListDetails?.payment?.entryDateTime &&
                handleDateTime(irregularityListDetails?.payment?.entryDateTime)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Saída:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityListDetails?.payment?.exitDateTime &&
                handleDateTime(irregularityListDetails?.payment?.exitDateTime)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Período:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityListDetails?.payment?.stayTime &&
                irregularityListDetails?.payment?.stayTime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Irregularidades:
            </Typography>
          </Grid>
          <StyledBox>
            <Grid item sx={{ marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
              <Typography color='primary' textAlign='center' gutterBottom>
                {map(irregularityListDetails?.irregularities, (item) => {
                  return `${item.id.toString()}, `;
                })}
              </Typography>
            </Grid>
          </StyledBox>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Valor:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityListDetails?.payment?.price &&
                handleMoney(irregularityListDetails?.payment?.price)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Meio de pagamento:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {paymentMethod && typeof paymentMethod !== 'string'
                ? `**** ${paymentMethod.number} (${paymentMethod.brand})`
                : 'PIX'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Button
            variant='contained'
            label='Pagar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={irregularitiesLoading || pixLoading}
            style={{ width: '100%' }}
          />
        </Grid>
      </StyledModalWrapper>
    </Modal>
  );
};
