import { Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  fetchGetTicketDetails,
  historySlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleMoney } from '../../utils';

export const ServiceOrderBox = (props: {
  id: string;
  plate: string;
  type: string;
  registerDateTime: string;
  entryDateTime: string;
  exitDateTime: string;
  situation: string;
  paymentType: string;
  price: number;
  paidPrice: number;
  routeId: number;
  routeName: string;
  routeImg: string;
  stayTime: string;
}) => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);

  const handleTicketDetails = () => {
    dispatch(
      fetchGetTicketDetails({
        driverId: driverId.toString(),
        ticketId: props.id,
      }),
    );
    dispatch(historySlice.actions.toggleTicketDetailsIsOpen());
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
      <Card
        sx={{
          borderRadius: '10px',
          minWidth: {
            xs: '100%',
            sm: 205,
          },
          height: 260,
          boxShadow: 5,
          cursor: 'pointer',
        }}
        onClick={handleTicketDetails}
      >
        <Grid
          item
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
          }}
        >
          <Grid spacing={1}>
            <CardMedia
              component='img'
              height='120'
              image={`data:image/png;base64,${props.routeImg}`}
            />
            <Typography
              gutterBottom
              color='primary'
              component='div'
              sx={{
                fontSize: '1.1em',
                marginTop: 1,
                marginLeft: 2,
                marginRight: 1,
              }}
              noWrap
            >
              {props.routeName}
            </Typography>
          </Grid>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  {moment(props.registerDateTime).format('DD/MM/yyyy HH:mm:ss')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={palette.success.main}
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  {handleMoney(props.price)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                    width: '40px',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  Placa: {props.plate}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                    maxWidth: '100px',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  Período: {props.stayTime}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
};
