import { styled, Box, Container } from '@mui/material';
import { palette } from '../../theme';

export const StyledWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: palette.background.main,
}));

export const StyledMainBox = styled(Box)(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
}));

export const StyledStepsWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 30,
}));

export const StyledBodyWrapper = styled(Container)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'scroll',
  marginTop: '10px',
}));

export const StyledButtonsWrapper = styled(Container)(() => ({
  width: '100%',
}));

export const StyledForm = styled('div')(() => ({
  marginTop: 20,
  height: '100%',
  width: '30%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledButtonsBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 20,
}));

export const StyledFormGroup = styled('form')(() => ({
  width: '100%',
  marginTop: 2,
  marginBottom: 2,
}));

export const StyledLogo = styled('img')(() => ({
  width: 150,
}));
