/* eslint-disable pii/no-phone-number */
import { Grid, Box, Typography } from '@mui/material';
import { map } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, ContentBox } from '../../components';
import { IRREGULARITIES } from '../../routes';
import {
  fetchGetIrregularityByPlate,
  fetchGetIrregularityListByIds,
  irregularitiesSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn } from '../../utils';
import { IrregularityCard } from './IrregularityCard';
import { IrregularityDetails } from './IrregularityDetails';
import { IrregularityListDetails } from './IrregularityListDetails';
import { CreateVehicleForIrregularityModal } from './modals/CreateVehicleForIrregularityModal';
import { EditCPFModal } from './modals/EditCPFModal';
import { RegularizeListModal } from './modals/RegularizeListModal';
import { RegularizeModal } from './modals/RegularizeModal';
import { SearchIrregularitiesModal } from './modals/SearchIrregularitiesModal';

export const Irregularities: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);
  const {
    irregularityList,
    showDetails,
    showListDetails,
    selectedIds,
    irregularitiesLoading,
    irregularityDetails,
    irregularityListText,
    searchingByCode,
    searchedPlate,
    allIdsSelected,
  } = useSelector((state) => state.irregularitiesReducer);
  const boxRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(10);
  const [displayedIds, setDisplayedIds] = useState<
    {
      brand: string;
      id: number;
      observation: string | null;
      plate: string;
      plateImg: string;
      registerDateTime: string;
      type: string;
    }[]
  >([]);

  useEffect(() => {
    if (location.pathname === IRREGULARITIES) {
      handleResetPage();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (showDetails) {
      if (!irregularityDetails || !irregularityDetails.payment) {
        handleBuggedIrregularities();
      }
    }
  }, [showDetails, showListDetails]);

  useEffect(() => {
    // Load the initial cards
    const initialIds = irregularityList.slice(0, cardsPerPage);
    setDisplayedIds(initialIds);
  }, [irregularityList, cardsPerPage]);

  const loadMoreCards = useCallback(() => {
    const nextPage = currentPage + 1;
    const newIds = irregularityList.slice(0, nextPage * cardsPerPage);
    setDisplayedIds(newIds);
    setCurrentPage(nextPage);
  }, [currentPage, irregularityList, cardsPerPage]);

  const handleScroll = useCallback(() => {
    const container = boxRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      // Load more cards when the user scrolls near the bottom of the container (e.g., 100px from bottom)
      if (scrollHeight - scrollTop - clientHeight < 100) {
        loadMoreCards();
      }
    }
  }, [loadMoreCards]);

  useEffect(() => {
    const box = boxRef.current;
    if (box) {
      box.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (box) {
        box.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handlePayIrregularities = () => {
    // eslint-disable-next-line lodash/prefer-invoke-map
    const selectedIdsAsString = map(selectedIds, (item) => {
      return item.toString();
    });
    dispatch(
      fetchGetIrregularityListByIds({
        driverId: driverId.toString(),
        irregularityListIds: selectedIdsAsString,
      }),
    ).then((res: any) => {
      if (!res.payload.data.payment) {
        handleBuggedIrregularities();
      } else {
        handleApiReturn(
          res,
          () => {
            dispatch(irregularitiesSlice.actions.toggleShowListDetails());
          },
          null,
        );
      }
    });
  };

  const handleResetPage = () => {
    vehicles.length > 0
      ? dispatch(
          fetchGetIrregularityByPlate({
            driverId: driverId.toString(),
            plate: vehicles[0].plate,
          }),
        )
      : dispatch(irregularitiesSlice.actions.resetSearchedPlate());
    showDetails && dispatch(irregularitiesSlice.actions.toggleShowDetails());
    showListDetails &&
      dispatch(irregularitiesSlice.actions.toggleShowListDetails());
  };

  const handleBuggedIrregularities = () => {
    dispatch(
      irregularitiesSlice.actions.toggleCreateVehicleForIrregularityIsOpen(),
    );
    toast.warn(
      'Irregularidade sem categoria. Por favor cadastre o veículo relacionado à essa irregularidade para continuar',
    );
    if (showDetails) {
      dispatch(irregularitiesSlice.actions.toggleShowDetails());
    }
  };

  const handleCheck = () => {
    let newSelectedIds: any[] = [];
    if (!allIdsSelected) {
      map(irregularityList, (item) => {
        newSelectedIds = [...newSelectedIds, item.id];
      });
      dispatch(irregularitiesSlice.actions.setSelectedIds(newSelectedIds));
      dispatch(irregularitiesSlice.actions.setAllIdsSelected(true));
    } else {
      dispatch(irregularitiesSlice.actions.setSelectedIds(newSelectedIds));
      dispatch(irregularitiesSlice.actions.setAllIdsSelected(false));
    }
  };

  return (
    <ContentBox loading={irregularitiesLoading || pixLoading}>
      {!showDetails && !showListDetails && (
        <Grid
          xs={12}
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Box
            ref={boxRef}
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              overflowY: 'auto',
              padding: '0 14px 0 14px',
            }}
          >
            {irregularityListText && (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography color={palette.text.main}>
                  {irregularityListText}
                </Typography>
              </Grid>
            )}
            {searchedPlate && (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography
                  color={palette.text.main}
                  sx={{ fontWeight: 'bold' }}
                >
                  Placa: {searchedPlate}
                </Typography>
              </Grid>
            )}
            <Checkbox
              label={
                <Typography color={palette.text.main}>
                  Selecionar todas as irregularidades
                </Typography>
              }
              checked={allIdsSelected}
              onChange={() => {
                handleCheck();
              }}
            />
            {irregularityList.length === 0 ? (
              <Grid
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexGrow: 1,
                  margin: 'auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={palette.text.main}
                  sx={{
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      lg: '18px',
                    },
                  }}
                >
                  Nenhuma irregularidade
                </Typography>
              </Grid>
            ) : (
              map(displayedIds, (item) => {
                if (item) {
                  return <IrregularityCard item={item} />;
                }
              })
            )}
          </Box>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                label='Buscar por código'
                type='submit'
                onClick={() => {
                  !searchingByCode &&
                    dispatch(
                      irregularitiesSlice.actions.toggleSearchingByCode(),
                    );
                  dispatch(irregularitiesSlice.actions.toggleSearchIsOpen());
                }}
                color='primary'
                sx={{
                  width: `90%`,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                label='Buscar por placa'
                type='submit'
                onClick={() => {
                  searchingByCode &&
                    dispatch(
                      irregularitiesSlice.actions.toggleSearchingByCode(),
                    );
                  dispatch(irregularitiesSlice.actions.toggleSearchIsOpen());
                }}
                color='primary'
                sx={{
                  width: `90%`,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                label='Pagar'
                type='submit'
                onClick={handlePayIrregularities}
                disabled={selectedIds.length === 0}
                color='secondary'
                sx={{
                  width: `90%`,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {showDetails && !showListDetails && irregularityDetails?.payment && (
        <IrregularityDetails />
      )}
      {!showDetails && showListDetails && <IrregularityListDetails />}
      <SearchIrregularitiesModal />
      <RegularizeModal />
      <RegularizeListModal />
      <CreateVehicleForIrregularityModal
        resetIrregularities={handleResetPage}
      />
      <EditCPFModal />
    </ContentBox>
  );
};
