import { styled, Box, Container } from '@mui/material';
import { palette } from '../../theme';

export const StyledWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: palette.background.main,
}));

export const StyledMainBox = styled(Box)(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 9,
  overflow: 'hidden',
}));

export const StyledBodyContainer = styled(Container)(() => ({
  marginTop: '20px',
  width: '100%',
  maxWidth: '475px',
}));

export const StyledBodyBox = styled(Container)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

export const StyledButtonsBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 30,
  marginBottom: 20,
}));

export const StyledFormGroup = styled('form')(() => ({
  width: '100%',
  marginTop: 2,
  marginBottom: 2,
}));

export const StyledLogo = styled('img')(() => ({
  width: 150,
}));

export const StyledLogoWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 30,
}));
