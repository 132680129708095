import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { LOGIN } from '../../routes';

const useStyles = makeStyles(() => ({
  linkWrapper: {
    display: 'inline-block',
  },
}));

export const LinkRoute = (
  props: JSX.IntrinsicAttributes &
    LinkProps &
    React.RefAttributes<HTMLAnchorElement>,
) => {
  const classes = useStyles();
  return (
    <Link {...props} className={classes.linkWrapper}>
      <Typography color='primary'>{props.children}</Typography>
    </Link>
  );
};
export const NotFound: React.FC = () => {
  return (
    <>
      {/* Page Not Found! */}
      <Typography variant='h2'>Página não encontrada ;(</Typography>
      <LinkRoute to={LOGIN}>
        <Typography variant='h6'>Voltar para o Login</Typography>
      </LinkRoute>
    </>
  );
};
