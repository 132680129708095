import { Box, styled } from '@mui/material';
import { palette } from '../../theme';

export const StyledModalWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledButtonsWrapper = styled('div')(() => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledInputWrapper = styled('div')(() => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledStepWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTextWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

export const StyledBox = styled(Box)`
  padding-top: 5px;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 400px;
  background-color: ${palette.grey[100]};

  &::-webkit-scrollbar {
    width: 3px;
    height: 4px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.grey[100]};
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.grey[400]};
    border-radius: 20px;
    height: 5px;
  }
`;
