import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  profileSlice,
  irregularitiesSlice,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleErrors } from '../../../utils';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledStepWrapper,
  StyledTextWrapper,
} from '../styles';
import { validationCPFSchema } from '../validations';

export const EditCPFModal: React.FC = () => {
  const dispatch = useDispatch();
  const { editCPFIsOpen, editCPFLoading, showListDetails } = useSelector(
    (state) => state.irregularitiesReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    cpf: '',
  });

  const handleClose = () => {
    setFormData({
      cpf: '',
    });
    dispatch(irregularitiesSlice.actions.toggleEditCPFIrregularitiesIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationCPFSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(profileSlice.actions.setCPF(formData.cpf));
        toast.success('CPF salvo com sucesso.');
        dispatch(
          irregularitiesSlice.actions.toggleEditCPFIrregularitiesIsOpen(),
        );
        if (showListDetails) {
          dispatch(irregularitiesSlice.actions.toggleRegularizeListIsOpen());
        } else {
          dispatch(irregularitiesSlice.actions.toggleRegularizeIsOpen());
        }
      })
      .catch((err: ValidationError | undefined) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <Modal open={editCPFIsOpen} onClose={handleClose} title='Edite seu CPF'>
      <StyledStepWrapper>
        <StyledTextWrapper>
          <Typography color={palette.text.main} textAlign='center' gutterBottom>
            Seu CPF é necessário para pagamentos Pix. Essa informação ficará
            armazenada apenas no seu navegador.
          </Typography>
        </StyledTextWrapper>
        <StyledInputWrapper>
          <Input
            name='cpf'
            type='text'
            mask='cpf'
            label='CPF'
            margin='normal'
            value={formData.cpf}
            onChange={(value: string) =>
              setFormData({ ...formData, cpf: value })
            }
            maxLength={14}
            errorMessage={errors.cpf}
            style={{ width: '100%' }}
          />
        </StyledInputWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={editCPFLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledStepWrapper>
    </Modal>
  );
};
