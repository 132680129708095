import { Typography, BottomNavigation, Paper } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { palette, title } from '../../../theme';

export const Footer = () => {
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        display: { xs: 'none', md: 'block' },
        zIndex: 2000,
      }}
    >
      <BottomNavigation
        sx={{
          backgroundColor: palette.grey[800],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography color='white'>
          {title} © {moment().year()}
        </Typography>
      </BottomNavigation>
    </Paper>
  );
};
