import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Typography, Box } from '@mui/material';
import { find, map } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import eloLogo from '../../assets/images/elo_logo.png';
import mastercardLogo from '../../assets/images/mastercard_logo.png';
import visaLogo from '../../assets/images/visa_logo.png';
import { Button, ContentBox, Modal } from '../../components';
import {
  fetchDeletePaymentMethod,
  fetchGetPaymentMethodList,
  fetchSetDefaultPaymentMethod,
  paymentMethodsSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn } from '../../utils';
import { CreatePaymentMethodModal } from './modals/CreatePaymentMethodModal';
import {
  ButtonsGrid,
  DeleteButton,
  StyledModalWrapper,
  PaymentMethodButton,
} from './styles';

export const PaymentMethods: React.FC = () => {
  const dispatch = useDispatch();
  const { paymentMethods, paymentMethodsLoading, deletePaymentMethodIsOpen } =
    useSelector((state) => state.paymentMethodsReducer);
  const [paymentMethodOnFocus, setPaymentMethodOnFocus] = useState<{
    id: number;
    number: string;
    brand: string;
    bankId: number | null;
    default: 0 | 1;
  } | null>(null);

  const selectBrand = (type: string) => {
    switch (type) {
      case 'Master':
        return (
          <img style={{ width: 30, height: 'auto' }} src={mastercardLogo} />
        );
      case 'Visa':
        return <img style={{ width: 30, height: 'auto' }} src={visaLogo} />;
      case 'Elo':
        return <img style={{ width: 30, height: 'auto' }} src={eloLogo} />;
      default:
        return;
    }
  };

  const handleStandardizePaymentMethod = (paymentMethodId: number) => {
    dispatch(
      fetchSetDefaultPaymentMethod({
        driverId: localStorage.getItem('NITROT:driverId') || '',
        paymentMethodId: paymentMethodId.toString(),
      }),
    ).then((res: any) => {
      handleApiReturn(
        res,
        () => {
          toast.success('Meio de pagamento padronizado com sucesso.');
          dispatch(
            fetchGetPaymentMethodList({
              driverId: localStorage.getItem('NITROT:driverId') || '',
            }),
          );
        },
        null,
      );
    });
  };

  const handleDeletePaymentMethod = () => {
    if (paymentMethodOnFocus) {
      // Check if item to be deleted is default, and change default to another if yes
      if (paymentMethodOnFocus.default && paymentMethods.length > 1) {
        const item = find(paymentMethods, (x) => {
          return x.id !== paymentMethodOnFocus.id && x.default === 0;
        });

        if (item) {
          dispatch(
            fetchSetDefaultPaymentMethod({
              driverId: localStorage.getItem('NITROT:driverId') || '',
              paymentMethodId: item.id.toString(),
            }),
          );
        }
      }

      dispatch(
        fetchDeletePaymentMethod({
          driverId: localStorage.getItem('NITROT:driverId') || '',
          paymentMethodId: paymentMethodOnFocus.id.toString(),
        }),
      ).then((res: any) => {
        handleApiReturn(
          res,
          () => {
            dispatch(
              fetchGetPaymentMethodList({
                driverId: localStorage.getItem('NITROT:driverId') || '',
              }),
            );
            dispatch(
              paymentMethodsSlice.actions.toggleDeletePaymentMethodIsOpen(),
            );
            setPaymentMethodOnFocus(null);
            toast.success('Meio de pagamento excluído com sucesso');
          },
          null,
        );
      });
    }
  };

  return (
    <ContentBox loading={paymentMethodsLoading}>
      {paymentMethods.length > 0 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: {
              xs: '20px',
              sm: 0,
            },
          }}
        >
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 0,
            }}
          >
            {map(paymentMethods, (item) => {
              return (
                <ButtonsGrid item xs={12}>
                  <PaymentMethodButton
                    variant='outlined'
                    color='primary'
                    startIcon={selectBrand(item.brand)}
                    endIcon={item.default === 1 && <CheckCircleOutlineIcon />}
                    onClick={() => handleStandardizePaymentMethod(item.id)}
                  >
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: {
                          xs: 0,
                          md: 'auto',
                        },
                        marginRight: 'auto',
                        paddingLeft: {
                          xs: 0,
                          // md: item.used === 0 ? '125px' : 0,
                        },
                        paddingRight: item.default === 0 ? '25px' : 0,
                      }}
                    >
                      <Typography
                        sx={{
                          marginLeft: {
                            xs: 1,
                            md: 5,
                          },
                          fontSize: {
                            xs: '17px',
                            sm: '20px',
                          },
                        }}
                      >
                        &#8226;&#8226;&#8226;&#8226;{' '}
                      </Typography>

                      <Typography
                        sx={{
                          marginLeft: 0.5,
                          marginRight: 1,
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                          },
                        }}
                      >
                        {item.number}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '9px',
                            sm: '12px',
                          },
                        }}
                        noWrap
                      >
                        ({item.bankId ? 'Débito' : 'Crédito'})
                      </Typography>
                    </Typography>
                  </PaymentMethodButton>
                  <DeleteButton
                    aria-label='delete'
                    size='medium'
                    onClick={() => {
                      setPaymentMethodOnFocus(item);
                      dispatch(
                        paymentMethodsSlice.actions.toggleDeletePaymentMethodIsOpen(),
                      );
                    }}
                  >
                    <DeleteIcon fontSize='inherit' sx={{ color: 'white' }} />
                  </DeleteButton>
                </ButtonsGrid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Grid
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color={palette.text.main}
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                lg: '18px',
              },
            }}
          >
            Nenhum meio de pagamento cadastrado
          </Typography>
        </Grid>
      )}
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexGrow: 1,
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Button
          variant='contained'
          label='Cadastrar meio de pagamento'
          type='submit'
          onClick={() =>
            dispatch(
              paymentMethodsSlice.actions.toggleCreatePaymentMethodIsOpen(),
            )
          }
          color='secondary'
          loading={paymentMethodsLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <Modal
        open={deletePaymentMethodIsOpen}
        onClose={() => {
          dispatch(
            paymentMethodsSlice.actions.toggleDeletePaymentMethodIsOpen(),
          );
        }}
        title='Deseja deletar o meio de pagamento?'
      >
        <StyledModalWrapper>
          <Typography
            color='primary'
            m={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {paymentMethodOnFocus && selectBrand(paymentMethodOnFocus.brand)}{' '}
            <Typography
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: {
                  xs: 0,
                  md: 'auto',
                },
                marginRight: 'auto',
                paddingLeft: {
                  xs: 0,
                },
                paddingRight: paymentMethodOnFocus?.default === 0 ? '25px' : 0,
              }}
            >
              <Typography
                sx={{
                  marginLeft: 1,
                  fontSize: '20px',
                }}
              >
                &#8226;&#8226;&#8226;&#8226;{' '}
              </Typography>

              <Typography
                sx={{
                  marginLeft: 0.5,
                  marginRight: 1,
                }}
              >
                {paymentMethodOnFocus?.number}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                ({paymentMethodOnFocus?.bankId ? 'Débito' : 'Crédito'})
              </Typography>
            </Typography>
          </Typography>
          <Button
            variant='contained'
            label='Deletar'
            type='submit'
            onClick={handleDeletePaymentMethod}
            color='secondary'
            loading={paymentMethodsLoading}
            style={{ width: '100%' }}
          />
        </StyledModalWrapper>
      </Modal>
      <CreatePaymentMethodModal />
    </ContentBox>
  );
};
