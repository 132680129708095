// All user related database operations can be defined here.
import { api } from '..';
import {
  GetIrregularityByIdResponse,
  GetIrregularityByPlateResponse,
  GetIrregularityListByIdsResponse,
  RegularizeListResponse,
  RegularizeResponse,
} from './types';

export * from './types';

export const fetchGetIrregularityByIdService = async (params: {
  driverId: string;
  irregularityId: string;
}) => {
  const { driverId, irregularityId } = params;
  return await api
    .get<GetIrregularityByIdResponse>(
      `notifications/driver/${driverId}/irregularity/${irregularityId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetIrregularityByPlateService = async (params: {
  driverId: string;
  plate: string;
}) => {
  const { driverId, plate } = params;
  return await api
    .get<GetIrregularityByPlateResponse>(
      `notifications/driver/${driverId}/irregularity/plate/${plate}/withtext/nolimit`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetIrregularityListByIdsService = async (params: {
  driverId: string;
  irregularityListIds: string[];
}) => {
  const { driverId, irregularityListIds } = params;
  return await api
    .post<GetIrregularityListByIdsResponse>(
      `notifications/driver/${driverId}/irregularities`,
      JSON.stringify({
        irregularities: irregularityListIds,
      }),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchRegularizeService = async (params: {
  irregularityId: string;
  driverId: string;
  plate: string;
  type: string;
  entryDateTime: string;
  exitDateTime: string;
  stayTime: string;
  price: number;
  routeId: number;
  vehicleId: number | null;
  paymentMethodId: number;
  ipAddress: string;
}) => {
  const { driverId, irregularityId } = params;
  return await api
    .post<RegularizeResponse>(
      `notifications/driver/${driverId}/regularize/${irregularityId}`,
      JSON.stringify(params),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchRegularizeListService = async (params: {
  driverId: string;
  plate: string;
  type: string;
  entryDateTime: string;
  exitDateTime: string;
  stayTime: string;
  price: number;
  routeId: number;
  vehicleId: number | null;
  irregularities: number[];
  paymentMethodId: number;
  observation: string;
  ipAddress: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<RegularizeListResponse>(
      `notifications/driver/${driverId}/regularizeall`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
