// All user related database operations can be defined here.
import { api } from '..';
import { SendMessageResponse } from './types';

export * from './types';

export const fetchSendMessageService = async (params: {
  driverId: string;
  message: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<SendMessageResponse>(
      `drivers/${driverId}/support`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
