import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { map, sortBy } from 'lodash';
import { calculateDistances } from '../../pages/TicketPurchase/auxFunctions';
import {
  OpenTicketType,
  fetchBuyTicketService,
  fetchNearbyService,
  fetchOpenTicketService,
} from '../../services/TicketPurchase';
import {
  // RenewTicketDataType,
  TicketDataType,
  TicketPurchaseStateType,
} from '../types';

const initialState: TicketPurchaseStateType = {
  nearbyRoutes: [],
  discount: null,
  deactivateDiscount: true,
  ticketData: null,
  openTicketData: null,
  hasPendingTicket: false,
  ticketTimeLeft: 0,
  ticketRenovate: false,
  isRenewTicket: false,
  navigatingToRenewTicket: false,
  buyTicketIsOpen: false,
  loadingOpenTicket: false,
  ticketPurchaseLoading: false,
  editCPFIsOpen: false,
  editCPFLoading: false,
};

export const fetchNearby = createAsyncThunk(
  'nearby',
  async (params: { driverId: string; latitude: string; longitude: string }) => {
    return await fetchNearbyService(params);
  },
);

export const fetchBuyTicket = createAsyncThunk(
  'buyTicket',
  async (params: {
    driverId: string;
    vehicleId: string;
    stayTime: string;
    price: string;
    paidPrice: string;
    routeId: string;
    paymentMethodId: string;
  }) => {
    return await fetchBuyTicketService(params);
  },
);

export const fetchOpenTicket = createAsyncThunk(
  'openTicket',
  async (params: { driverId: string }) => {
    return await fetchOpenTicketService(params);
  },
);

export const ticketPurchaseSlice = createSlice({
  name: 'ticketPurchase',
  initialState,
  reducers: {
    toggleTicketPurchaseLoading(state) {
      state.ticketPurchaseLoading = !state.ticketPurchaseLoading;
    },
    resetNearby(state) {
      state.nearbyRoutes = [];
    },
    setDiscount(
      state,
      action: PayloadAction<{
        id: number;
        absolut: number;
        percentual: number;
      }>,
    ) {
      return {
        ...state,
        discount: action.payload,
      };
    },
    toggleBuyTicketIsOpen(state) {
      state.buyTicketIsOpen = !state.buyTicketIsOpen;
    },
    setTicketData(state, action: PayloadAction<TicketDataType | null>) {
      return {
        ...state,
        ticketData: action.payload,
      };
    },
    setOpenTicketData(state, action: PayloadAction<OpenTicketType | null>) {
      return {
        ...state,
        openTicketData: action.payload,
      };
    },
    handleCountdown(state, action: PayloadAction<boolean>) {
      const status = action.payload;
      if (state.openTicketData) {
        return {
          ...state,
          openTicketData: {
            ...state.openTicketData,
            serverDateTime:
              status && state.openTicketData?.serverDateTime
                ? state.openTicketData?.serverDateTime
                : null,
          },
          hasPendingTicket: status,
        };
      } else {
        return state;
      }
    },
    setTimeLeft(state, action: PayloadAction<number>) {
      return {
        ...state,
        ticketTimeLeft: action.payload,
      };
    },
    setRenovate(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        ticketRenovate: action.payload,
      };
    },
    setRenewTicket(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isRenewTicket: action.payload,
      };
    },
    setNavigatingRenewTicket(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        navigatingToRenewTicket: action.payload,
      };
    },
    cancelPayment(state) {
      return {
        ...state,
        openTicketData: null,
        hasPendingTicket: false,
      };
    },
    toggleEditCPFTicketIsOpen(state) {
      return {
        ...state,
        editCPFIsOpen: !state.editCPFIsOpen,
      };
    },
    toggleEditCPFTicketLoading(state) {
      return {
        ...state,
        editCPFLoading: !state.editCPFLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNearby.pending, (state) => {
      return {
        ...state,
        ticketPurchaseLoading: true,
      };
    }),
      builder.addCase(fetchNearby.fulfilled, (state, res) => {
        const handledNearbyRoutes: {
          id: number;
          name: string;
          coordinates: {
            latitude: string;
            longitude: string;
          }[];
          prices: {
            type: string;
            period: string;
            price: string;
          }[];
          workingPeriods: {
            startTime: string;
            endTime: string;
            days: string[];
          }[];
          dist: number;
        }[] = [];
        map(res.payload.data, (item) => {
          const distance = calculateDistances(
            item,
            res.meta.arg.latitude,
            res.meta.arg.longitude,
          );
          handledNearbyRoutes.push({
            ...item,
            dist: distance,
          });
        });

        const sortedNearbyRoutes = sortBy(
          handledNearbyRoutes,
          ['dist'],
          ['asc'],
        );

        return {
          ...state,
          nearbyRoutes: sortedNearbyRoutes,
          ticketPurchaseLoading: false,
        };
      }),
      builder.addCase(fetchNearby.rejected, (state, res) => {
        return {
          ...state,
          ticketPurchaseLoading: false,
        };
      });

    builder.addCase(fetchBuyTicket.pending, (state) => {
      return {
        ...state,
        ticketPurchaseLoading: true,
      };
    }),
      builder.addCase(fetchBuyTicket.fulfilled, (state, res) => {
        return {
          ...state,
          // openTicket: res.payload.data,
          ticketPurchaseLoading: false,
        };
      }),
      builder.addCase(fetchBuyTicket.rejected, (state, res) => {
        return {
          ...state,
          ticketPurchaseLoading: false,
        };
      });

    builder.addCase(fetchOpenTicket.pending, (state) => {
      return {
        ...state,
        loadingOpenTicket: true,
      };
    }),
      builder.addCase(fetchOpenTicket.fulfilled, (state, res) => {
        return {
          ...state,
          openTicketData: res.payload.data,
          loadingOpenTicket: false,
        };
      }),
      builder.addCase(fetchOpenTicket.rejected, (state, res) => {
        return {
          ...state,
          loadingOpenTicket: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleTicketPurchaseLoading,
  resetNearby,
  toggleBuyTicketIsOpen,
  setTicketData,
  handleCountdown,
  setTimeLeft,
  setRenovate,
  setRenewTicket,
  setNavigatingRenewTicket,
  toggleEditCPFTicketIsOpen,
  toggleEditCPFTicketLoading,
} = ticketPurchaseSlice.actions;
