import { Grid, styled } from '@mui/material';

export const StyledStepWrapper = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  overflow: 'scroll',
  paddingLeft: '10px',
  paddingRight: '10px',
}));

export const StyledButtonsWrapper = styled('div')(() => ({
  marginTop: 20,
  width: '100%',
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));
