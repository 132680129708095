import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import * as React from 'react';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: StructuredFormatting;
}

export const MapsAutocomplete = (props: {
  value: PlaceType | null;
  setValue: (value: PlaceType | null) => void;
  onChange?: (value: any) => void;
  onClick?: () => void;
  onClear?: () => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  [x: string]: any;
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  const { value, setValue, margin, errorMessage, disabled, ...otherProps } =
    props;

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const mapsfetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getQueryPredictions(
            {
              ...request,
              radius: 50,
              location: new google.maps.LatLng(-22.906357, -43.090538),
            },
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    mapsfetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, mapsfetch]);

  return (
    <Autocomplete
      disablePortal
      options={options}
      sx={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      disabled={disabled}
      noOptionsText='Nenhum endereço encontrado'
      onChange={async (
        event: any,
        newValue: PlaceType | null,
        reason: string,
      ) => {
        if (reason === 'clear') props.onClear && props.onClear();
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (props.onChange) {
          if (newValue) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              {
                address: newValue?.description,
              },
              (results: any, status) => {
                if (status == google.maps.GeocoderStatus.OK && results) {
                  const lat = results[0].geometry.location.lat();
                  const lng = results[0].geometry.location.lng();
                  if (props.onChange && lat && lng) {
                    props.onChange({
                      lat: lat,
                      lng: lng,
                    });
                  }
                } else {
                  console.log(
                    'Geocode was not successful for the following reason: ' +
                      status,
                  );
                }
              },
            );
          } else {
            props.onChange(null);
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        );

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component='span'
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  &nbsp;{option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          // {...params}
          // ref={params.InputProps.ref}
          label='Digite o endereço de onde estacionou'
          color='primary'
          variant='standard'
          margin={margin === undefined ? 'normal' : margin}
          error={errorMessage !== undefined}
          helperText={errorMessage}
          style={{ height: 40 }}
          {...params}
          // {...otherProps}
        />
      )}
    />
  );
};
