// All user related database operations can be defined here.
import { api } from '..';
import { BuyTicketResponse, NearbyResponse, OpenTicketResponse } from './types';

export * from './types';

export const fetchNearbyService = async (params: {
  driverId: string;
  latitude: string;
  longitude: string;
}) => {
  const { driverId, latitude, longitude } = params;
  return await api
    .get<NearbyResponse>(
      `routes/driver/${driverId}/nearby?latitude=${latitude}&longitude=${longitude}&maxKm=0.6`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchBuyTicketService = async (params: {
  driverId: string;
  vehicleId: string;
  stayTime: string;
  price: string;
  paidPrice: string;
  routeId: string;
  paymentMethodId: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<BuyTicketResponse>(
      `serviceorders/driver/${driverId}/buyticket`,
      otherParams,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchOpenTicketService = async (params: { driverId: string }) => {
  const { driverId } = params;
  return await api
    .get<OpenTicketResponse>(`drivers/${driverId}/openticket`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
