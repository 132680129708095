import { Typography, Grid, Paper } from '@mui/material';
import React from 'react';
import { drawerWidth, ticketBarHeight } from '..';
import { pixSlice, useDispatch, useSelector } from '../../../stores';
import { palette } from '../../../theme';
import { Button } from '../../Button';

export const PixBar = () => {
  const dispatch = useDispatch();
  const { ticketTimeLeft, ticketRenovate } = useSelector(
    (state) => state.ticketPurchaseReducer,
  );
  const { hasOpenPix } = useSelector((state) => state.pixReducer);

  const handleManageOpenPix = () => {
    dispatch(pixSlice.actions.toggleManagePixIsOpen());
  };

  return hasOpenPix ? (
    <Grid
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
        position: 'absolute',
        top: {
          sm: ticketTimeLeft > 0 || ticketRenovate ? 128 : 64,
          xs: ticketTimeLeft > 0 || ticketRenovate ? 112 : 56,
        },
        right: 0,
        borderRadius: 0,
      }}
    >
      <Paper
        sx={{
          backgroundColor: palette.secondary.main,
          borderRadius: 0,
          height: ticketBarHeight,
          boxShadow: `0px 15px 10px 1px rgba(0, 0, 0, 0.3)`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 10px 0 10px',
        }}
      >
        <Typography
          color='white'
          sx={{
            fontSize: {
              xs: '12px',
              sm: '14px',
              md: '18px',
            },
          }}
        >
          Você tem um Pix em aberto
        </Typography>
        <Button
          variant='contained'
          label='Gerenciar'
          onClick={handleManageOpenPix}
          color='primary'
        />
      </Paper>
    </Grid>
  ) : null;
};
