import {
  ContentCopy,
  PhoneIphone,
  QrCode,
  Check,
  DoneAll,
  Close,
} from '@mui/icons-material';
import { Grid, Typography, Button as MuiButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import pixLogo from '../../assets/images/pix_logo.png';
import { Button, ContentBox } from '../../components';
import { DASHBOARD, PIX } from '../../routes';
import {
  useDispatch,
  useSelector,
  ticketPurchaseSlice,
  fetchAuthenticatePayment,
  fetchCancelPix,
  vehiclesSlice,
} from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn, handleDateTime, handleMoney } from '../../utils';
import 'react-credit-cards/es/styles-compiled.css';
import { AuthenticatePaymentResponse } from '../../services';
import { filter, find, includes } from 'lodash';

export const Pix: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const { driverId } = useSelector((state) => state.profileReducer);
  const { pixData, pixLoading, hasOpenPix, openPixServiceOrderId } =
    useSelector((state) => state.pixReducer);

  useEffect(() => {
    if (!pixData) {
      navigate(DASHBOARD);
    }
  }, [pixData]);

  useEffect(() => {
    if (location.pathname === PIX && !hasOpenPix) {
      navigate(DASHBOARD);
    }
  }, [location.pathname]);

  const copyToClipboard = async () => {
    if (pixData?.pixString) {
      navigator.clipboard.writeText(pixData?.pixString);
      toast.info('Código copiado!');
    } else {
      toast.warning('Problema com o código! Tente novamente mais tarde');
    }
  };

  const handleVerifyPix = async () => {
    if (pixData && openPixServiceOrderId) {
      toast.info('Buscando pagamento...');
      const vehicle = find(vehicles, (vehicle) => {
        return includes(openPixServiceOrderId, vehicle.plate);
      });
      if (vehicle) {
        dispatch(
          fetchAuthenticatePayment({
            driverId: driverId.toString(),
            serviceOrderId: pixData.id.toString(),
            vehicleId: vehicle?.id.toString(),
          }),
        ).then(async (res: { payload: AuthenticatePaymentResponse }) => {
          handleApiReturn(
            res,
            () => {
              dispatch(vehiclesSlice.actions.setUsedVehicle(vehicle.id));
              toast.success('Pix pago com sucesso');
              navigate(DASHBOARD);
            },
            null,
          );
        });
      }
    }
  };

  const handleCancelPix = async () => {
    if (pixData) {
      await dispatch(
        fetchCancelPix({
          driverId: driverId.toString(),
          serviceOrderId: pixData.id.toString(),
        }),
      ).then((res: { meta: { requestStatus: string } }) => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(ticketPurchaseSlice.actions.cancelPayment());
          toast.success('Pix cancelado com sucesso.');
          navigate(DASHBOARD);
        }
      });
    }
  };

  return (
    <ContentBox loading={pixLoading}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{
            maxWidth: '500px',
          }}
        >
          <Grid
            item
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <img style={{ width: 300, height: 'auto' }} src={pixLogo} />
          </Grid>
          <Grid
            item
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
                md: 'none',
              },
            }}
          >
            <img style={{ width: 200, height: 'auto' }} src={pixLogo} />
          </Grid>
          <Grid
            item
            sx={{
              display: {
                xs: 'box',
                sm: 'none',
              },
            }}
          >
            <img style={{ width: 150, height: 'auto' }} src={pixLogo} />
          </Grid>
          <Grid
            item
            sx={{
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{
                color: palette.text.main,
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              Total: {handleMoney(pixData?.paidPrice)}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: 1,
            }}
          >
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{
                color: palette.text.main,
                fontSize: '20px',
              }}
            >
              Seu Pix expira em:
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              marginBottom: 2,
            }}
          >
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{
                color: palette.text.main,
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {handleDateTime(pixData?.pixExpiration)}
            </Typography>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              margin: '5px',
            }}
          >
            <Grid xs={1}>
              <ContentCopy color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
            >
              <Typography
                color='text'
                gutterBottom
                sx={{
                  color: palette.text.main,
                  fontSize: '16px',
                }}
              >
                1. Utilize o <b>código QR</b> abaixo ou clique em{' '}
                <b>Copiar Código QR</b> ou diretamente no texto do código abaixo
              </Typography>
            </Grid>
            <Grid
              xs={11}
              sx={{
                display: {
                  xs: 'flex',
                  sm: 'none',
                },
                paddingLeft: 1,
              }}
            >
              <Typography
                color='text'
                gutterBottom
                sx={{
                  color: palette.text.main,
                  fontSize: '16px',
                }}
              >
                1. Clique em <b>Copiar Código QR</b> ou diretamente no texto do
                código abaixo
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: {
                sm: 'flex',
                xs: 'none',
              },
              margin: 0,
              padding: 0,
              width: 300,
              height: 290,
            }}
          >
            <img
              style={{ width: 300, height: 300 }}
              src={`data:image/png;base64,${pixData?.pixBase64}`}
            />
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              sx={{
                display: {
                  sm: 'box',
                  xs: 'none',
                },
                color: palette.text.main,
                fontSize: '16px',
              }}
            >
              - ou -
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              label='Copiar Código QR'
              onClick={copyToClipboard}
              color='primary'
              startIcon={<ContentCopy />}
              sx={{
                padding: 2.5,
                width: {
                  sm: '300px',
                  xs: '200px',
                },
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              sx={{
                color: palette.text.main,
                fontSize: '16px',
              }}
            >
              - ou -
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              marginBottom: '20px',
            }}
          >
            <MuiButton
              variant='contained'
              onClick={copyToClipboard}
              // loading={profileLoading}
              disableElevation
              sx={{
                width: {
                  sm: '300px',
                  xs: '200px',
                },
              }}
              style={{
                marginTop: 10,
                backgroundColor: 'transparent',
                borderRadius: '10px',
                border: `1px solid ${palette.grey[300]}`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 16,
                paddingRight: 16,
              }}
            >
              <Typography
                textAlign='center'
                noWrap
                sx={{
                  color: palette.text.main,
                  fontSize: '16px',
                }}
              >
                {pixData?.pixString}
              </Typography>
              <Typography
                color='primary'
                textAlign='center'
                sx={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
              >
                Copiar
              </Typography>
            </MuiButton>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Grid xs={1}>
              <PhoneIphone color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Typography
                textAlign='center'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: palette.text.main,
                }}
              >
                2. Abra o app do seu banco ou instituição financeira e entre no
                ambiente <b>Pix</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Grid xs={1}>
              <QrCode color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Typography
                textAlign='center'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: palette.text.main,
                }}
              >
                3. Escolha a opção <b>Pix Copia e Cola</b> e cole o código
                copiado
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Grid xs={1}>
              <Check color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Typography
                textAlign='center'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: palette.text.main,
                }}
              >
                4. Confirme as informações e finalize a compra
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Grid xs={1}>
              <DoneAll color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Typography
                textAlign='center'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: palette.text.main,
                }}
              >
                5. Em seguida, retorne a esta tela e clique em Verificar
                Pagamento abaixo
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Button
              variant='contained'
              label='Verificar pagamento'
              onClick={handleVerifyPix}
              loading={pixLoading}
              color='primary'
              startIcon={<DoneAll />}
              sx={{
                padding: 2.5,
                width: {
                  sm: '300px',
                  xs: '200px',
                },
              }}
            />
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Grid xs={1}>
              <Close color='primary' />
            </Grid>
            <Grid
              xs={11}
              sx={{
                paddingLeft: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Typography
                textAlign='center'
                gutterBottom
                sx={{
                  fontSize: '16px',
                  color: palette.text.main,
                }}
              >
                6. Caso seja necessário, cancele o Pix em aberto clicando em
                Cancelar Pix abaixo
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              margin: '5px',
            }}
          >
            <Button
              variant='contained'
              label='Cancelar Pix'
              onClick={handleCancelPix}
              loading={pixLoading}
              color='secondary'
              startIcon={<Close />}
              sx={{
                padding: 2.5,
                width: {
                  sm: '300px',
                  xs: '200px',
                },
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid item sx={{ width: '100%' }}>
          <Button
            variant='contained'
            label='Comprar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={ticketPurchaseLoading}
            style={{ width: '100%' }}
          />
        </Grid> */}
      </div>
    </ContentBox>
  );
};
