import { Container, styled } from '@mui/material';
import { palette } from '../../theme';

export const StyledLogo = styled('img')(() => ({
  height: 45,
  padding: 'auto',
}));

export const StyledLogoWrapper = styled('div')(() => ({
  flexGrow: 1,
}));

export const StyledContainer = styled(Container)`
  overflowx: scroll;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.grey[200]};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.grey[500]};
    border-radius: 20px;
    height: 10px;
  }
`;

export const StyledButtonsWrapper = styled('div')(() => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledInputWrapper = styled('div')(() => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledStepWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTextWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));
