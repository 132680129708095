import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchEditProfileService,
  fetchEditEmailService,
  fetchEditPhoneService,
  fetchChangePasswordService,
} from '../../services/Profile';
import { ProfileStateType } from '../types';

const initialState: ProfileStateType = {
  profileLoading: false,
  driverId: 0,
  name: '',
  cellphone: '',
  email: '',
  birth: '',
  firstTicket: '',
  picture: '',
  termsOfUseDateTime: '',
  passwordUpdateDateTime: '',
  cpf: '',
  editLoading: false,
  editEmailIsOpen: false,
  emailEditStep: 1,
  editPhoneIsOpen: false,
  phoneEditStep: 1,
  changePasswordIsOpen: false,
  editCPFIsOpen: false,
  editCPFLoading: false,
};

export const fetchEditProfile = createAsyncThunk(
  'editProfile',
  async (params: { driverId: string; name: string; birth: string }) => {
    return await fetchEditProfileService(params);
  },
);

export const fetchEditEmail = createAsyncThunk(
  'editEmail',
  async (params: {
    driverId: string;
    email: string;
    validationCode: string;
  }) => {
    return await fetchEditEmailService(params);
  },
);

export const fetchEditPhone = createAsyncThunk(
  'editPhone',
  async (params: {
    driverId: string;
    cellphone: string;
    validationCode: string;
  }) => {
    return await fetchEditPhoneService(params);
  },
);

export const fetchChangePassword = createAsyncThunk(
  'changePassword',
  async (params: {
    driverId: string;
    oldPassword: string;
    newPassword: string;
  }) => {
    return await fetchChangePasswordService(params);
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    toggleProfileLoading(state) {
      state.profileLoading = !state.profileLoading;
    },
    setUser: (
      state,
      action: PayloadAction<{
        driverId: number;
        name: string;
        cellphone: string;
        email: string;
        birth: string;
        firstTicket: string;
        picture: string;
        termsOfUseDateTime: string;
        passwordUpdateDateTime: string;
      }>,
    ) => {
      return {
        ...state,
        driverId: action.payload.driverId,
        name: action.payload.name,
        cellphone: action.payload.cellphone,
        email: action.payload.email,
        birth: action.payload.birth,
        firstTicket: action.payload.firstTicket,
        picture: action.payload.picture,
        termsOfUseDateTime: action.payload.termsOfUseDateTime,
        passwordUpdateDateTime: action.payload.passwordUpdateDateTime,
      };
    },
    setCPF: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        cpf: action.payload,
      };
    },
    toggleEditEmailIsOpen: (state) => {
      return {
        ...state,
        editEmailIsOpen: !state.editEmailIsOpen,
      };
    },
    setEmailEditStep: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        emailEditStep: action.payload,
      };
    },
    toggleEditPhoneIsOpen: (state) => {
      return {
        ...state,
        editPhoneIsOpen: !state.editPhoneIsOpen,
      };
    },
    setPhoneEditStep: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        phoneEditStep: action.payload,
      };
    },
    toggleChangePasswordIsOpen: (state) => {
      return {
        ...state,
        changePasswordIsOpen: !state.changePasswordIsOpen,
      };
    },
    toggleEditCPFProfileIsOpen(state) {
      return {
        ...state,
        editCPFIsOpen: !state.editCPFIsOpen,
      };
    },
    toggleEditCPFProfileLoading(state) {
      return {
        ...state,
        editCPFLoading: !state.editCPFLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEditProfile.pending, (state) => {
      return {
        ...state,
        profileLoading: true,
      };
    }),
      builder.addCase(fetchEditProfile.fulfilled, (state, res) => {
        return {
          ...state,
          driverId: res.payload.data.id,
          name: res.payload.data.name,
          birth: res.payload.data.birth,
          cellphone: res.payload.data.cellphone,
          email: res.payload.data.email,
          profileLoading: false,
        };
      }),
      builder.addCase(fetchEditProfile.rejected, (state, res) => {
        return {
          ...state,
          profileLoading: false,
        };
      });

    builder.addCase(fetchEditEmail.pending, (state) => {
      return {
        ...state,
        profileLoading: true,
      };
    }),
      builder.addCase(fetchEditEmail.fulfilled, (state, res) => {
        return {
          ...state,
          email: res.payload.data.email,
          profileLoading: false,
        };
      }),
      builder.addCase(fetchEditEmail.rejected, (state, res) => {
        return {
          ...state,
          profileLoading: false,
        };
      });

    builder.addCase(fetchEditPhone.pending, (state) => {
      return {
        ...state,
        profileLoading: true,
      };
    }),
      builder.addCase(fetchEditPhone.fulfilled, (state, res) => {
        return {
          ...state,
          cellphone: res.payload.data.cellphone,
          profileLoading: false,
        };
      }),
      builder.addCase(fetchEditPhone.rejected, (state, res) => {
        return {
          ...state,
          profileLoading: false,
        };
      });

    builder.addCase(fetchChangePassword.pending, (state) => {
      return {
        ...state,
        profileLoading: true,
      };
    }),
      builder.addCase(fetchChangePassword.fulfilled, (state, res) => {
        return {
          ...state,
          profileLoading: false,
        };
      }),
      builder.addCase(fetchChangePassword.rejected, (state, res) => {
        return {
          ...state,
          profileLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleProfileLoading,
  setUser,
  setCPF,
  toggleEditEmailIsOpen,
  setEmailEditStep,
  toggleEditPhoneIsOpen,
  setPhoneEditStep,
  toggleChangePasswordIsOpen,
  toggleEditCPFProfileIsOpen,
  toggleEditCPFProfileLoading,
} = profileSlice.actions;
