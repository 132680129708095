// All user related database operations can be defined here.
import { api } from '..';
import {
  CreateVehicleResponse,
  SetDefaultVehicleResponse,
  DeleteVehicleResponse,
  GetVehicleListResponse,
} from './types';

export * from './types';

export const fetchCreateVehicleService = async (params: {
  driverId: string;
  plate: string;
  type: string;
  model: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<CreateVehicleResponse>(
      `drivers/${driverId}/vehicles`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchSetDefaultVehicleService = async (params: {
  driverId: string;
  vehicleId: string;
}) => {
  const { driverId, vehicleId } = params;
  return await api
    .put<SetDefaultVehicleResponse>(
      `drivers/${driverId}/vehicles/${vehicleId}/default`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchDeleteVehicleService = async (params: {
  driverId: string;
  vehicleId: string;
}) => {
  const { driverId, vehicleId } = params;
  return await api
    .delete<DeleteVehicleResponse>(`drivers/${driverId}/vehicles/${vehicleId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetVehicleListService = async (params: {
  driverId: string;
}) => {
  const { driverId } = params;
  return await api
    .get<GetVehicleListResponse>(`drivers/${driverId}/vehicles`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
