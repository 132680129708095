import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  IconButton,
  Modal as MuiModal,
  Skeleton,
  Typography,
} from '@mui/material';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { palette } from '../../theme';

export const Modal = (props: {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  open: boolean;
  onClose: (arg0: any) => void;
  title: string;
  width?: number;
  noCloseButton?: boolean;
  isLoading?: boolean;
}) => {
  const { children, open, onClose, title, width, noCloseButton, isLoading } =
    props;

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          borderRadius: 2,
          boxShadow: 24,
          p: {
            md: 3,
            xs: 2,
          },
          paddingBottom: 5,
          maxHeight: {
            md: '600px',
            xs: '500px',
          },
          width: width || '50%',
          maxWidth: {
            sm: '500px',
            xs: '300px',
          },
          minWidth: '300px',
          overflowY: 'auto',
        }}
      >
        {isLoading ? (
          <>
            <Skeleton width='100%' height={30} />
            <Skeleton variant='rectangular' width='100%' height={118} />
            <Skeleton width='60%' />
            <Skeleton width='45%' />
            <Skeleton width='80%' />
            <Skeleton width='75%' />
            <Skeleton width='60%' />
          </>
        ) : (
          <>
            <Typography
              id='modal-modal-title'
              variant='h5'
              sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: palette.primary.main,
              }}
            >
              {title}
              {!noCloseButton && (
                <IconButton aria-label='Fechar modal' onClick={onClose}>
                  <CancelIcon />
                </IconButton>
              )}
            </Typography>
            {children}
          </>
        )}
      </Box>
    </MuiModal>
  );
};
