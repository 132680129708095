import { Box, styled } from '@mui/material';
import { palette } from '../../theme';

export const StyledOuterBox = styled(Box)`
  background-color: ${palette.grey[100]};
  border-radius: 9px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.3);
`;

export const StyledInnerBox = styled('div')`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: ${palette.grey[100]};
    border-radius: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${palette.grey[400]};
    border-radius: 20px;
    height: 10px;
  }
`;
