// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchLoginUrl,
  fetchCellphoneGetCodeUrl,
  fetchEmailGetCodeUrl,
  fetchCellphoneValidateCodeUrl,
  fetchEmailValidateCodeUrl,
  fetchSignupUrl,
  fetchLoginWithFBUrl,
  fetchPasswordRecoveryUrl,
} from './constants';
import {
  LoginResponse,
  CellphoneGetCodeResponse,
  EmailGetCodeResponse,
  CellphoneValidateCodeResponse,
  EmailValidateCodeResponse,
  SignupResponse,
  LoginWithFBResponse,
  PasswordRecoveryResponse,
} from './types';

export * from './types';

export const fetchLoginService = async (params: {
  email: string;
  password: string;
}) => {
  const { email, password } = params;
  return await api
    .post<LoginResponse>(
      fetchLoginUrl,
      JSON.stringify({ email: email, password: password }),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchCellphoneGetCodeService = async (params: {
  number: string;
}) => {
  return await api
    .post<CellphoneGetCodeResponse>(fetchCellphoneGetCodeUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};

export const fetchEmailGetCodeService = async (params: { email: string }) => {
  return await api
    .post<EmailGetCodeResponse>(fetchEmailGetCodeUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};

export const fetchCellphoneValidateCodeService = async (params: {
  number: string;
  code: string;
}) => {
  return await api
    .post<CellphoneValidateCodeResponse>(fetchCellphoneValidateCodeUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};

export const fetchEmailValidateCodeService = async (params: {
  email: string;
  code: string;
}) => {
  return await api
    .post<EmailValidateCodeResponse>(fetchEmailValidateCodeUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};

export const fetchLoginWithFBService = async (params: {
  facebookId: string;
}) => {
  return await api
    .post<LoginWithFBResponse>(fetchLoginWithFBUrl, JSON.stringify(params))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchSignupService = async (params: {
  name: string;
  email: string;
  cellphone: string;
  birth: string;
  password: string;
  validationCode: string;
  facebookId: string | null;
}) => {
  return await api
    .post<SignupResponse>(fetchSignupUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};

export const fetchPasswordRecoveryService = async (params: {
  email: string;
}) => {
  return await api
    .post<PasswordRecoveryResponse>(fetchPasswordRecoveryUrl, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.data);
    });
};
