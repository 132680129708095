import { map, replace } from 'lodash';
import Maskara from 'maskara';
import moment from 'moment';
import { ValidationError } from 'yup';

export const handleErrors = (errors: ValidationError | undefined) => {
  let errorMessages = {};
  if (errors) {
    map(errors.inner, (item) => {
      if (item.path) {
        errorMessages = { ...errorMessages, [item.path]: item.message };
      }
    });
  }
  return errorMessages;
};

export const handleApiReturn = (res: any, onSuccess: any, onFailure: any) => {
  if (res.meta.requestStatus === 'fulfilled') {
    onSuccess && onSuccess();
  } else {
    onFailure && onFailure();
  }
};

export const handleDateTime = (dateTime: string | undefined) => {
  if (dateTime === undefined) return '--';
  const formattedDateTime = moment(dateTime)
    .format('DD/MM/YYYY HH:mm:ss')
    .toString();
  return formattedDateTime === '01/01/0001 12:00:00' ? '--' : formattedDateTime;
};

export const handleMoney = (value: number | string | undefined) => {
  if (value === undefined) return 'R$0.00';
  if (typeof value === 'string') {
    return `R$${replace(parseFloat(value).toFixed(2).toString(), '.', ',')}`;
  }
  return `R$${replace(value.toFixed(2).toString(), '.', ',')}`;
};

export const handlePhone = (phone: string | undefined) => {
  if (phone === undefined) return '--';
  return Maskara.apply(phone, '(00) 00000-0000');
};

export const handleDateTimeDiff = (start: string, end: string) => {
  const diff = moment.utc(end).diff(moment.utc(start), 'seconds');
  const hours = Math.floor(diff / 3600).toString();
  const minutes = Math.floor((diff % 3600) / 60).toString();
  const seconds = Math.round(
    ((diff % 3600) / 60 - Math.floor((diff % 3600) / 60)) * 60,
  ).toString();
  return `${hours.length < 2 ? ('0' + hours).slice(-2) : hours}:${
    minutes.length < 2 ? ('0' + minutes).slice(-2) : minutes
  }:${seconds.length < 2 ? ('0' + seconds).slice(-2) : seconds}`;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  const rhex = r.toString(16);
  const ghex = g.toString(16);
  const bhex = b.toString(16);
  return '#' + rhex + ghex + bhex;
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const groupArr = (data: string | any[], n: number) => {
  const group: any[][] = [];
  for (let i = 0, j = 0; i < data.length; i++) {
    if (i >= n && i % n === 0) {
      j++;
    }
    group[j] = group[j] || [];
    group[j].push(data[i]);
  }
  return group;
};

export const generateRandomId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

export const formatSecsToFullTime = (secs: number) => {
  const pad = (num: number) => `0${num}`.slice(-2);
  let minutes = Math.floor(secs / 60);
  secs %= 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
};
