import { find, includes } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from '../..';
import {
  StyledButtonsWrapper,
  StyledStepWrapper,
} from '../../../pages/TicketPurchase/styles';
import {
  useDispatch,
  useSelector,
  fetchAuthenticatePayment,
  fetchCancelPix,
  pixSlice,
  vehiclesSlice,
  ticketPurchaseSlice,
} from '../../../stores';

export const ManagePixModal: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const { managePixIsOpen, managePixIsLoading, openPixServiceOrderId } =
    useSelector((state) => state.pixReducer);

  const handleVerifyPix = async () => {
    // console.log('==================================');
    // console.log('openPixServiceOrderId: ' + openPixServiceOrderId);
    if (openPixServiceOrderId) {
      // toast.info('Buscando pagamento...');
      const vehicle = find(vehicles, (vehicle) => {
        return includes(openPixServiceOrderId, vehicle.plate);
      });
      // console.log('vehicle: ', vehicle);
      if (vehicle) {
        // console.log('entered if');
        // console.log(
        //   'fetchAuthenticatePayment sending vehicleId as ',
        //   vehicle.id.toString(),
        // );
        dispatch(
          fetchAuthenticatePayment({
            driverId: driverId.toString(),
            serviceOrderId: openPixServiceOrderId,
            vehicleId: vehicle.id.toString(),
          }),
        ).then(async (res: any) => {
          // console.log('returned');
          if (res.meta.requestStatus === 'fulfilled') {
            // console.log('fulfilled');
            dispatch(
              ticketPurchaseSlice.actions.setOpenTicketData(res.payload.data),
            );
            // console.log(
            //   'sending the following vehicleId to be marked as used: ',
            //   vehicle.id,
            // );
            dispatch(vehiclesSlice.actions.setUsedVehicle(vehicle.id));
            dispatch(pixSlice.actions.toggleManagePixIsOpen());
            toast.success('Pix pago com sucesso');
          }
        });
      }
    }
  };

  const handleCancelPix = async () => {
    if (openPixServiceOrderId) {
      await dispatch(
        fetchCancelPix({
          driverId: driverId.toString(),
          serviceOrderId: openPixServiceOrderId,
        }),
      ).then((res: { meta: { requestStatus: string } }) => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast.success('Pix cancelado com sucesso.');
          dispatch(pixSlice.actions.toggleManagePixIsOpen());
        }
      });
    }
  };

  const handleClose = () => {
    dispatch(pixSlice.actions.toggleManagePixIsOpen());
  };

  return (
    <Modal
      open={managePixIsOpen}
      onClose={handleClose}
      title='Gerenciar Pix em aberto'
    >
      <StyledStepWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Verificar pagamento'
            onClick={handleVerifyPix}
            color='primary'
            loading={managePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Cancelar Pix'
            onClick={handleCancelPix}
            color='secondary'
            loading={managePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
        <StyledButtonsWrapper>
          <Button
            label='Voltar'
            onClick={handleClose}
            color='secondary'
            loading={managePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledStepWrapper>
    </Modal>
  );
};
