import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Grid,
  Typography,
  Button as MuiButton,
  Link,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ContentBox, Input } from '../../components';
import { TERMS_OF_USE } from '../../services';
import { EditProfileResponse } from '../../services/Profile';
import {
  fetchEditProfile,
  profileSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn, handleErrors } from '../../utils';
import { ChangePasswordModal } from './modals/ChangePasswordModal';
import { EditCPFModal } from './modals/EditCPFModal';
import { EditEmailModal } from './modals/EditEmailModal';
import { EditPhoneModal } from './modals/EditPhoneModal';
import { validationProfileSchema } from './validations';

export const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { profileLoading, name, email, cellphone, birth, cpf } = useSelector(
    (state) => state.profileReducer,
  );
  const { termsOfUse } = useSelector((state) => state.legalReducer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    name: name,
    email: email,
    cellphone: cellphone,
    birth: birth,
    cpf: cpf,
  });
  const [birthdate, setBirthdate] = useState(
    moment(birth, 'YYYY-MM-DD').format('DDMMYYYY'),
  );

  useEffect(() => {
    setFormData({ name, email, cellphone, birth, cpf });
  }, [name, email, cellphone, birth, cpf]);

  const handleSubmit = () => {
    setErrors({});
    validationProfileSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchEditProfile({
            driverId: localStorage.getItem('NITROT:driverId') || '',
            name: formData.name,
            birth: formData.birth,
          }),
        ).then((res: EditProfileResponse) => {
          handleApiReturn(
            res,
            () => {
              toast.success('Perfil atualizado com sucesso.');
            },
            null,
          );
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleEditEmail = () => {
    dispatch(profileSlice.actions.toggleEditEmailIsOpen());
  };

  const handleEditPhone = () => {
    dispatch(profileSlice.actions.toggleEditPhoneIsOpen());
  };

  const handleEditCPF = () => {
    dispatch(profileSlice.actions.toggleEditCPFProfileIsOpen());
  };

  const handleChangePassword = () => {
    dispatch(profileSlice.actions.toggleChangePasswordIsOpen());
  };

  return (
    <ContentBox loading={profileLoading}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 0,
          }}
        >
          <Grid item xs={12}>
            <Input
              data-testid='name'
              type='text'
              margin='normal'
              label='Nome'
              value={formData.name}
              onChange={(value) => setFormData({ ...formData, name: value })}
              errorMessage={errors && errors.name}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              data-testid='email'
              type='text'
              margin='normal'
              label='Email'
              value={formData.email}
              disabled
              onChange={(value) => setFormData({ ...formData, email: value })}
              errorMessage={errors && errors.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      color='primary'
                      aria-label='editar'
                      onClick={handleEditEmail}
                      sx={{ borderRadius: 1, padding: '3px' }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              data-testid='phone'
              type='text'
              margin='normal'
              label='Telefone'
              disabled
              mask='phone'
              value={formData.cellphone}
              onChange={(value) =>
                setFormData({ ...formData, cellphone: value })
              }
              errorMessage={errors && errors.phone}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      color='primary'
                      aria-label='editar'
                      onClick={handleEditPhone}
                      sx={{ borderRadius: 1, padding: '3px' }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              data-testid='birth'
              type='text'
              margin='normal'
              label='Data de nascimento'
              mask='date'
              value={birthdate}
              onChange={(value) => {
                setBirthdate(value);
                setFormData({
                  ...formData,
                  birth: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                });
              }}
              errorMessage={errors && errors.birth}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              data-testid='cpf'
              type='text'
              margin='normal'
              label='CPF'
              disabled
              mask='cpf'
              value={formData.cpf}
              onChange={(value) => setFormData({ ...formData, cpf: value })}
              errorMessage={errors && errors.cpf}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      color='primary'
                      aria-label='editar'
                      onClick={handleEditCPF}
                      sx={{ borderRadius: 1, padding: '3px' }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* {termsOfUse.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {map(termsOfUse, (item) => {
                return (
                  <Typography color={palette.text.main}>
                    Acesse
                    <MuiButton
                      component={Link}
                      onClick={() =>
                        window.open(item.url, '_blank', 'noreferrer')
                      }
                    >
                      <Typography sx={{ margin: 0, padding: 0 }}>
                        {item.title}
                      </Typography>
                    </MuiButton>
                  </Typography>
                );
              })}
            </Grid>
          )} */}
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color={palette.text.main}>
              Acesse os
              <MuiButton
                component={Link}
                onClick={() =>
                  window.open(TERMS_OF_USE, '_blank', 'noreferrer')
                }
              >
                <Typography sx={{ margin: 0, padding: 0 }}>
                  Termos de Uso
                </Typography>
              </MuiButton>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <MuiButton component={Link} onClick={handleChangePassword}>
              <Typography sx={{ margin: 0, padding: 0 }}>
                Alterar senha
              </Typography>
            </MuiButton>
          </Grid>
        </Grid>
      </Box>
      <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          label='Editar perfil'
          type='submit'
          onClick={handleSubmit}
          color='secondary'
          loading={profileLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <EditEmailModal />
      <EditPhoneModal />
      <EditCPFModal />
      <ChangePasswordModal />
    </ContentBox>
  );
};
