/* eslint-disable jsx-a11y/alt-text */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Typography,
  InputAdornment,
  IconButton,
  Button as MuiButton,
  Link,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Input, Modal } from '../..';
import {
  useDispatch,
  useSelector,
  legalSlice,
  fetchChangePassword,
  fetchAcceptTerms,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import { validationPasswordSchema } from '../validations';

export const LGPDModal: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const {
    LGPDIsOpen,
    needsToUpdatePassword,
    needsToAcceptTerms,
    termsOfUse,
    legalLoading,
  } = useSelector((state) => state.legalReducer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
    acceptedTerms: Array<boolean>(termsOfUse.length),
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [allTermsChecked, setAllTermsChecked] = useState(false);
  const [passwordHasEightChars, setPasswordHasEightChars] = useState(false);
  const [passwordHasUpperLowerChars, setPasswordHasUpperLowerChars] =
    useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleSubmit = () => {
    setErrors({});
    validationPasswordSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchChangePassword({
            driverId: driverId.toString(),
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(legalSlice.actions.toggleNeedsToUpdatePassword());
              toast.success('Senha atualizada com sucesso.');
              dispatch(
                fetchAcceptTerms({
                  driverId: driverId.toString(),
                }),
              ).then((res: any) => {
                handleApiReturn(
                  res,
                  () => {
                    dispatch(legalSlice.actions.toggleNeedsToAcceptTerms());
                    toast.success('Aceitação dos termos enviada.');
                    handleClose();
                  },
                  null,
                );
              });
            },
            null,
          );
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleClose = () => {
    dispatch(legalSlice.actions.toggleLGPDIsOpen());
  };

  function checkPasswordStrength(password: string) {
    // Initialize variables
    let strength = 0;

    // Check password length
    if (password.length < 8) {
      setPasswordHasEightChars(false);
    } else {
      setPasswordHasEightChars(true);
      strength += 1;
    }

    // Check for mixed case
    if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
      setPasswordHasUpperLowerChars(false);
    } else {
      setPasswordHasUpperLowerChars(true);
      strength += 1;
    }

    // Check for numbers
    if (!password.match(/\d/)) {
      setPasswordHasNumber(false);
    } else {
      setPasswordHasNumber(true);
      strength += 1;
    }

    // Check for special character
    if (!password.match(/[^a-zA-Z\d]/)) {
      setPasswordHasSpecialChar(false);
    } else {
      setPasswordHasSpecialChar(true);
      strength += 1;
    }

    setPasswordStrength(strength);
  }

  useEffect(() => {
    let allChecked = true;
    map(formData.acceptedTerms, (item) => {
      if (!item) allChecked = false;
    });
    setAllTermsChecked(allChecked);
  }, [formData]);

  return (
    <Modal open={LGPDIsOpen} onClose={handleClose} title='Nova política'>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h6' color='primary'>
          Nossas políticas de utilização do app foram atualizadas, e você
          precisa revisá-las para continuar utilizando o sistema.
        </Typography>
        {needsToUpdatePassword && (
          <>
            <Typography sx={{ marginTop: '30px' }}>
              De acordo com nossa nova política de segurança, precisamos que
              você redefina sua senha para uma senha que seja forte
            </Typography>
            <Input
              data-testid='oldPassword'
              margin='normal'
              label='Senha antiga'
              type={showOldPassword ? 'text' : 'password'}
              value={formData.oldPassword}
              onChange={(value) =>
                setFormData({ ...formData, oldPassword: value })
              }
              errorMessage={errors && errors.oldPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      onMouseDown={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Input
              data-testid='newPassword'
              margin='normal'
              label='Nova senha'
              type={showNewPassword ? 'text' : 'password'}
              value={formData.newPassword}
              onChange={(value) => {
                setFormData({ ...formData, newPassword: value });
                checkPasswordStrength(value);
              }}
              errorMessage={errors && errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      onMouseDown={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              passwordStrength={passwordStrength}
            />
            <Input
              data-testid='passwordConfirmation'
              margin='normal'
              label='Confirme sua nova senha'
              type={showPasswordConfirmation ? 'text' : 'password'}
              value={formData.passwordConfirmation}
              onChange={(value) =>
                setFormData({ ...formData, passwordConfirmation: value })
              }
              errorMessage={errors && errors.passwordConfirmation}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() =>
                        setShowPasswordConfirmation(!showPasswordConfirmation)
                      }
                      onMouseDown={() =>
                        setShowPasswordConfirmation(!showPasswordConfirmation)
                      }
                    >
                      {showPasswordConfirmation ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              component='span'
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginTop: '20px',
              }}
            >
              <Typography
                color={
                  passwordHasEightChars
                    ? palette.success.main
                    : palette.text.main
                }
                sx={{ fontSize: '11px' }}
              >
                Pelo menos 8 caracteres
              </Typography>
              <Typography
                color={
                  passwordHasUpperLowerChars
                    ? palette.success.main
                    : palette.text.main
                }
                sx={{ fontSize: '11px' }}
              >
                Letras maiúsculas e minúsculas
              </Typography>
              <Typography
                color={
                  passwordHasNumber ? palette.success.main : palette.text.main
                }
                sx={{ fontSize: '11px' }}
              >
                Pelo menos um número
              </Typography>
              <Typography
                color={
                  passwordHasSpecialChar
                    ? palette.success.main
                    : palette.text.main
                }
                sx={{ fontSize: '11px' }}
              >
                Pelo menos um caracter especial
              </Typography>
            </Box>
          </>
        )}

        {needsToAcceptTerms && (
          <>
            <Typography sx={{ marginTop: '30px' }}>
              Os Termos de Uso foram atualizados e você precisa revisá-los.
            </Typography>
            <div
              style={{
                marginTop: 20,
                width: '100%',
                minWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              {termsOfUse.length > 0 &&
                map(termsOfUse, (item, index) => {
                  return (
                    <Checkbox
                      label={
                        <Typography color={palette.text.main}>
                          Li e aceito
                          <MuiButton component={Link}>
                            <Typography
                              sx={{ margin: 0, padding: 0 }}
                              onClick={() =>
                                window.open(item.url, '_blank', 'noreferrer')
                              }
                            >
                              {item.title}
                            </Typography>
                          </MuiButton>
                        </Typography>
                      }
                      checked={formData.acceptedTerms[index]}
                      errorMessage={errors && errors.acceptedTerms}
                      onChange={() => {
                        const newTerms = formData.acceptedTerms;
                        newTerms[index] = !newTerms[index];
                        setFormData({
                          ...formData,
                          acceptedTerms: newTerms,
                        });
                      }}
                    />
                  );
                })}
            </div>
          </>
        )}
        <Button
          variant='contained'
          label='Enviar'
          onClick={handleSubmit}
          disabled={needsToAcceptTerms && !allTermsChecked}
          color='secondary'
          loading={legalLoading}
          sx={{
            width: '100%',
            marginTop: '20px',
          }}
        />
        <Button
          label='Voltar'
          onClick={handleClose}
          sx={{
            width: '100%',
          }}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={legalLoading}
        >
          <CircularProgress color='primary' />
        </Backdrop>
      </div>
    </Modal>
  );
};
