import DoneAllIcon from '@mui/icons-material/DoneAll';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SendIcon from '@mui/icons-material/Send';
import { Step, StepLabel, Stepper, StepIconProps } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { map } from 'lodash';
import { desaturate } from 'polished';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { palette } from '../../theme';
import { StepOne } from './StepOne';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';
import {
  StyledWrapper,
  StyledMainBox,
  StyledStepsWrapper,
  StyledBodyWrapper,
} from './styles';

const steps = ['Envio do Código', 'Validação', 'Formulário'];

export const Signup: React.FC = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [code, setCode] = useState('');

  const fbData = location.state
    ? {
        facebookId: location.state.facebookId,
        name: location.state.name,
        email: location.state.email,
      }
    : null;

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: palette.secondary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: palette.secondary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: desaturate(0.5, palette.primary.main),
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: desaturate(0.5, palette.primary.main),
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: palette.secondary.main,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundColor: palette.secondary.main,
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <SendIcon />,
      2: <DoneAllIcon />,
      3: <FormatListBulletedIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <StyledWrapper>
      <StyledMainBox
        boxShadow={20}
        sx={{
          width: {
            xs: '100%',
            sm: '600px',
          },
          height: {
            xs: '100%',
            sm: '600px',
          },
          borderRadius: {
            xs: 0,
            sm: '14px',
          },
        }}
      >
        <StyledStepsWrapper>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            sx={{ width: '100%', marginBottom: '20px' }}
          >
            {map(steps, (label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </StyledStepsWrapper>
        <StyledBodyWrapper>
          {activeStep === 0 ? (
            // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
            // <StepOne handleNext={handleNext} fbData={fbData} />
            <StepOne handleNext={handleNext} isSignupWithFB={fbData !== null} />
          ) : activeStep === 1 ? (
            <StepTwo
              handleNext={handleNext}
              handleBack={handleBack}
              setCode={setCode}
            />
          ) : (
            <StepThree handleBack={handleBack} code={code} fbData={fbData} />
          )}
        </StyledBodyWrapper>
      </StyledMainBox>
    </StyledWrapper>
  );
};
