import { toUpper, uniq } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dropdown, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  vehiclesSlice,
  fetchCreateVehicle,
  fetchGetVehicleList,
} from '../../../stores';
import { handleApiReturn, handleErrors } from '../../../utils';
import { vehicleTypesOptions } from '../resources';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledModalWrapper,
} from '../styles';
import { validationSchema } from '../validations';

const vehicleModelOptions = require('../../../assets/brands.json');

export const CreateVehicleModal: React.FC = () => {
  const dispatch = useDispatch();
  const { createVehicleIsOpen, vehiclesLoading } = useSelector(
    (state) => state.vehiclesReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    plate: '',
    model: '',
    type: '',
  });

  const handleClose = () => {
    setFormData({
      plate: '',
      model: '',
      type: '',
    });
    dispatch(vehiclesSlice.actions.toggleCreateVehicleIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchCreateVehicle({
            driverId: localStorage.getItem('NITROT:driverId') || '',
            plate: formData.plate,
            model: formData.model,
            type: formData.type,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(
                fetchGetVehicleList({
                  driverId: localStorage.getItem('NITROT:driverId') || '',
                }),
              );
              toast.success('Veículo criado com sucesso.');
              handleClose();
            },
            null,
          );
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <Modal
      open={createVehicleIsOpen}
      onClose={handleClose}
      title='Cadastro de veículo'
    >
      <StyledModalWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='plate'
            margin='normal'
            label='Placa'
            type='text'
            mask='plate'
            value={formData.plate}
            onChange={(value) => {
              setFormData({ ...formData, plate: value });
            }}
            errorMessage={errors && errors.plate}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            options={uniq(vehicleModelOptions.brands)}
            data-testid='model'
            margin='normal'
            label='Marca/ modelo'
            type='text'
            value={formData.model}
            onChange={(value) => setFormData({ ...formData, model: value })}
            errorMessage={errors && errors.model}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            options={vehicleTypesOptions}
            data-testid='type'
            margin='normal'
            label='Tipo de veículo'
            type='text'
            value={formData.type}
            onChange={(value) => setFormData({ ...formData, type: value })}
            errorMessage={errors && errors.type}
          />
        </StyledInputWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={vehiclesLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledModalWrapper>
    </Modal>
  );
};
