/* eslint-disable pii/no-phone-number */
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  plate: Yup.string()
    .min(7, 'Mínimo de 7 caracteres')
    .required('Placa é obrigatório'),
  model: Yup.string().required('Modelo é obrigatório'),
  type: Yup.string().required('Tipo é obrigatório'),
});
