import CheckIcon from '@mui/icons-material/Check';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CardActionArea,
} from '@mui/material';
import { filter, includes } from 'lodash';
import React, { useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { irregularitiesSlice, useDispatch, useSelector } from '../../stores';
import { palette } from '../../theme';
import { handleDateTime } from '../../utils';

export const IrregularityCard = (props: {
  item: {
    brand: string;
    id: number;
    observation: string | null;
    plate: string;
    plateImg: string;
    registerDateTime: string;
    type: string;
  };
}) => {
  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const { irregularityList, selectedIds } = useSelector(
    (state) => state.irregularitiesReducer,
  );

  const selected = includes(selectedIds, props.item.id);

  const handleClick = (irregularityId: number) => {
    if (!selected) {
      const newSelectedIds = [...selectedIds, irregularityId];
      dispatch(irregularitiesSlice.actions.setSelectedIds(newSelectedIds));

      if (newSelectedIds.length === irregularityList.length) {
        dispatch(irregularitiesSlice.actions.setAllIdsSelected(true));
      }
    } else {
      const newSelectedIds = filter(selectedIds, (item) => {
        return item !== irregularityId;
      });
      dispatch(irregularitiesSlice.actions.setSelectedIds(newSelectedIds));
      dispatch(irregularitiesSlice.actions.setAllIdsSelected(false));
    }
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        position: 'relative',
        width: '100%',
        maxWidth: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
      }}
    >
      {selected && (
        <div
          style={{
            position: 'absolute',
            top: '-6px',
            right: '-10px',
            height: '25px',
            width: '25px',
            backgroundColor: palette.secondary.main,
            borderRadius: '100px',
            zIndex: 1,
          }}
        >
          <CheckIcon sx={{ fontSize: '25px', color: 'white' }} />
        </div>
      )}
      <Card
        sx={{
          width: '100%',
          borderRadius: '14px',
          border:
            // selecting && selected
            selected
              ? `3px solid ${palette.secondary.main}`
              : `3px solid ${palette.primary.main}`,
        }}
        ref={cardRef}
        onClick={() => handleClick(props.item.id)}
      >
        <CardActionArea
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            height: {
              xs: 275,
              sm: 175,
            },
          }}
        >
          <CardContent
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingLeft: '40px',
              width: '50%',
            }}
          >
            <Typography variant='h6' color='primary'>
              {props.item.id}
            </Typography>
            <Typography
              gutterBottom
              color='text.secondary'
              sx={{
                fontSize: '16px',
              }}
            >
              {handleDateTime(props.item.registerDateTime)}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '16px',
              }}
            >
              Placa: {props.item.plate}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '16px',
              }}
            >
              Categoria: {props.item.type}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '16px',
              }}
            >
              Modelo: {props.item.brand}
            </Typography>
          </CardContent>
          <CardMedia
            sx={{
              height: '100%',
              width: {
                xs: '100%',
                sm: '50%',
              },
              padding: 0,
            }}
            image={props.item.plateImg}
          />
          <CardContent
            sx={{
              display: {
                xs: 'flex',
                sm: 'none',
              },
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Typography variant='h6' color='primary'>
              {props.item.id}
            </Typography>
            <Typography
              gutterBottom
              color='text.secondary'
              sx={{
                fontSize: '14px',
              }}
            >
              {handleDateTime(props.item.registerDateTime)}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '14px',
              }}
            >
              Placa: {props.item.plate}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '14px',
              }}
            >
              Categoria: {props.item.type}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: '14px',
              }}
            >
              Modelo: {props.item.brand}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
