/* eslint-disable pii/no-phone-number */
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  irregularitiesSlice,
  fetchGetIrregularityById,
  fetchGetIrregularityByPlate,
} from '../../../stores';
import { handleApiReturn } from '../../../utils';
import 'react-credit-cards/es/styles-compiled.css';
import { palette } from '../../../theme';

export const SearchIrregularitiesModal: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { searchIsOpen, searchingByCode } = useSelector(
    (state) => state.irregularitiesReducer,
  );
  const [searchValue, setSearchValue] = useState('');

  const handleClose = () => {
    setSearchValue('');
    dispatch(irregularitiesSlice.actions.toggleSearchIsOpen());
  };

  const handleSubmit = () => {
    if (searchingByCode) {
      dispatch(
        fetchGetIrregularityById({
          driverId: driverId.toString(),
          irregularityId: searchValue,
        }),
      ).then((res: any) => {
        handleApiReturn(
          res,
          () => {
            dispatch(irregularitiesSlice.actions.toggleSearchIsOpen());
            dispatch(irregularitiesSlice.actions.toggleShowDetails());
            setSearchValue('');
          },
          null,
        );
      });
    } else {
      dispatch(
        fetchGetIrregularityByPlate({
          driverId: driverId.toString(),
          plate: searchValue,
        }),
      ).then((res: any) => {
        handleApiReturn(
          res,
          () => {
            if (res.payload.data.list.length > 0) {
              dispatch(irregularitiesSlice.actions.toggleSearchIsOpen());
              setSearchValue('');
            } else {
              toast.warning('Placa sem irregularidades');
            }
          },
          null,
        );
      });
    }
  };

  return (
    <Modal
      open={searchIsOpen}
      onClose={handleClose}
      title={`Busca de irregularidade por ${
        searchingByCode ? 'código' : 'placa'
      }`}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography variant='h6' sx={{ color: palette.text.main }}>
          {searchingByCode
            ? 'Digite o código da irregularidade presente no seu comprovante'
            : 'Digite a placa do veículo que você quer regularizar'}
        </Typography>
        <Input
          data-testid='searchValue'
          type='text'
          margin='normal'
          label={searchingByCode ? 'Código' : 'Placa'}
          mask={searchingByCode ? 'irregularityCode' : 'plate'}
          value={searchValue}
          onChange={(value) => {
            setSearchValue(value);
          }}
          sx={{
            width: '60%',
            marginBottom: '30px',
          }}
        />
        <Button
          variant='contained'
          label='Buscar'
          type='submit'
          onClick={handleSubmit}
          color='secondary'
          sx={{
            width: `90%`,
          }}
        />
      </Grid>
    </Modal>
  );
};
