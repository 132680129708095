import { Typography, Grid, Skeleton } from '@mui/material';
import { find, map, split, trim } from 'lodash';
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Button, Dropdown } from '../../components';
import {
  irregularitiesSlice,
  paymentMethodsSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { handleDateTime, handleMoney } from '../../utils';
import { CreatePaymentMethodModal } from '../PaymentMethods/modals/CreatePaymentMethodModal';

export const IrregularityListDetails = () => {
  const dispatch = useDispatch();
  const { cpf } = useSelector((state) => state.profileReducer);
  const { paymentMethods } = useSelector(
    (state) => state.paymentMethodsReducer,
  );
  const { irregularitiesLoading, irregularityListDetails } = useSelector(
    (state) => state.irregularitiesReducer,
  );
  const [paymentMethodText, setPaymentMethodText] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<
    | {
        id: number;
        number: string;
        brand: string;
        bankId: number | null;
        default: 0 | 1;
      }
    | 'PIX'
    | undefined
  >(undefined);
  const [error, setError] = useState<string | undefined>();

  const handleSelectedPaymentMethod = (e: string) => {
    if (e !== 'PIX') {
      const paymentMethod = find(paymentMethods, [
        'number',
        trim(split(e.toString(), ' ')[1]),
      ]);
      setPaymentMethod(paymentMethod);
    } else {
      setPaymentMethod('PIX');
    }
    setPaymentMethodText(e.toString());
  };

  const handleSubmit = () => {
    if (!paymentMethod) {
      setError('Escolha um meio de pagamento');
    } else {
      setError(undefined);

      if (irregularityListDetails) {
        if (paymentMethod === 'PIX') {
          if (!cpf) {
            dispatch(
              irregularitiesSlice.actions.toggleEditCPFIrregularitiesIsOpen(),
            );
          } else {
            dispatch(irregularitiesSlice.actions.setPaymentMethod('PIX'));
            dispatch(irregularitiesSlice.actions.toggleRegularizeListIsOpen());
          }
        } else {
          dispatch(irregularitiesSlice.actions.setPaymentMethod(paymentMethod));
          dispatch(irregularitiesSlice.actions.toggleRegularizeListIsOpen());
        }
      }
    }
  };

  const handleCarouselList = () => {
    const list: React.JSX.Element[] = [];
    map(irregularityListDetails?.irregularities, (item) => {
      list.push(
        <div style={{ padding: '10px' }}>
          <img
            alt='local'
            src={item.locationImg}
            style={{ borderRadius: '10px' }}
          />
        </div>,
      );
      list.push(
        <div style={{ padding: '10px' }}>
          <img
            alt='placa'
            src={item.plateImg}
            style={{ borderRadius: '10px' }}
          />
        </div>,
      );
    });
    return list;
  };

  return irregularitiesLoading ? (
    <>
      <Skeleton width='100%' height={30} />
      <Skeleton variant='rectangular' width='100%' height={118} />
      <Skeleton width='60%' />
      <Skeleton width='45%' />
      <Skeleton width='80%' />
      <Skeleton width='75%' />
      <Skeleton width='60%' />
    </>
  ) : (
    <>
      <Grid
        container
        rowSpacing={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        <Grid
          item
          xs={12}
          marginBottom={{
            sm: 3,
          }}
          sx={{
            textAlign: 'center',
            width: '100%',
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography
            color='primary'
            sx={{
              fontSize: { xs: '4.5vw', sm: '20px' },
              fontWeight: 'bold',
            }}
          >
            Detalhes da(s) irregularidade(s){' '}
            {map(irregularityListDetails?.irregularities, (item, index) => {
              if (
                irregularityListDetails?.irregularities.length &&
                index === irregularityListDetails?.irregularities.length - 1
              ) {
                return `${item.id.toString()}`;
              } else {
                return `${item.id.toString()}, `;
              }
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} sx={{ padding: 1 }}>
          <Carousel
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            {handleCarouselList()}
          </Carousel>
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <Grid xs={12} margin={1}>
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            >
              <b>Placa:</b> {irregularityListDetails?.payment?.plate}
            </Typography>
          </Grid>
          <Grid xs={12} margin={1}>
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            >
              <b>Entrada:</b>{' '}
              {irregularityListDetails?.payment?.entryDateTime &&
                handleDateTime(irregularityListDetails?.payment?.entryDateTime)}
            </Typography>
          </Grid>
          <Grid xs={12} margin={1}>
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            >
              <b>Saída:</b>{' '}
              {irregularityListDetails?.payment?.exitDateTime &&
                handleDateTime(irregularityListDetails?.payment?.exitDateTime)}
            </Typography>
          </Grid>
          <Grid xs={12} margin={1}>
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            >
              <b>Período:</b>{' '}
              {irregularityListDetails?.payment?.stayTime &&
                irregularityListDetails?.payment?.stayTime}
            </Typography>
          </Grid>
          <Grid xs={12} margin={1}>
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '14px', sm: '16px' } }}
            >
              <b>Valor:</b>{' '}
              {irregularityListDetails?.payment?.price &&
                handleMoney(irregularityListDetails?.payment?.price)}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            rowSpacing={3}
            margin={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Dropdown
              options={[
                'PIX',
                ...map(paymentMethods, (item) => {
                  return `**** ${item.number} (${item.brand})`;
                }),
              ]}
              data-testid='paymentMethod'
              margin='normal'
              label='Meio de pagamento'
              type='text'
              value={paymentMethodText}
              onChange={handleSelectedPaymentMethod}
              errorMessage={error}
            />
          </Grid>
          <Grid
            sx={{
              width: '100%',
              display: 'flex',
              flexGrow: 1,
              margin: 'auto',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <Button
              variant='text'
              label='Cadastrar meio de pagamento'
              type='submit'
              onClick={() => {
                dispatch(
                  paymentMethodsSlice.actions.toggleCreatePaymentMethodIsOpen(),
                );
                // navigate(PAYMENT_METHODS);
              }}
              color='primary'
              style={{ width: '100%', marginTop: 10 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          margin: 'auto',
          paddingTop: '20px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        order={4}
      >
        <Button
          variant='contained'
          label='Pagar irregularidades'
          onClick={handleSubmit}
          color='secondary'
          loading={irregularitiesLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
        <Button
          label='Voltar'
          type='submit'
          onClick={() => {
            dispatch(
              irregularitiesSlice.actions.resetIrregularityListDetails(),
            );
            dispatch(irregularitiesSlice.actions.toggleShowListDetails());
          }}
          color='primary'
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <CreatePaymentMethodModal />
    </>
  );
};
