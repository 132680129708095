import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { palette } from "./theme";

export const AppStyles = css`
  body,
  ul {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
    background-color: ${palette.background.main};
  }

  #main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;

export const StyledAppOuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  backgroundcolor: ${palette.background.main};
`;

export const StyledAppMidWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledAppInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
