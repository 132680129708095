/**
 * theme for MUI
 * TODO: create a theme object as per designs
 */
import { ptBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { desaturate } from 'polished';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import logoNiteroiRotativo from '../assets/images/logo_niteroi_rotativo.png';

export const logo = logoNiteroiRotativo;

export const title = 'Niterói Rotativo';

export const screenBreakpoints = {
  xs: 0, // smartphones: up to 425px
  sm: 426, // tablets: from 425px to 768px
  md: 769, // laptops: from 768px to 1024px
  lg: 1025, // large laptops and desktops: from 1024px to 1440px
  xl: 2561, // 4k: above 1024px
};

export const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: screenBreakpoints.xs,
        sm: screenBreakpoints.sm,
        md: screenBreakpoints.md,
        lg: screenBreakpoints.lg,
        xl: screenBreakpoints.xl,
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#721B7D',
        contrastText: '#fff',
      },
      secondary: {
        main: '#FF7300',
        contrastText: '#fff',
      },
      error: {
        main: '#e62929',
        contrastText: '#fff',
      },
      success: {
        main: '#1aca3d',
        contrastText: '#fff',
      },
      warning: {
        main: '#e8d22e',
        contrastText: '#fff',
      },
      divider: '#e0e0e0',
      text: {
        main: '#666666',
        contrastText: '#fff',
      },
      background: {
        main: '#c0c0c0',
        contrastText: '#000',
      },
      action: {
        disabledBackground: desaturate(0.5, '#FF7300'),
        // disabled: 'set color of text here'
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
        // Using this will stop tranforming all button texts to UPPERCASE
      },
    },
  },
  ptBR,
);

export const palette = theme.palette;
