import * as Yup from 'yup';

const phoneRegExp = /^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/;
const dateRegExp = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/[12][0-9]{3}$/;
const strongPasswordRegExp =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W]).{8,}$/;

export const validationSchema = Yup.object({
  name: Yup.string().required('Insira seu nome'),
  email: Yup.string()
    .email('Email deve ser válido')
    .required('Insira seu email'),
  emailConfirmation: Yup.string()
    .email()
    .oneOf([Yup.ref('email'), null], 'Emails devem ser iguais')
    .required('Confirme seu email'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Telefone não é válido')
    .required('Insira seu telefone'),
  birthdate: Yup.string()
    .matches(dateRegExp, 'Data não é válida')
    .required('Insira sua data de nascimento'),
  password: Yup.string()
    .required('A senha é obrigatória')
    .matches(strongPasswordRegExp, {
      message: 'A senha precisa ter formato especificado',
      excludeEmptyString: true,
    }),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais')
    .required('Confirme a senha'),
});
