import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { findIndex, orderBy } from 'lodash';
import {
  fetchCreateVehicleService,
  fetchSetDefaultVehicleService,
  fetchDeleteVehicleService,
  fetchGetVehicleListService,
} from '../../services/Vehicles';
import { VehiclesStateType } from '../types';

const initialState: VehiclesStateType = {
  vehiclesLoading: false,
  vehicles: [],
  deleteVehicleIsOpen: false,
  createVehicleIsOpen: false,
};

export const fetchCreateVehicle = createAsyncThunk(
  'createVehicle',
  async (params: {
    driverId: string;
    plate: string;
    type: string;
    model: string;
  }) => {
    return await fetchCreateVehicleService(params);
  },
);

export const fetchSetDefaultVehicle = createAsyncThunk(
  'setDefaultVehicle',
  async (params: { driverId: string; vehicleId: string }) => {
    return await fetchSetDefaultVehicleService(params);
  },
);

export const fetchDeleteVehicle = createAsyncThunk(
  'deleteVehicle',
  async (params: { driverId: string; vehicleId: string }) => {
    return await fetchDeleteVehicleService(params);
  },
);

export const fetchGetVehicleList = createAsyncThunk(
  'getVehicleList',
  async (params: { driverId: string }) => {
    return await fetchGetVehicleListService(params);
  },
);

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    toggleVehiclesLoading(state) {
      state.vehiclesLoading = !state.vehiclesLoading;
    },
    setVehicles(
      state,
      action: PayloadAction<
        {
          id: number;
          plate: string;
          type: string;
          model: string;
          default: number;
          used: number;
        }[]
      >,
    ) {
      return {
        ...state,
        vehicles: action.payload,
      };
    },
    toggleDeleteVehicleIsOpen(state) {
      state.deleteVehicleIsOpen = !state.deleteVehicleIsOpen;
    },
    toggleCreateVehicleIsOpen(state) {
      state.createVehicleIsOpen = !state.createVehicleIsOpen;
    },
    setUsedVehicle(state, action: PayloadAction<number>) {
      const idx = findIndex(state.vehicles, ['id', action.payload]);
      if (idx !== -1) {
        state.vehicles[idx].used = 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateVehicle.pending, (state) => {
      return {
        ...state,
        vehiclesLoading: true,
      };
    }),
      builder.addCase(fetchCreateVehicle.fulfilled, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      }),
      builder.addCase(fetchCreateVehicle.rejected, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      });

    builder.addCase(fetchSetDefaultVehicle.pending, (state) => {
      return {
        ...state,
        vehiclesLoading: true,
      };
    }),
      builder.addCase(fetchSetDefaultVehicle.fulfilled, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      }),
      builder.addCase(fetchSetDefaultVehicle.rejected, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      });

    builder.addCase(fetchDeleteVehicle.pending, (state) => {
      return {
        ...state,
        vehiclesLoading: true,
      };
    }),
      builder.addCase(fetchDeleteVehicle.fulfilled, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      }),
      builder.addCase(fetchDeleteVehicle.rejected, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      });

    builder.addCase(fetchGetVehicleList.pending, (state) => {
      return {
        ...state,
        vehiclesLoading: true,
      };
    }),
      builder.addCase(fetchGetVehicleList.fulfilled, (state, res) => {
        return {
          ...state,
          vehicles: orderBy(res.payload.data, ['default'], ['desc']),
          vehiclesLoading: false,
        };
      }),
      builder.addCase(fetchGetVehicleList.rejected, (state, res) => {
        return {
          ...state,
          vehiclesLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleVehiclesLoading,
  toggleDeleteVehicleIsOpen,
  toggleCreateVehicleIsOpen,
  setUsedVehicle,
} = vehiclesSlice.actions;
