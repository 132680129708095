import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Input } from '../../../components';
import { LOGIN } from '../../../routes';
import {
  useSelector,
  authSlice,
  fetchEmailGetCode,
  fetchCellphoneGetCode,
  useDispatch,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import {
  StyledStepWrapper,
  StyledTextWrapper,
  StyledInputWrapper,
  StyledButtonsWrapper,
} from './styles';
import { validationPhoneSchema, validationEmailSchema } from './validations';

export const StepOne = (props: {
  handleNext: () => any;
  // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
  // fbData: {
  //   facebookId: string;
  //   name: string;
  //   email: string;
  // } | null;
  isSignupWithFB: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { validationType, authLoading } = useSelector(
    (state) => state.authReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    number: '',
    // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
    // email: props.fbData ? props.fbData.email : '',
    email: '',
  });

  const handleSubmit = () => {
    setErrors({});
    // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
    // if (validationType !== 'phone' || props.fbData) {
    if (validationType !== 'phone') {
      validationEmailSchema
        .validate(formData, { abortEarly: false })
        .then(() =>
          dispatch(fetchEmailGetCode({ email: formData.email })).then(
            (res: any) => {
              handleApiReturn(
                res,
                () => {
                  toast.success('Código enviado para email com sucesso.');
                  props.handleNext();
                },
                null,
              );
            },
          ),
        )
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    } else {
      validationPhoneSchema
        .validate(formData, { abortEarly: false })
        .then(() =>
          dispatch(
            fetchCellphoneGetCode({
              number: VMasker.toNumber(formData.number),
            }),
          ).then((res: any) => {
            handleApiReturn(
              res,
              () => {
                toast.success('Código enviado para telefone com sucesso.');
                props.handleNext();
              },
              null,
            );
          }),
        )
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    }
  };

  const handleReturn = () => {
    navigate(LOGIN);
    validationType === 'email' &&
      dispatch(authSlice.actions.toggleValidationType());
  };

  const toggleValidationType = () => {
    setFormData({
      number: '',
      email: '',
    });
    dispatch(authSlice.actions.toggleValidationType());
  };

  return (
    <StyledStepWrapper>
      <StyledTextWrapper>
        <Typography
          color='primary'
          textAlign='center'
          variant='h4'
          gutterBottom
        >
          Olá, motorista!
        </Typography>
        <Typography color={palette.text.main} textAlign='center' gutterBottom>
          {/* RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION */}
          {/* {validationType !== 'phone' || props.fbData */}
          {validationType !== 'phone'
            ? 'Digite seu email para enviarmos um email com um código para cadastro no aplicativo'
            : 'Digite seu telefone para enviarmos um SMS com um código para cadastro no aplicativo'}
        </Typography>
      </StyledTextWrapper>
      <StyledInputWrapper>
        {/* RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION */}
        {/* {validationType !== 'phone' || props.fbData ? ( */}
        {validationType !== 'phone' ? (
          <Input
            name='email'
            type='text'
            mask='email'
            label='Email'
            margin='normal'
            value={formData.email}
            // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
            // disabled={!!props.fbData}
            onChange={(value: string) =>
              setFormData({ ...formData, email: value })
            }
            maxLength={15}
            errorMessage={errors.email}
            style={{ width: '100%', maxWidth: '200px' }}
          />
        ) : (
          <Input
            name='phone'
            type='text'
            mask='phone'
            label='Telefone'
            margin='normal'
            value={formData.number}
            onChange={(value: string) =>
              setFormData({ ...formData, number: value })
            }
            // keyboardType='numeric'
            maxLength={15}
            errorMessage={errors.number}
            style={{ width: '100%', maxWidth: '200px' }}
          />
        )}
        <Button
          variant='contained'
          label={
            // RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION
            // validationType !== 'phone' || props.fbData
            //   ? 'Validar email'
            //   : 'Validar telefone'
            validationType === 'phone' ? 'Validar telefone' : 'Validar email'
          }
          type='submit'
          onClick={handleSubmit}
          color='secondary'
          loading={authLoading}
          style={{ width: '100%', maxWidth: '350px', marginTop: '20px' }}
        />
        {/* RESTORE THIS WHEN API CAN SIGNUP USER WITH FACEBOOK USING ONLY EMAIL VALIDATION */}
        {/* {!props.fbData && ( */}
        {!props.isSignupWithFB && (
          <Button
            label={
              validationType === 'phone'
                ? 'Problema com o recebimento do SMS?'
                : 'Voltar para validação de telefone'
            }
            style={{ width: '100%', maxWidth: '350px', marginTop: 10 }}
            onClick={toggleValidationType}
          />
        )}
      </StyledInputWrapper>
      <StyledButtonsWrapper>
        <Button
          label='Voltar'
          style={{ width: '100%', maxWidth: '350px' }}
          onClick={handleReturn}
        />
      </StyledButtonsWrapper>
    </StyledStepWrapper>
  );
};
