import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Modal } from '../../../components';
import { PIX } from '../../../routes';
import {
  useDispatch,
  useSelector,
  fetchRegularizeWithPix,
  irregularitiesSlice,
  fetchRegularize,
  fetchGetIrregularityByPlate,
} from '../../../stores';
import { handleApiReturn, handleDateTime, handleMoney } from '../../../utils';
import { StyledModalWrapper } from '../styles';
import 'react-credit-cards/es/styles-compiled.css';

export const RegularizeModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const {
    irregularityDetails,
    paymentMethod,
    regularizeIsOpen,
    irregularitiesLoading,
    searchedPlate,
  } = useSelector((state) => state.irregularitiesReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);

  const handleClose = () => {
    dispatch(irregularitiesSlice.actions.toggleRegularizeIsOpen());
  };

  const handleSubmit = async () => {
    if (driverId && irregularityDetails && paymentMethod) {
      if (paymentMethod === 'PIX') {
        dispatch(
          fetchRegularizeWithPix({
            driverId: driverId.toString(),
            irregularityId: irregularityDetails.irregularity.id.toString(),
            plate: irregularityDetails.payment.plate,
            type: irregularityDetails.payment.type,
            entryDateTime: irregularityDetails.payment.entryDateTime,
            exitDateTime: irregularityDetails.payment.exitDateTime,
            stayTime: irregularityDetails.payment.stayTime,
            price: irregularityDetails.payment.price.toString(),
            routeId: irregularityDetails.payment.routeId,
            driverDocument: VMasker.toNumber(cpf).toString(),
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(irregularitiesSlice.actions.toggleRegularizeIsOpen());
              dispatch(irregularitiesSlice.actions.toggleShowDetails());
              toast.success('Pix gerado com sucesso.');
              navigate(PIX);
            },
            null,
          );
        });
      } else {
        await axios.get('https://api.ipify.org/?format=json').then((res) => {
          const ipAddress = res.data.ip;
          dispatch(
            fetchRegularize({
              irregularityId: irregularityDetails.irregularity.id.toString(),
              driverId: driverId.toString(),
              plate: irregularityDetails?.payment?.plate,
              type: irregularityDetails?.payment?.type,
              entryDateTime: irregularityDetails?.payment?.entryDateTime,
              exitDateTime: irregularityDetails?.payment?.exitDateTime,
              stayTime: irregularityDetails?.payment?.stayTime,
              price: irregularityDetails?.payment?.price,
              routeId: irregularityDetails?.payment?.routeId,
              vehicleId: irregularityDetails?.payment?.vehicleId,
              paymentMethodId: paymentMethod.id,
              ipAddress: ipAddress,
            }),
          ).then((res: any) => {
            searchedPlate &&
              handleApiReturn(
                res,
                () => {
                  dispatch(
                    fetchGetIrregularityByPlate({
                      driverId: driverId.toString(),
                      plate: searchedPlate,
                    }),
                  ).then((res: any) => {
                    handleApiReturn(
                      res,
                      () => {
                        dispatch(
                          irregularitiesSlice.actions.toggleRegularizeIsOpen(),
                        );
                        dispatch(
                          irregularitiesSlice.actions.toggleShowDetails(),
                        );
                        toast.success('Irregularidade paga com sucesso.');
                      },
                      null,
                    );
                  });
                },
                null,
              );
          });
        });
      }
    }
  };

  return (
    <Modal
      open={regularizeIsOpen}
      onClose={handleClose}
      title={`Regularização da irregularidade ${irregularityDetails?.irregularity.id}`}
    >
      <StyledModalWrapper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Placa:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityDetails?.irregularity.plate}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Entrada:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityDetails?.payment?.entryDateTime &&
                handleDateTime(irregularityDetails?.payment?.entryDateTime)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Saída:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityDetails?.payment?.exitDateTime &&
                handleDateTime(irregularityDetails?.payment.exitDateTime)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Período:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityDetails?.payment?.stayTime &&
                irregularityDetails?.payment.stayTime}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Valor:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {irregularityDetails?.payment?.price &&
                handleMoney(irregularityDetails?.payment.price)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='primary'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Meio de pagamento:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='primary' textAlign='center' gutterBottom>
              {paymentMethod && typeof paymentMethod !== 'string'
                ? `**** ${paymentMethod.number} (${paymentMethod.brand})`
                : 'PIX'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Button
            variant='contained'
            label='Pagar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={irregularitiesLoading || pixLoading}
            style={{ width: '100%' }}
          />
        </Grid>
      </StyledModalWrapper>
    </Modal>
  );
};
