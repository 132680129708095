import { styled } from '@mui/material';

export const StyledStepWrapper = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledTextWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledInputWrapper = styled('div')(() => ({
  width: '75%',
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'center',
  alignItems: 'center',
}));

export const StyledButtonsWrapper = styled('div')(() => ({
  marginTop: 20,
  width: '75%',
  minWidth: '250px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
