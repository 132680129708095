import creditCardType from 'credit-card-type';
import React, { useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  paymentMethodsSlice,
  fetchCreatePaymentMethod,
  fetchGetPaymentMethodList,
} from '../../../stores';
import { handleApiReturn, handleErrors } from '../../../utils';
// import { paymentMethodTypesOptions } from '../resources';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledModalWrapper,
} from '../styles';
import { validationSchema } from '../validations';
import 'react-credit-cards/es/styles-compiled.css';
import { split } from 'lodash';

const paymentMethodModelOptions = require('../../../assets/brands.json');

export const CreatePaymentMethodModal: React.FC = () => {
  const dispatch = useDispatch();
  const { createPaymentMethodIsOpen, paymentMethodsLoading } = useSelector(
    (state) => state.paymentMethodsReducer,
  );
  const { name } = useSelector((state) => state.profileReducer);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    number: '',
    holder: '',
    validity: '',
    cvv: '',
    brand: '',
  });
  const [focus, setFocus] = useState<Focused | undefined>();

  const verifyCardType = (value: string) => {
    if (value) {
      const [cardType] = creditCardType(value);
      const brandType = cardType ? cardType.niceType : '';

      switch (brandType) {
        case 'Maestro':
          setFormData({ ...formData, brand: 'Master', number: value });
          break;
        case 'Mastercard':
          setFormData({ ...formData, brand: 'Master', number: value });
          break;
        case 'Diners Club':
          setFormData({ ...formData, brand: 'Diners', number: value });
          break;
        case 'American Express':
          setFormData({ ...formData, brand: 'Amex', number: value });
          break;
        default:
          setFormData({ ...formData, brand: brandType, number: value });
          break;
      }
    } else {
      setFormData({ ...formData, brand: '', number: value });
    }
  };

  const handleValidity = (validity: string) => {
    const splitValidity = split(validity, '/');
    return `${splitValidity[0]}/20${splitValidity[1]}`;
  };

  const handleClose = () => {
    setFormData({
      number: '',
      holder: '',
      validity: '',
      cvv: '',
      brand: '',
    });
    dispatch(paymentMethodsSlice.actions.toggleCreatePaymentMethodIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchCreatePaymentMethod({
            driverId: localStorage.getItem('NITROT:driverId') || '',
            number: VMasker.toNumber(formData.number),
            driverName: name,
            holder: formData.holder,
            validity: `${handleValidity(formData.validity)}`,
            cvv: formData.cvv,
            brand: formData.brand,
            bankId: 0,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              dispatch(
                fetchGetPaymentMethodList({
                  driverId: localStorage.getItem('NITROT:driverId') || '',
                }),
              );
              toast.success('Método de pagamento criado com sucesso.');
              handleClose();
            },
            null,
          );
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <Modal
      open={createPaymentMethodIsOpen}
      onClose={handleClose}
      title='Cadastro de meio de pagamento'
    >
      <StyledModalWrapper>
        <Cards
          cvc={formData.cvv}
          expiry={formData.validity}
          focused={focus}
          name={formData.holder}
          number={formData.number}
          placeholders={{
            name: 'SEU NOME AQUI',
          }}
          locale={{
            valid: 'Válido até',
          }}
        />
        <StyledInputWrapper>
          <Input
            data-testid='number'
            margin='normal'
            label='Número'
            type='text'
            mask='cardNumber'
            value={formData.number}
            onChange={(value) => verifyCardType(value)}
            errorMessage={errors && errors.number}
            onFocus={() => setFocus('number')}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='holder'
            margin='normal'
            label='Nome do titular'
            type='text'
            value={formData.holder}
            onChange={(value) => setFormData({ ...formData, holder: value })}
            errorMessage={errors && errors.holder}
            onFocus={() => setFocus('name')}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='validity'
            margin='normal'
            label='Validade'
            type='text'
            mask='cardValidity'
            value={formData.validity}
            onChange={(value) => setFormData({ ...formData, validity: value })}
            errorMessage={errors && errors.validity}
            onFocus={() => setFocus('expiry')}
            inputProps={{ maxLength: 5 }}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='cvv'
            margin='normal'
            label='CVV'
            type='text'
            mask='cardCVV'
            value={formData.cvv}
            onChange={(value) => setFormData({ ...formData, cvv: value })}
            errorMessage={errors && errors.cvv}
            onFocus={() => setFocus('cvc')}
          />
        </StyledInputWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={paymentMethodsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledModalWrapper>
    </Modal>
  );
};
