/* eslint-disable pii/no-phone-number */
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  number: Yup.string()
    .min(19, 'Mínimo de 13 caracteres')
    .required('Número é obrigatório'),
  holder: Yup.string().required('Nome é obrigatório'),
  validity: Yup.string()
    .min(5, 'Validade está no formato errado')
    .required('Validade é obrigatório'),
  cvv: Yup.string().min(3, 'CVV inválido').required('CVV é obrigatório'),
});
