import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchLoginService,
  fetchCellphoneGetCodeService,
  fetchEmailGetCodeService,
  fetchCellphoneValidateCodeService,
  fetchEmailValidateCodeService,
  fetchSignupService,
  fetchLoginWithFBService,
  fetchPasswordRecoveryService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { AuthStateType } from '../types';
import moment from 'moment';

const initialState: AuthStateType = {
  authLoading: false,
  phone: null,
  email: null,
  validationType: 'phone',
  signupId: '',
  token: null,
  tokenExpiration: null,
};

export const fetchLogin = createAsyncThunk(
  'login',
  async (params: { email: string; password: string }) => {
    return await fetchLoginService(params);
  },
);

export const fetchCellphoneGetCode = createAsyncThunk(
  'cellphoneGetCode',
  async (params: { number: string }) => {
    return await fetchCellphoneGetCodeService(params);
  },
);

export const fetchEmailGetCode = createAsyncThunk(
  'emailGetCode',
  async (params: { email: string }) => {
    return await fetchEmailGetCodeService(params);
  },
);

export const fetchCellphoneValidateCode = createAsyncThunk(
  'cellphoneValidateCode',
  async (params: { number: string; code: string }) => {
    return await fetchCellphoneValidateCodeService(params);
  },
);

export const fetchEmailValidateCode = createAsyncThunk(
  'emailValidateCode',
  async (params: { email: string; code: string }) => {
    return await fetchEmailValidateCodeService(params);
  },
);

export const fetchSignup = createAsyncThunk(
  'signup',
  async (params: {
    name: string;
    email: string;
    cellphone: string;
    birth: string;
    password: string;
    validationCode: string;
    facebookId: string | null;
    emailValidation: 0 | 1;
  }) => {
    return await fetchSignupService(params);
  },
);

export const fetchLoginWithFB = createAsyncThunk(
  'loginWithFB',
  async (params: { facebookId: string }) => {
    return await fetchLoginWithFBService(params);
  },
);

export const fetchPasswordRecovery = createAsyncThunk(
  'passwordRecovery',
  async (params: { email: string }) => {
    return await fetchPasswordRecoveryService(params);
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      return {
        ...state,
        token: null,
        tokenExpiration: null,
      };
    },
    toggleAuthLoading(state) {
      state.authLoading = !state.authLoading;
    },
    toggleValidationType(state) {
      state.validationType =
        state.validationType === 'phone' ? 'email' : 'phone';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchLogin.fulfilled, (state, res) => {
        return {
          ...state,
          token: res.payload.data.token,
          tokenExpiration: moment().add(12, 'hours').toString(),
          authLoading: false,
        };
      }),
      builder.addCase(fetchLogin.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchCellphoneGetCode.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchCellphoneGetCode.fulfilled, (state, res) => {
        return {
          ...state,
          phone: res.payload.data.number,
          authLoading: false,
        };
      }),
      builder.addCase(fetchCellphoneGetCode.rejected, (state, res) => {
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchEmailGetCode.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchEmailGetCode.fulfilled, (state, res) => {
        return {
          ...state,
          email: res.payload.data.email,
          authLoading: false,
        };
      }),
      builder.addCase(fetchEmailGetCode.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchCellphoneValidateCode.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchCellphoneValidateCode.fulfilled, (state, res) => {
        return {
          ...state,
          signupId: res.payload.data.id,
          authLoading: false,
        };
      }),
      builder.addCase(fetchCellphoneValidateCode.rejected, (state, res) => {
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchEmailValidateCode.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchEmailValidateCode.fulfilled, (state, res) => {
        return {
          ...state,
          signupId: res.payload.data.id,
          authLoading: false,
        };
      }),
      builder.addCase(fetchEmailValidateCode.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchSignup.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchSignup.fulfilled, (state, res) => {
        return {
          ...state,
          token: res.payload.data.token,
          authLoading: false,
        };
      }),
      builder.addCase(fetchSignup.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchLoginWithFB.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchLoginWithFB.fulfilled, (state, res) => {
        return {
          ...state,
          token: res.payload.data.token,
          authLoading: false,
        };
      }),
      builder.addCase(fetchLoginWithFB.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchPasswordRecovery.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchPasswordRecovery.fulfilled, (state, res) => {
        return {
          ...state,
          authLoading: false,
        };
      }),
      builder.addCase(fetchPasswordRecovery.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { logout, toggleAuthLoading, toggleValidationType } =
  authSlice.actions;
