import * as Yup from 'yup';

const phoneRegExp = /^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/;

export const validationPhoneSchema = Yup.object({
  number: Yup.string()
    .matches(phoneRegExp, 'Telefone não é válido')
    .required('Insira um telefone'),
});

export const validationEmailSchema = Yup.object({
  email: Yup.string().required('Insira um email').email(),
});
