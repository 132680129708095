import { Grid, Box, Typography } from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ContentBox } from '../../components';
import { HISTORY } from '../../routes';
import {
  fetchGetHistoryList,
  historySlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import useCurrentBreakpoint from '../../utils/useCurrentBreakpoint';
import useWindowDimensions from '../../utils/useWidowDimensions';
import { TicketDetaisModal } from './modals/TicketDetailsModal';
import { ServiceOrderBox } from './ServiceOrderBox';

export const History: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentBreakpoint = useCurrentBreakpoint();
  const { browserHeight } = useWindowDimensions();
  const ref = useRef();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { historyLoading, history } = useSelector(
    (state) => state.historyReducer,
  );

  useEffect(() => {
    if (location.pathname === HISTORY) {
      const pageSize =
        currentBreakpoint === 'xl'
          ? Math.floor(browserHeight / 284) * 3 + 1
          : currentBreakpoint === 'lg' || currentBreakpoint === 'md'
          ? Math.floor(browserHeight / 284) * 2 + 1
          : Math.floor(browserHeight / 284) + 1;
      dispatch(historySlice.actions.setPageSize(pageSize));
      dispatch(historySlice.actions.resetOffset());
      dispatch(
        fetchGetHistoryList({
          driverId: driverId.toString(),
          reset: true,
        }),
      );
    }
  }, [location.pathname, currentBreakpoint]);

  const onScrollY = (e: any) => {
    if (e) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      bottom &&
        dispatch(
          fetchGetHistoryList({
            driverId: driverId.toString(),
          }),
        );
    }
  };

  useEffect(() => {
    const unsubscribe = document
      ?.getElementById('historyBox')
      ?.addEventListener('scroll', onScrollY, true);

    return unsubscribe;
  }, []);

  return (
    <ContentBox ref={ref} loading={historyLoading}>
      {history.length > 0 ? (
        <Box
          id='historyBox'
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflowY: 'auto',
            paddingTop: {
              xs: '20px',
              sm: 0,
            },
            paddingBottom: {
              xs: '20px',
              sm: 0,
            },
          }}
        >
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: 0,
              paddingBottom: {
                xs: 0,
                md: '50px',
              },
            }}
          >
            {map(history, (item) => {
              return <ServiceOrderBox {...item} />;
            })}
          </Grid>
        </Box>
      ) : (
        <Grid
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color={palette.text.main}
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                lg: '18px',
              },
            }}
          >
            Nenhum ticket no histórico
          </Typography>
        </Grid>
      )}
      {(currentBreakpoint === 'md' ||
        currentBreakpoint === 'lg' ||
        currentBreakpoint === 'xl') && (
        <Grid
          sx={{
            flexGrow: 1,
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: `100%`,
            maxWidth: '920px',
          }}
        >
          <Button
            variant='contained'
            label='Carregar tickets mais antigos'
            type='submit'
            onClick={() => {
              const pageSize =
                currentBreakpoint === 'xl'
                  ? Math.floor(browserHeight / 284) * 3 + 1
                  : currentBreakpoint === 'lg' || currentBreakpoint === 'md'
                  ? Math.floor(browserHeight / 284) * 2 + 1
                  : Math.floor(browserHeight / 284) + 1;
              dispatch(historySlice.actions.setPageSize(pageSize));
              dispatch(
                fetchGetHistoryList({
                  driverId: driverId.toString(),
                }),
              );
            }}
            color='secondary'
            loading={historyLoading}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: `100%`,
              maxWidth: '920px',
            }}
          />
        </Grid>
      )}

      <TicketDetaisModal />
    </ContentBox>
  );
};
