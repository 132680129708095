import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  Typography,
} from '@mui/material';
import { replace } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  profileSlice,
  fetchChangePassword,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledStepWrapper,
} from '../styles';
import { validationPasswordsSchema } from '../validations';

export const ChangePasswordModal: React.FC = () => {
  const dispatch = useDispatch();
  const { changePasswordIsOpen, editLoading } = useSelector(
    (state: { profileReducer: any }) => state.profileReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  });
  const [passwordHasEightChars, setPasswordHasEightChars] = useState(false);
  const [passwordHasUpperLowerChars, setPasswordHasUpperLowerChars] =
    useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleClose = () => {
    setFormData({
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    });
    dispatch(profileSlice.actions.toggleChangePasswordIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationPasswordsSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchChangePassword({
            driverId: localStorage.getItem('NITROT:driverId') || '',
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              toast.success('Senha atualizada com sucesso.');
              handleClose();
            },
            null,
          );
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  function checkPasswordStrength(password: string) {
    // Initialize variables
    let strength = 0;

    // Check password length
    if (password.length < 8) {
      setPasswordHasEightChars(false);
    } else {
      setPasswordHasEightChars(true);
      strength += 1;
    }

    // Check for mixed case
    if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) {
      setPasswordHasUpperLowerChars(false);
    } else {
      setPasswordHasUpperLowerChars(true);
      strength += 1;
    }

    // Check for numbers
    if (!password.match(/\d/)) {
      setPasswordHasNumber(false);
    } else {
      setPasswordHasNumber(true);
      strength += 1;
    }

    // Check for special character
    if (!password.match(/\W/)) {
      setPasswordHasSpecialChar(false);
    } else {
      setPasswordHasSpecialChar(true);
      strength += 1;
    }

    setPasswordStrength(strength);
  }

  return (
    <Modal
      open={changePasswordIsOpen}
      onClose={handleClose}
      title='Mude sua senha'
    >
      <StyledStepWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='oldPassword'
            margin='normal'
            label='Senha antiga'
            type={showOldPassword ? 'text' : 'password'}
            value={formData.oldPassword}
            onChange={(value) =>
              setFormData({ ...formData, oldPassword: value })
            }
            errorMessage={errors && errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    onMouseDown={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='newPassword'
            margin='normal'
            label='Nova senha'
            type={showNewPassword ? 'text' : 'password'}
            value={formData.newPassword}
            onChange={(value) => {
              setFormData({ ...formData, newPassword: value });
              checkPasswordStrength(value);
            }}
            errorMessage={errors && errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            passwordStrength={passwordStrength}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            data-testid='passwordConfirmation'
            margin='normal'
            label='Confirme sua nova senha'
            type={showPasswordConfirmation ? 'text' : 'password'}
            value={formData.passwordConfirmation}
            onChange={(value) =>
              setFormData({ ...formData, passwordConfirmation: value })
            }
            errorMessage={errors && errors.passwordConfirmation}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                    onMouseDown={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  >
                    {showPasswordConfirmation ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Box
            component='span'
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              marginTop: '20px',
            }}
          >
            <Typography
              color={
                passwordHasEightChars ? palette.success.main : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos 8 caracteres
            </Typography>
            <Typography
              color={
                passwordHasUpperLowerChars
                  ? palette.success.main
                  : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Letras maiúsculas e minúsculas
            </Typography>
            <Typography
              color={
                passwordHasNumber ? palette.success.main : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos um número
            </Typography>
            <Typography
              color={
                passwordHasSpecialChar
                  ? palette.success.main
                  : palette.text.main
              }
              sx={{ fontSize: '11px' }}
            >
              Pelo menos um caracter especial
            </Typography>
          </Box>
        </StyledInputWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={editLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledStepWrapper>
    </Modal>
  );
};
