/* eslint-disable lodash/prefer-lodash-method */
import { lineString, point } from '@turf/helpers';
import pointToLineDistance from '@turf/point-to-line-distance';
import moment from 'moment';
import {
  assoc,
  compose,
  filter,
  lensIndex,
  lensProp,
  map,
  over,
  prepend,
  set,
  min,
  reduce,
} from 'ramda';

const now = moment();
const currentWeekDay = now.day() === 0 ? 7 : now.day();
const convertToMoment = (time) => moment(`${now.format('YYYY-MM-DD')} ${time}`);

export const calculateDistances = (path, latitude, longitude) => {
  const userLocation = point([longitude, latitude]);
  const coordinates = path.coordinates.map((coord) => [
    parseFloat(coord && coord.longitude) || 0,
    parseFloat(coord && coord.latitude) || 0,
  ]);
  const pathLocation = lineString(coordinates);

  return pointToLineDistance(userLocation, pathLocation);
};

export const handleClosestPath = (paths, latitude, longitude) => {
  const insertDistancesOnPaths = (path) => {
    return assoc(
      'distance',
      calculateDistances(path, latitude, longitude),
      path,
    );
  };

  const distanceBetweenUserAndPaths = paths.map(calculateDistances);
  const pathsWithDistanceAsProp = paths.map(insertDistancesOnPaths);

  const filterClosestDistance = reduce(
    min,
    Infinity,
    distanceBetweenUserAndPaths,
  );
  const filterClosestPath = pathsWithDistanceAsProp.filter(
    (path) => path.distance === filterClosestDistance,
  );

  const isWorking = filterClosestPath[0]
    ? validateWorkingPeriods({
        route: filterClosestPath[0],
      })
    : 0;

  if (isWorking === 1) {
    return filterClosestPath[0];
  } else if (isWorking === 2) {
    const workingTimes = getWorkingHoursToday({
      route: filterClosestPath[0],
    });
    return `O trecho não está em operação. \nHorário de funcionamento: ${workingTimes.startTime} - ${workingTimes.endTime}`;
  } else {
    return 'Trecho fora do dia de funcionamento. Qualquer dúvida entre em contato';
  }
};

export const validateWorkingPeriods = ({ route: { workingPeriods } }) => {
  if (workingPeriods) {
    let formatWorkingPeriods = workingPeriods.map((period) => {
      const toInt = (arr) => map((str) => parseInt(str, 10), arr);
      const { startTime, endTime } = period;
      let newPeriod = compose(
        assoc('splitPeriod', false),
        over(lensProp('days'), toInt),
      )(period);

      if (startTime > endTime) {
        newPeriod = set(lensProp('splitPeriod'), true, newPeriod);
      }

      return newPeriod;
    });

    const filterWorkingPeriod = filter(
      (period) => period.splitPeriod,
      formatWorkingPeriods,
    );

    if (filterWorkingPeriod.length > 0) {
      const splitedWorkingPeriod = [
        filterWorkingPeriod[0],
        filterWorkingPeriod[0],
      ];
      const enableNextDay = compose(
        over(
          lensProp('days'),
          map((id) => (id === 7 ? 1 : id + 1)),
        ),
        set(lensProp('startTime'), '00:00'),
      );
      const convertToEnableNextDay = compose(
        over(lensIndex(1), enableNextDay),
        over(lensIndex(0), set(lensProp('endTime'), '23:59')),
      );

      formatWorkingPeriods = compose(
        prepend(convertToEnableNextDay(splitedWorkingPeriod)[1]),
        prepend(convertToEnableNextDay(splitedWorkingPeriod)[0]),
        filter((period) => !period.splitPeriod),
      )(formatWorkingPeriods);
    }

    const finalWorkingPeriods = formatWorkingPeriods.filter((period) =>
      period.days.includes(currentWeekDay),
    );

    if (finalWorkingPeriods.length > 0) {
      const workingPeriodsResult = finalWorkingPeriods.map((period) => {
        const { startTime, endTime } = period;
        let isWorking = assoc('isWorking', false, period);
        const startTimeMoment = convertToMoment(startTime).subtract(1, 'hours');
        const endTimeMoment = convertToMoment(endTime);

        if (now.isAfter(startTimeMoment) && now.isBefore(endTimeMoment)) {
          isWorking = set(lensProp('isWorking'), true, isWorking);
        }

        return isWorking;
      });

      return workingPeriodsResult.filter((period) => period.isWorking).length >
        0
        ? 1
        : 2;
    }

    return 3;
  } else {
    return 0;
  }
};

export const getWorkingHoursToday = ({ route: { workingPeriods } }) => {
  if (workingPeriods) {
    const todaysPeriods = workingPeriods.filter((period) =>
      period.days.includes(currentWeekDay.toString()),
    );
    return {
      startTime: todaysPeriods[0].startTime,
      endTime: todaysPeriods[0].endTime,
    };
  }
};

export const pricesByType = (
  closestPath,
  vehicle,
  discount,
  deactivateDiscount,
) => {
  if (closestPath.prices.length > 0 && vehicle.id !== null) {
    const filterPriceByVehicleType = (price) =>
      price.type.toUpperCase() === vehicle.type.toUpperCase();
    let prices = compose(
      map((priceType) => {
        const { price } = priceType;
        return compose(
          set(lensProp('paidPrice'), parseFloat(price, 10)),
          set(lensProp('price'), parseFloat(price, 10)),
        )(priceType);
      }),
      filter(filterPriceByVehicleType),
    )(closestPath.prices);

    if (!deactivateDiscount && vehicle.used === 0) {
      const setDiscount = (amount) =>
        amount - discount.absolut <= 0 ? 0 : amount - discount.absolut;
      prices = map(over(lensProp('paidPrice'), setDiscount), prices);
    }

    return prices;
  }

  return closestPath.prices;
};
