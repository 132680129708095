import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import VMasker from 'vanilla-masker';
import { Button, Input } from '../../../components';
import {
  fetchCellphoneValidateCode,
  fetchEmailValidateCode,
  useSelector,
  useDispatch,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import {
  StyledStepWrapper,
  StyledTextWrapper,
  StyledInputWrapper,
  StyledButtonsWrapper,
} from './styles';
import { validationSchema } from './validations';

export const StepTwo = (props: {
  handleNext: () => any;
  handleBack: () => any;
  setCode: (code: string) => any;
}) => {
  const dispatch = useDispatch();
  const { validationType, authLoading, phone, email } = useSelector(
    (state) => state.authReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    code: '',
  });

  const handleSubmit = () => {
    setErrors({});

    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (validationType === 'phone') {
          dispatch(
            fetchCellphoneValidateCode({
              number: VMasker.toNumber(phone || ''),
              code: formData.code,
            }),
          ).then((res: any) => {
            handleApiReturn(
              res,
              () => {
                props.setCode(formData.code);
                props.handleNext();
              },
              null,
            );
          });
        } else {
          dispatch(
            fetchEmailValidateCode({
              email: email || '',
              code: formData.code,
            }),
          ).then((res: any) => {
            handleApiReturn(
              res,
              () => {
                props.setCode(formData.code);
                props.handleNext();
              },
              null,
            );
          });
        }
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    props.handleBack();
  };

  return (
    <StyledStepWrapper>
      <StyledTextWrapper>
        <Typography
          color='primary'
          textAlign='center'
          variant='h4'
          gutterBottom
        >
          Código enviado com sucesso!
        </Typography>
        <Typography color={palette.text.main} textAlign='center' gutterBottom>
          {validationType === 'phone'
            ? 'Por favor, digite o código de 6 dígitos que enviamos por SMS para o seu celular'
            : 'Por favor, digite o código de 6 dígitos que enviamos por SMS para o seu email'}
        </Typography>
      </StyledTextWrapper>
      <StyledInputWrapper>
        <VerificationInput
          validChars='0-9'
          inputProps={{ inputMode: 'numeric' }}
          onChange={(e) => setFormData({ ...formData, code: e })}
        />
        <Typography
          color={palette.error.main}
          textAlign='center'
          gutterBottom
          marginTop={1}
          height={10}
        >
          {errors.code}
        </Typography>
      </StyledInputWrapper>
      <StyledButtonsWrapper>
        <Button
          variant='contained'
          label='Enviar'
          type='submit'
          onClick={handleSubmit}
          color='secondary'
          loading={authLoading}
          style={{ width: '100%' }}
        />
        <Button
          label='Voltar'
          style={{ width: '100%', marginTop: 10 }}
          onClick={handleReturn}
        />
      </StyledButtonsWrapper>
    </StyledStepWrapper>
  );
};
