import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import { transparentize } from 'polished';
import { palette } from '../../theme';

export const StyledButton = styled(LoadingButton)<LoadingButtonProps>(() => ({
  margin: 8,
  // minWidth: 100,
  height: 35,
  color: 'background.main',
  '& .MuiLoadingButton-loadingIndicator': {
    color: transparentize(0.4, palette.background.main),
  },
  borderRadius: 40,
}));
