import InfoIcon from '@mui/icons-material/Info';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { find, includes, map, replace, split, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  ContentBox,
  Dropdown,
  MapsAutocomplete,
  PlaceType,
} from '../../components';
import { TICKET_PURCHASE } from '../../routes';
import {
  fetchNearby,
  ticketPurchaseSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleDateTime, handleMoney } from '../../utils';
import useScreenBreakpoint from '../../utils/useCurrentBreakpoint';
import {
  calculateDistances,
  getWorkingHoursToday,
  pricesByType,
  validateWorkingPeriods,
} from './auxFunctions';
import { BuyTicketModal } from './modals/BuyTicketModal';
import { EditCPFModal } from './modals/EditCPFModal';

export const TicketPurchase: React.FC = () => {
  const dispatch = useDispatch();
  // const ref = useRef<TextFieldProps>(null);
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const { paymentMethods } = useSelector(
    (state) => state.paymentMethodsReducer,
  );
  const {
    ticketPurchaseLoading,
    nearbyRoutes,
    discount,
    deactivateDiscount,
    isRenewTicket,
    navigatingToRenewTicket,
    openTicketData,
    hasPendingTicket,
    ticketData,
  } = useSelector((state) => state.ticketPurchaseReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);
  const [selectedVehicle, setSelectedVehicle] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(
    null,
  );
  const [selectedRoute, setSelectedRoute] = useState<string>('');
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [periodsList, setPeriodsList] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');
  const [ticketValue, setTicketValue] = useState<string | null>(null);
  const [newRouteOnRenovation, setNewRouteOnRenovation] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    vehicle: null,
    coords: null,
    period: null,
    route: null,
    paymentMethod: null,
  });
  const [hasDiscount, setHasDiscount] = useState(false);
  const [breakpoint, setBreakpoint] = useState('');
  const screenBreakpoint = useScreenBreakpoint();

  useEffect(() => {
    if (location.pathname === TICKET_PURCHASE && !navigatingToRenewTicket) {
      dispatch(ticketPurchaseSlice.actions.setRenewTicket(false));
    } else {
      dispatch(ticketPurchaseSlice.actions.setNavigatingRenewTicket(false));
    }
  }, [location.pathname]);

  useEffect(() => {
    setBreakpoint(screenBreakpoint);
  }, [screenBreakpoint]);

  useEffect(() => {
    if ((isRenewTicket || hasPendingTicket) && openTicketData) {
      const vehicle = find(vehicles, ['id', openTicketData.vehicleId]);
      const route = openTicketData.route;
      if (vehicle && route) {
        setFormData({
          vehicle: vehicle,
          coords: null,
          period: null,
          route: route,
          paymentMethod: null,
        });
        setSelectedVehicle(
          `${vehicle.plate} (${vehicle.type}) ${
            !deactivateDiscount && vehicle.used === 0 ? '- COM DESCONTO' : ''
          }`,
        );
        setSelectedRoute(route.name);
        setNewRouteOnRenovation(false);

        const isWorking = validateWorkingPeriods({
          route: route,
        });
        if (isWorking !== 1) {
          if (isWorking === 2) {
            const workingTimes = getWorkingHoursToday({
              route: route,
            });

            toast.warning(
              `O trecho não está em operação.${
                workingTimes
                  ? ` \nHorário de funcionamento: ${workingTimes.startTime} - ${workingTimes.endTime}`
                  : ``
              }`,
            );
          } else {
            toast.warning(
              'Trecho fora do dia de funcionamento. Qualquer dúvida entre em contato',
            );
          }
        }
      }
    }
  }, [isRenewTicket, hasPendingTicket]);

  useEffect(() => {
    if (formData.route && formData.vehicle) {
      const periods = pricesByType(
        formData.route,
        formData.vehicle,
        discount,
        deactivateDiscount,
      );
      setPeriodsList(periods);
    } else {
      setTicketValue(null);
      setPeriodsList([]);
    }
  }, [formData.route, formData.vehicle]);

  const handleSelectedVehicle = (e: string) => {
    setTicketValue(null);
    const vehicle = find(vehicles, [
      'plate',
      trim(split(e.toString(), '(')[0]),
    ]);
    setSelectedVehicle(e.toString());
    setSelectedPeriod('');
    setFormData({ ...formData, vehicle: vehicle, period: null });
    if (!deactivateDiscount && includes(e, 'DESCONTO')) setHasDiscount(true);
    else setHasDiscount(false);
  };

  const handleSelectedAddress = (lat: number, lng: number) => {
    setSelectedRoute('');
    setSelectedPeriod('');
    setFormData({
      ...formData,
      route: null,
      period: null,
      coords: { latitude: lat, longitude: lng },
    });
    fetchClosestPath(lat, lng);
  };

  const fetchClosestPath = (latitude: number, longitude: number) => {
    if (latitude && longitude) {
      dispatch(
        fetchNearby({
          driverId: driverId.toString(),
          latitude: latitude.toString(),
          longitude: longitude.toString(),
        }),
      );
    }
  };

  const handleSelectedRoute = (e: string) => {
    setTicketValue(null);
    setSelectedPeriod('');
    setSelectedRoute(e);
    const route = find(nearbyRoutes, ['name', e.toString()]);
    if (route) {
      const isWorking = validateWorkingPeriods({
        route: route,
      });
      if (isWorking === 1) {
        setFormData({ ...formData, route: route, period: null });
      } else if (isWorking === 2) {
        const workingTimes = getWorkingHoursToday({
          route: route,
        });

        toast.warning(
          `O trecho não está em operação.${
            workingTimes
              ? ` \nHorário de funcionamento: ${workingTimes.startTime} - ${workingTimes.endTime}`
              : ``
          }`,
        );
      } else {
        toast.warning(
          'Trecho fora do dia de funcionamento. Qualquer dúvida entre em contato',
        );
      }
    }
  };

  const handleSelectedPeriod = (e: string) => {
    const value = trim(split(e, '$')[1]);
    const period = find(periodsList, [
      'price',
      parseFloat(replace(trim(value), /,/g, '.')),
    ]);
    setSelectedPeriod(e);
    setFormData({ ...formData, period: period });
    setTicketValue(
      !deactivateDiscount && hasDiscount
        ? `${replace(
            parseFloat(period.paidPrice).toFixed(2).toString(),
            '.',
            ',',
          )}`
        : `${replace(
            parseFloat(period.price).toFixed(2).toString(),
            '.',
            ',',
          )}`,
    );
  };

  const handleSelectedPaymentMethod = (e: string) => {
    if (e !== 'PIX') {
      const paymentMethod = find(paymentMethods, [
        'number',
        trim(split(e.toString(), ' ')[1]),
      ]);
      setFormData({ ...formData, paymentMethod: paymentMethod });
    } else {
      setFormData({ ...formData, paymentMethod: 'PIX' });
    }
    setSelectedPaymentMethod(e.toString());
  };

  const handleBuyTicket = () => {
    dispatch(
      ticketPurchaseSlice.actions.setTicketData({
        ...formData,
        value: ticketValue,
      }),
    );
    if (formData.paymentMethod === 'PIX' && !cpf) {
      dispatch(ticketPurchaseSlice.actions.toggleEditCPFTicketIsOpen());
    } else {
      dispatch(ticketPurchaseSlice.actions.toggleBuyTicketIsOpen());
    }
  };

  const handleResetForm = () => {
    setTicketValue(null);
    !hasPendingTicket && setSelectedVehicle('');
    setSelectedRoute('');
    setSelectedPeriod('');
    setHasDiscount(false);
    dispatch(ticketPurchaseSlice.actions.resetNearby());
    setPeriodsList([]);
    setSelectedAddress(null);
    setSelectedPaymentMethod('');
    setFormData({
      vehicle: null,
      coords: null,
      period: null,
      route: null,
      paymentMethod: null,
    });
    dispatch(ticketPurchaseSlice.actions.setNavigatingRenewTicket(false));
    dispatch(ticketPurchaseSlice.actions.setRenewTicket(false));
  };

  const handlePeriodLabels = (item: {
    paidPrice: number;
    period: string;
    price: number;
    type: string;
  }) => {
    const hours = item.period[0] + item.period[1];
    const minutes = item.period[3] + item.period[4];
    const periodTime =
      hours !== '00' && minutes !== '00'
        ? `${hours} ${hours === '01' ? 'hr' : 'hrs'} e ${minutes} min`
        : hours === '00' && minutes !== '00'
        ? `${minutes} min`
        : `${hours} ${hours === '01' ? 'hr' : 'hrs'}`;
    return `${periodTime} - ${handleMoney(item.price)}`;
  };

  return (
    <ContentBox loading={ticketPurchaseLoading || pixLoading}>
      <Grid
        container
        sx={{
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        {hasPendingTicket && (
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: 1,
                textAlign: 'center',
              }}
              color={palette.primary.main}
            >
              Renovação de ticket para veículo {ticketData?.vehicle.plate}
            </Typography>
          </Grid>
        )}
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              textAlign: 'center',
            }}
            color={palette.text.main}
          >
            {hasPendingTicket
              ? 'Enquanto houver ticket ativo não é possível comprar um ticket para um novo veículo'
              : !isRenewTicket
              ? 'Insira abaixo os dados para realizar a compra do ticket'
              : 'Insira abaixo os dados para renovar o ticket'}
          </Typography>
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={map(vehicles, (item) => {
              return `${item.plate} (${item.type}) ${
                !deactivateDiscount && item.used === 0 ? '- COM DESCONTO' : ''
              }`;
            })}
            data-testid='vehicle'
            margin='normal'
            label='Veículo'
            type='text'
            disabled={isRenewTicket || hasPendingTicket}
            value={selectedVehicle}
            onChange={handleSelectedVehicle}
            onClear={() => {
              setSelectedVehicle('');
              setSelectedPeriod('');
              dispatch(ticketPurchaseSlice.actions.resetNearby());
              setPeriodsList([]);
              setFormData({
                ...formData,
                vehicle: null,
                period: null,
              });
            }}
          />
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <MapsAutocomplete
            value={selectedAddress}
            setValue={setSelectedAddress}
            onChange={(e) => {
              setNewRouteOnRenovation(true);
              if (e) handleSelectedAddress(e.lat, e.lng);
            }}
            onClear={() => {
              setSelectedRoute('');
              setSelectedPeriod('');
              dispatch(ticketPurchaseSlice.actions.resetNearby());
              setPeriodsList([]);
              setFormData({
                ...formData,
                coords: null,
                route: null,
                period: null,
              });
            }}
          />
          {(breakpoint === 'xl' || breakpoint === 'lg') && (
            <Tooltip title='Digite um endereço nesse campo para buscarmos os trechos e períodos para você'>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={map(nearbyRoutes, (item) => {
              // if (formData.coords) {
              //   const distance = calculateDistances(
              //     item,
              //     formData.coords.latitude,
              //     formData.coords.longitude,
              //   );
              //   return `${item.name} - distância: ${distance
              //     .toFixed(2)
              //     .toString()}km`;
              // }
              return `${item.name}`;
            })}
            data-testid='route'
            margin='normal'
            label='Trecho'
            type='text'
            disabled={!newRouteOnRenovation || !formData.coords}
            value={selectedRoute}
            onChange={handleSelectedRoute}
            onClear={() => {
              setSelectedRoute('');
              setSelectedPeriod('');
              setPeriodsList([]);
              setFormData({ ...formData, route: null, period: null });
            }}
          />
        </Grid>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={map(periodsList, (item) => handlePeriodLabels(item))}
            data-testid='period'
            margin='normal'
            label='Período'
            type='text'
            disabled={!formData.vehicle || !formData.route}
            value={selectedPeriod}
            onChange={handleSelectedPeriod}
            onClear={() => {
              setTicketValue(null);
              setFormData({ ...formData, period: null });
            }}
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Dropdown
            options={[
              'PIX',
              ...map(paymentMethods, (item) => {
                return `**** ${item.number} (${item.brand})`;
              }),
            ]}
            data-testid='paymentMethod'
            margin='normal'
            label='Meio de pagamento'
            type='text'
            value={selectedPaymentMethod}
            onChange={handleSelectedPaymentMethod}
          />
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: {
              sm: 0,
              xs: 3,
            },
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            color={palette.primary.main}
          >
            Valor:&nbsp;
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: 1,
              textTransform: 'uppercase',
            }}
            color={palette.primary.main}
          >
            {ticketValue
              ? ticketValue === '0,00'
                ? 'Grátis'
                : `R$${ticketValue}`
              : `R$ -`}
            {!deactivateDiscount && hasDiscount && (
              <Typography color={palette.success.main}>
                &nbsp;(com desconto)
              </Typography>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          margin: 'auto',
          paddingTop: '20px',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant='contained'
          label={isRenewTicket ? 'Renovar ticket' : 'Comprar ticket'}
          type='submit'
          disabled={
            !formData.vehicle ||
            !formData.route ||
            !formData.period ||
            !formData.paymentMethod
          }
          onClick={handleBuyTicket}
          color='secondary'
          loading={ticketPurchaseLoading || pixLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
        <Button
          label='Limpar'
          type='submit'
          onClick={() => {
            handleResetForm();
          }}
          color='primary'
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <BuyTicketModal handleResetForm={handleResetForm} />
      <EditCPFModal />
    </ContentBox>
  );
};
