import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import VerificationInput from 'react-verification-input';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  profileSlice,
  fetchEmailGetCode,
  fetchEmailValidateCode,
  fetchEditEmail,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleApiReturn, handleErrors } from '../../../utils';
import {
  StyledButtonsWrapper,
  StyledInputWrapper,
  StyledStepWrapper,
  StyledTextWrapper,
} from '../styles';
import { validationCodeSchema, validationEmailSchema } from '../validations';

export const EditEmailModal: React.FC = () => {
  const dispatch = useDispatch();
  const { editEmailIsOpen, emailEditStep, editLoading } = useSelector(
    (state) => state.profileReducer,
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState({
    email: '',
    code: '',
  });

  const handleClose = () => {
    setFormData({
      email: '',
      code: '',
    });
    dispatch(profileSlice.actions.setEmailEditStep(1));
    dispatch(profileSlice.actions.toggleEditEmailIsOpen());
  };

  const handleRequestCode = () => {
    setErrors({});
    validationEmailSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(fetchEmailGetCode({ email: formData.email })).then(
          (res: any) => {
            handleApiReturn(
              res,
              () => {
                toast.success('Código enviado para email com sucesso.');
                dispatch(profileSlice.actions.setEmailEditStep(2));
              },
              null,
            );
          },
        ),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleVerifyCode = () => {
    setErrors({});
    validationCodeSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        dispatch(
          fetchEmailValidateCode({
            email: formData.email || '',
            code: formData.code,
          }),
        ).then((res: any) => {
          handleApiReturn(
            res,
            () => {
              handleUpdateEmail();
            },
            null,
          );
        });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleUpdateEmail = () => {
    dispatch(
      fetchEditEmail({
        driverId: localStorage.getItem('NITROT:driverId') || '',
        email: formData.email,
        validationCode: formData.code,
      }),
    ).then((res: any) => {
      handleApiReturn(
        res,
        () => {
          toast.success('Email atualizado com sucesso.');
          handleClose();
        },
        null,
      );
    });
  };

  return (
    <Modal open={editEmailIsOpen} onClose={handleClose} title='Edite seu email'>
      {emailEditStep === 1 ? (
        <StyledStepWrapper>
          <StyledTextWrapper>
            <Typography
              color='primary'
              textAlign='center'
              variant='h6'
              gutterBottom
            >
              Olá, motorista!
            </Typography>
            <Typography
              color={palette.text.main}
              textAlign='center'
              gutterBottom
            >
              Digite seu email para enviarmos um email com um código de
              validação
            </Typography>
          </StyledTextWrapper>
          <StyledInputWrapper>
            <Input
              name='email'
              type='text'
              mask='email'
              label='Email'
              margin='normal'
              value={formData.email}
              onChange={(value: string) =>
                setFormData({ ...formData, email: value })
              }
              maxLength={15}
              errorMessage={errors.email}
              style={{ width: '100%' }}
            />
          </StyledInputWrapper>
          <StyledButtonsWrapper>
            <Button
              variant='contained'
              label='Validar email'
              type='submit'
              onClick={handleRequestCode}
              color='secondary'
              loading={editLoading}
              style={{ width: '100%' }}
            />
          </StyledButtonsWrapper>
        </StyledStepWrapper>
      ) : (
        <StyledStepWrapper>
          <StyledTextWrapper>
            <Typography
              color='primary'
              textAlign='center'
              variant='h6'
              gutterBottom
            >
              Código enviado com sucesso!
            </Typography>
            <Typography
              color={palette.text.main}
              textAlign='center'
              gutterBottom
            >
              Por favor, digite o código de 6 dígitos que enviamos para o seu
              email
            </Typography>
          </StyledTextWrapper>
          <StyledInputWrapper>
            <VerificationInput
              validChars='0-9'
              inputProps={{ inputMode: 'numeric' }}
              onChange={(e) => setFormData({ ...formData, code: e })}
            />
            <Typography
              color={palette.error.main}
              textAlign='center'
              gutterBottom
              marginTop={1}
              height={10}
            >
              {errors.code}
            </Typography>
          </StyledInputWrapper>
          <StyledButtonsWrapper>
            <Button
              variant='contained'
              label='Enviar'
              type='submit'
              onClick={handleVerifyCode}
              color='secondary'
              loading={editLoading}
              style={{ width: '100%' }}
            />
          </StyledButtonsWrapper>
        </StyledStepWrapper>
      )}
    </Modal>
  );
};
