import { Grid, Typography } from '@mui/material';
import { replace } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { Button, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  ticketPurchaseSlice,
  fetchBuyTicket,
  fetchOpenTicket,
  fetchBuyTicketWithPix,
  pixSlice,
  vehiclesSlice,
} from '../../../stores';
import { StyledModalWrapper } from '../styles';
import 'react-credit-cards/es/styles-compiled.css';
import { PIX } from '../../../routes';

export const BuyTicketModal = (props: { handleResetForm: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { driverId, cpf } = useSelector((state) => state.profileReducer);
  const {
    ticketData,
    buyTicketIsOpen,
    ticketPurchaseLoading,
    isRenewTicket,
    hasPendingTicket,
    openTicketData,
  } = useSelector((state) => state.ticketPurchaseReducer);
  const { pixLoading } = useSelector((state) => state.pixReducer);

  const handleClose = () => {
    dispatch(ticketPurchaseSlice.actions.toggleBuyTicketIsOpen());
  };

  const handleSubmit = () => {
    if (driverId && ticketData?.vehicle) {
      const params = {
        driverId: driverId.toString(),
        vehicleId: ticketData?.vehicle?.id.toString(),
        stayTime: ticketData?.period.period,
        price: ticketData?.period ? ticketData?.period.price.toString() : '0.0',
        paidPrice: ticketData?.period
          ? ticketData?.period.paidPrice.toString()
          : '0.0',
        routeId: ticketData?.route.id.toString(),
        paymentMethodId:
          typeof ticketData?.paymentMethod === 'string'
            ? '0'
            : ticketData?.paymentMethod.id.toString(),
        openTicket:
          (isRenewTicket || hasPendingTicket) && openTicketData
            ? openTicketData.exitDateTime
            : null,
        serverDateTime:
          (isRenewTicket || hasPendingTicket) && openTicketData
            ? openTicketData.serverDateTime
            : null,
      };

      if (ticketData?.paymentMethod === 'PIX') {
        dispatch(
          fetchBuyTicketWithPix({
            ...params,
            driverDocument: VMasker.toNumber(cpf).toString(),
          }),
        ).then((res: any) => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (ticketData.value === '0,00') {
              dispatch(fetchOpenTicket({ driverId: driverId.toString() }));
              dispatch(ticketPurchaseSlice.actions.toggleBuyTicketIsOpen());
              props.handleResetForm();
              dispatch(
                vehiclesSlice.actions.setUsedVehicle(ticketData?.vehicle?.id),
              );
              toast.success('Ticket comprado com sucesso');
            } else {
              dispatch(ticketPurchaseSlice.actions.toggleBuyTicketIsOpen());
              dispatch(pixSlice.actions.setHasOpenPix(true));
              props.handleResetForm();
              toast.success('Pix gerado com sucesso.');
              navigate(PIX);
            }
          }
        });
      } else {
        dispatch(fetchBuyTicket(params)).then((res: any) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(fetchOpenTicket({ driverId: driverId.toString() }));
            dispatch(ticketPurchaseSlice.actions.toggleBuyTicketIsOpen());
            dispatch(
              vehiclesSlice.actions.setUsedVehicle(ticketData?.vehicle?.id),
            );
            props.handleResetForm();
            toast.success('Ticket comprado com sucesso');
          }
        });
      }
    }
  };

  return (
    <Modal
      open={buyTicketIsOpen}
      onClose={handleClose}
      title='Confirmação de compra'
    >
      <StyledModalWrapper>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Veículo:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {`${ticketData?.vehicle.plate} (${ticketData?.vehicle.type})`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Valor:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {ticketData
                ? ticketData.value === '0,00'
                  ? 'Grátis'
                  : `R$${replace(ticketData.value.toString(), '.', ',')}`
                : 'R$0,00'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color='text'
              textAlign='center'
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Meio de pagamento:
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 1 }}>
            <Typography color='text' textAlign='center' gutterBottom>
              {ticketData?.paymentMethod &&
              typeof ticketData?.paymentMethod !== 'string'
                ? `**** ${ticketData?.paymentMethod.number} (${ticketData?.paymentMethod.brand})`
                : 'PIX'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Button
            variant='contained'
            label='Comprar'
            type='submit'
            onClick={handleSubmit}
            color='secondary'
            loading={ticketPurchaseLoading || pixLoading}
            style={{ width: '100%' }}
          />
        </Grid>
      </StyledModalWrapper>
    </Modal>
  );
};
