import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IRREGULARITIES } from '../../routes';
import {
  fetchGetIrregularityById,
  irregularitiesSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { handleApiReturn, handleDateTime } from '../../utils';

export const IrregularityCard = (props: {
  item:
    | {
        brand: string;
        id: number;
        observation: string | null;
        plate: string;
        plateImg: string;
        registerDateTime: string;
        type: string;
      }
    | undefined;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);

  const handleIrregularityClick = (irregularityId: string) => {
    dispatch(
      fetchGetIrregularityById({
        driverId: driverId.toString(),
        irregularityId: irregularityId,
      }),
    ).then((res: any) => {
      handleApiReturn(
        res,
        () => {
          dispatch(irregularitiesSlice.actions.toggleShowDetails());
          navigate(IRREGULARITIES);
        },
        null,
      );
    });
  };

  return (
    <Card
      sx={{
        cursor: 'pointer',
        height: {
          xs: 275,
          sm: 200,
        },
        width: {
          xs: 205,
          sm: 400,
        },
        borderRadius: '14px',
        overflow: 'visible',
        margin: '10px',
      }}
      onClick={() =>
        handleIrregularityClick(props.item ? props.item.id.toString() : '')
      }
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          height: '100%',
          width: {
            xs: 205,
            sm: 400,
          },
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <CardContent
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '50%',
          }}
        >
          <Typography variant='h6' color='primary'>
            {props.item?.id}
          </Typography>
          <Typography
            gutterBottom
            color='text.secondary'
            sx={{
              fontSize: '16px',
            }}
          >
            {handleDateTime(props.item?.registerDateTime)}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '16px',
            }}
          >
            Placa: {props.item?.plate}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '16px',
            }}
          >
            Categoria: {props.item?.type}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '16px',
            }}
          >
            Modelo: {props.item?.brand}
          </Typography>
        </CardContent>
        <CardMedia
          sx={{
            height: '100%',
            width: '100%',
            borderTopRightRadius: '14px',
            borderBottomRightRadius: {
              sm: '14px',
              xs: 0,
            },
            borderTopLeftRadius: {
              sm: 0,
              xs: '14px',
            },
          }}
          image={props.item?.plateImg}
        />
        <CardContent
          sx={{
            display: {
              xs: 'flex',
              sm: 'none',
            },
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <Typography variant='h6' color='primary'>
            {props.item?.id}
          </Typography>
          <Typography
            gutterBottom
            color='text.secondary'
            sx={{
              fontSize: '14px',
            }}
          >
            {handleDateTime(props.item?.registerDateTime)}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '14px',
            }}
          >
            Placa: {props.item?.plate}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '14px',
            }}
          >
            Categoria: {props.item?.type}
          </Typography>
          <Typography
            color='text.secondary'
            sx={{
              fontSize: '14px',
            }}
          >
            Modelo: {props.item?.brand}
          </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
};
