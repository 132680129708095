import { styled, Button, Grid, IconButton } from '@mui/material';
import { palette } from '../../theme';

export const ButtonsGrid = styled(Grid)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const PaymentMethodButton = styled(Button)({
  display: 'flex',
  flex: 1,
  flexGrow: 1,
  backgroundColor: 'white',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 20px',
  borderRadius: '30px',
  marginRight: '5px',
});

export const DeleteButton = styled(IconButton)({
  display: 'flex',
  flex: 1,
  flexGrow: 0,
  backgroundColor: palette.grey[700],
});

export const StyledModalWrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledInputWrapper = styled('div')({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledButtonsWrapper = styled('div')({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
});
