/* eslint-disable pii/no-phone-number */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
// import FacebookLogin, {
//   ReactFacebookFailureResponse,
//   ReactFacebookLoginInfo,
// } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectPackage from '../../../package.json';
import { Input, Button } from '../../components';
import { DASHBOARD, SIGNUP } from '../../routes';
import { LoginResponse, LoginWithFBResponse } from '../../services';
import {
  useDispatch,
  fetchLogin,
  useSelector,
  fetchLoginWithFB,
  profileSlice,
  vehiclesSlice,
  paymentMethodsSlice,
  ticketPurchaseSlice,
  fetchPasswordRecovery,
  fetchComplianceForSignup,
} from '../../stores';
import { palette, logo } from '../../theme';
import { handleApiReturn, handleErrors } from '../../utils';
import {
  StyledWrapper,
  StyledBodyContainer,
  StyledBodyBox,
  StyledLogo,
  StyledLogoWrapper,
  StyledButtonsBox,
  StyledFormGroup,
} from './styles';
import { validationSchema, validationRecoverySchema } from './validations';

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authLoading } = useSelector((state) => state.authReducer);
  const { driverId } = useSelector((state) => state.profileReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isRecovering, setIsRecovering] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (
      localStorage.getItem('NITROT:driverId') !== null &&
      localStorage.getItem('NITROT:token') !== null
    ) {
      navigate(DASHBOARD);
    } else {
      dispatch(fetchComplianceForSignup());
    }
  }, []);

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchLogin({
            email: formData.email,
            password: formData.password,
          }),
        ).then((res: { payload: LoginResponse }) => {
          handleApiReturn(
            res,
            () => {
              dispatch(
                profileSlice.actions.setUser({
                  driverId: res.payload.data.id,
                  name: res.payload.data.name,
                  cellphone: res.payload.data.cellphone,
                  email: res.payload.data.email,
                  birth: res.payload.data.birth,
                  firstTicket: res.payload.data.firstTicket,
                  picture: res.payload.data.picture || '',
                  termsOfUseDateTime: res.payload.data.termsOfUseDateTime,
                  passwordUpdateDateTime:
                    res.payload.data.passwordUpdateDateTime,
                }),
              );
              dispatch(
                vehiclesSlice.actions.setVehicles(
                  orderBy(res.payload.data.vehicles, ['default'], ['desc']),
                ),
              );
              dispatch(
                paymentMethodsSlice.actions.setPaymentMethods(
                  res.payload.data.paymentMethods,
                ),
              );
              dispatch(
                ticketPurchaseSlice.actions.setDiscount(
                  res.payload.data.discount,
                ),
              );
              localStorage.setItem('NITROT:token', res.payload.data.token);
              localStorage.setItem(
                'NITROT:driverId',
                res.payload.data.id.toString(),
              );
              window.dispatchEvent(new Event('storage'));
              toast.success('Bem vindo!');
              navigate(DASHBOARD);
            },
            () => {},
          );
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReset = () => {
    setFormData({
      email: '',
      password: '',
    });
  };

  const handleSignup = () => {
    navigate(SIGNUP);
  };

  const handleFacebookLogin = (
    // response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
    response: any,
  ) => {
    if (response.id) {
      dispatch(
        fetchLoginWithFB({
          facebookId: response.id,
        }),
      )
        .then((res: { payload: LoginWithFBResponse }) => {
          if (res.payload) {
            handleApiReturn(
              res,
              () => {
                if (res.payload.data.id) {
                  dispatch(
                    profileSlice.actions.setUser({
                      driverId: res.payload.data.id,
                      name: res.payload.data.name,
                      cellphone: res.payload.data.cellphone,
                      email: res.payload.data.email,
                      birth: res.payload.data.birth,
                      firstTicket: res.payload.data.firstTicket,
                      picture: res.payload.data.picture || '',
                      termsOfUseDateTime: res.payload.data.termsOfUseDateTime,
                      passwordUpdateDateTime:
                        res.payload.data.passwordUpdateDateTime,
                    }),
                  );
                  dispatch(
                    vehiclesSlice.actions.setVehicles(
                      res.payload.data.vehicles,
                    ),
                  );
                  localStorage.setItem('NITROT:token', res.payload.data.token);
                  localStorage.setItem(
                    'NITROT:driverId',
                    res.payload.data.id.toString(),
                  );
                  window.dispatchEvent(new Event('storage'));
                  toast.success('Bem vindo!');
                  navigate(DASHBOARD);
                } else {
                  navigate(SIGNUP, {
                    state: {
                      facebookId: response.id,
                      name: response.name,
                      email: response.email,
                    },
                  });
                }
              },
              () => {
                console.log('Error no login com facebook');
              },
            );
          }
        })
        .catch(() => {
          console.log('Error no login com facebook');
        });
    }
  };

  const handleForgotPassword = () => {
    setFormData({
      email: '',
      password: '',
    });
    setErrors({});
    setIsRecovering(!isRecovering);
  };

  const handlePasswordRecovery = () => {
    setErrors({});
    validationRecoverySchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchPasswordRecovery({
            email: formData.email,
          }),
        ).then((res: { payload: LoginResponse }) => {
          handleApiReturn(
            res,
            () => {
              setIsRecovering(false);
              setFormData({
                email: '',
                password: '',
              });
              toast.success('Email enviado com sucesso!');
            },
            null,
          );
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <StyledWrapper
      sx={{
        overflow: 'auto',
        paddingTop: {
          xs: 0,
          md: '60px',
        },
        paddingBottom: {
          xs: 0,
          md: '60px',
        },
      }}
    >
      <Box
        boxShadow={20}
        sx={{
          width: {
            xs: '100%',
            md: 'auto',
          },
          // height: {
          //   xs: '100%',
          //   md: '720px',
          // },
          // minHeight: {
          //   xs: '100%',
          //   md: '575px',
          // },
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: {
            xs: 0,
            md: '60px',
          },
          borderRadius: {
            xs: 0,
            md: '14px',
          },
          // overflow: 'auto',
        }}
      >
        <StyledLogoWrapper>
          <StyledLogo src={logo} alt='logo' />
        </StyledLogoWrapper>
        <StyledBodyContainer>
          {!isRecovering ? (
            <StyledBodyBox>
              <StyledFormGroup>
                {/* <Grid
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography color='primary' textAlign='center' variant='h4'>
                    Bem-vindo à
                  </Typography>
                  <Typography
                    color={palette.text.main}
                    textAlign='center'
                    sx={{
                      fontSize: '24px',
                    }}
                    gutterBottom
                  >
                    Niterói Rotativo
                  </Typography>
                </Grid> */}
                <Input
                  data-testid='email'
                  type='text'
                  margin='normal'
                  label='Email'
                  value={formData.email}
                  onChange={(value) =>
                    setFormData({ ...formData, email: value })
                  }
                  errorMessage={errors.email}
                />
                <Input
                  data-testid='password'
                  type={showPassword ? 'text' : 'password'}
                  label='Senha'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formData.password}
                  onChange={(InputProps) =>
                    setFormData({ ...formData, password: InputProps })
                  }
                  errorMessage={errors && errors.password}
                />
                <StyledButtonsBox>
                  <Button
                    data-testid='submit'
                    variant='contained'
                    label='Entrar'
                    loading={authLoading}
                    style={{ width: '100%' }}
                    onClick={handleSubmit}
                    color='secondary'
                  />
                  <Button
                    data-testid='clean'
                    variant='outlined'
                    label='Limpar'
                    style={{ width: '100%' }}
                    onClick={handleReset}
                  />
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_FB_ID}`}
                    autoLoad={false}
                    fields='name,email'
                    // scope='public_profile,email'
                    // onClick={componentClicked}
                    callback={handleFacebookLogin}
                    render={(renderProps: {
                      onClick: (values: any) => void;
                    }) => (
                      <Button
                        label='Entrar com o Facebook'
                        startIcon={<FacebookIcon />}
                        style={{
                          width: '100%',
                          backgroundColor: '#4267B2',
                          color: 'white',
                          marginTop: 30,
                          marginBottom: 20,
                        }}
                        onClick={renderProps.onClick}
                      />
                    )}
                  />
                  <Button
                    label='Não tenho cadastro'
                    style={{ width: '100%', marginBottom: 0 }}
                    onClick={handleSignup}
                  />
                  <Button
                    label='Esqueci minha senha'
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={handleForgotPassword}
                  />
                </StyledButtonsBox>
              </StyledFormGroup>
            </StyledBodyBox>
          ) : (
            <StyledBodyBox>
              <StyledFormGroup>
                <Grid
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color='primary'
                    textAlign='center'
                    variant='h4'
                    gutterBottom
                  >
                    Esqueceu sua senha?
                  </Typography>
                  <Typography
                    color={palette.text.main}
                    textAlign='center'
                    gutterBottom
                  >
                    Digite seu e-mail para enviarmos uma nova senha para você
                  </Typography>
                </Grid>
                <Input
                  data-testid='email'
                  type='text'
                  margin='normal'
                  label='Email'
                  value={formData.email}
                  onChange={(value) =>
                    setFormData({ ...formData, email: value })
                  }
                  errorMessage={errors.email}
                />
                <StyledButtonsBox>
                  <Button
                    data-testid='submit'
                    variant='contained'
                    label='Recuperar senha'
                    loading={authLoading}
                    style={{ width: '100%' }}
                    onClick={handlePasswordRecovery}
                    color='secondary'
                  />
                  <Button
                    data-testid='clean'
                    variant='outlined'
                    label='Limpar'
                    style={{ width: '100%' }}
                    onClick={handleReset}
                  />
                  <Button
                    label='Voltar'
                    style={{ width: '100%', marginTop: 5 }}
                    onClick={handleForgotPassword}
                  />
                </StyledButtonsBox>
              </StyledFormGroup>
            </StyledBodyBox>
          )}
        </StyledBodyContainer>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <Typography
            color={palette.text.main}
            fontSize='12px'
            marginBottom='10px'
          >
            Versão {projectPackage.version}
          </Typography>
        </Grid>
      </Box>
      {/* <Grid
        xs={12}
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
          justifyContent: 'center',
        }}
      >
        <Typography padding={5} color={palette.primary.contrastText}>
          Versão {projectPackage.version}
        </Typography>
      </Grid> */}
    </StyledWrapper>
  );
};
