import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HISTORY } from '../../routes';
import {
  fetchGetTicketDetails,
  historySlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleMoney } from '../../utils';

export const TicketCard = (props: {
  item:
    | {
        id: string;
        plate: string;
        type: string;
        registerDateTime: string;
        entryDateTime: string;
        exitDateTime: string;
        situation: string;
        paymentType: string;
        price: number;
        paidPrice: number;
        routeId: number;
        routeName: string;
        routeImg: string;
        stayTime: string;
      }
    | undefined;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);

  const handleTicketClick = (ticketId: string) => {
    dispatch(
      fetchGetTicketDetails({
        driverId: driverId.toString(),
        ticketId: ticketId,
      }),
    );
    dispatch(historySlice.actions.toggleTicketDetailsIsOpen());
    navigate(HISTORY);
  };

  return (
    <Card
      sx={{
        cursor: 'pointer',
        width: {
          xs: 205,
          sm: 400,
        },
        minWidth: {
          xs: 205,
          sm: 400,
        },
        maxWidth: 400,
        borderRadius: '14px',
        overflow: 'visible',
        margin: '10px',
      }}
      onClick={() => handleTicketClick(props.item ? props.item.id : '')}
    >
      <Grid
        item
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}
      >
        <Grid spacing={1}>
          <CardMedia
            component='img'
            height='120'
            image={`data:image/png;base64,${props.item?.routeImg}`}
            sx={{
              borderTopRightRadius: '14px',
              borderTopLeftRadius: '14px',
            }}
          />
          <Typography
            gutterBottom
            color='primary'
            component='div'
            sx={{
              fontSize: '1.1em',
              marginTop: 1,
              marginLeft: 2,
              marginRight: 1,
            }}
            noWrap
          >
            {props.item?.routeName}
          </Typography>
        </Grid>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <Typography
                sx={{
                  fontSize: {
                    xs: '0.75em',
                    sm: '0.75em',
                  },
                }}
                noWrap
              >
                {moment(props.item?.entryDateTime).format(
                  'DD/MM/yyyy HH:mm:ss',
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'flex-end',
                },
                alignItems: 'center',
              }}
            >
              <Typography
                color={palette.success.main}
                sx={{
                  fontSize: {
                    xs: '0.75em',
                    sm: '0.75em',
                  },
                }}
                noWrap
              >
                {handleMoney(props.item?.price)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: {
                    xs: '0.75em',
                    sm: '0.75em',
                  },
                  width: '40px',
                  textOverflow: 'ellipsis',
                }}
                noWrap
              >
                Placa: {props.item?.plate}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'flex-end',
                },
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: '0.75em',
                    sm: '0.75em',
                  },
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                }}
                noWrap
              >
                Período: {props.item?.stayTime}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
};
