import { CardContent, CardMedia, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Button, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  historySlice,
  fetchSendReceipt,
} from '../../../stores';
import { handleMoney } from '../../../utils';
import { StyledButtonsWrapper, StyledModalWrapper } from '../styles';
import 'react-credit-cards/es/styles-compiled.css';

export const TicketDetaisModal: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId, email, name } = useSelector(
    (state) => state.profileReducer,
  );
  const {
    ticketDetails,
    ticketDetailsIsOpen,
    ticketDetailsIsLoading,
    sendTicketByEmailLoading,
  } = useSelector((state) => state.historyReducer);

  const handleClose = () => {
    dispatch(historySlice.actions.toggleTicketDetailsIsOpen());
  };

  const handleSendTicketByEmail = () => {
    if (ticketDetails) {
      dispatch(
        fetchSendReceipt({
          driverId: driverId.toString(),
          ticketId: ticketDetails.id,
          email: email,
          name: name,
        }),
      );
    }
  };

  return (
    <Modal
      open={ticketDetailsIsOpen}
      onClose={handleClose}
      title='Detalhes de ticket'
      isLoading={ticketDetailsIsLoading}
    >
      <StyledModalWrapper>
        <Grid
          item
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
          }}
        >
          <Grid spacing={1}>
            <CardMedia
              component='img'
              height='220'
              image={`data:image/png;base64,${ticketDetails?.routeImg}`}
            />
          </Grid>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='primary'
                  component='div'
                  sx={{
                    fontSize: '1.2em',
                  }}
                >
                  {ticketDetails?.routeName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Horário do registro:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {moment(ticketDetails?.registerDateTime).format(
                    'DD/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Horário inicial:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {moment(ticketDetails?.entryDateTime).format(
                    'DD/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Horário final:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {moment(ticketDetails?.exitDateTime).format(
                    'DD/MM/yyyy HH:mm:ss',
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Placa:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {ticketDetails?.plate}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Período:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {ticketDetails?.stayTime}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                    fontWeight: 'bold',
                  }}
                  noWrap
                >
                  Valor:&nbsp;
                </Typography>
                <Typography
                  color='primary'
                  sx={{
                    fontSize: '0.75em',
                  }}
                  noWrap
                >
                  {handleMoney(ticketDetails?.price)} (
                  <Typography
                    color='primary'
                    sx={{
                      fontSize: '0.75em',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                    noWrap
                  >
                    {ticketDetails?.paymentType}
                  </Typography>
                  )
                </Typography>
              </Grid>
            </Grid>
            {ticketDetails?.paymentType !== 'PIX' && (
              <Grid container sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <Typography
                    color='primary'
                    sx={{
                      fontSize: '0.75em',
                      fontWeight: 'bold',
                    }}
                    noWrap
                  >
                    Pago pelo aplicativo:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    color='primary'
                    sx={{
                      fontSize: '0.75em',
                      fontWeight: 'bold',
                    }}
                    noWrap
                  >
                    Cartão:&nbsp;
                  </Typography>
                  <Typography
                    color='primary'
                    sx={{
                      fontSize: '0.75em',
                    }}
                    noWrap
                  >
                    **** {ticketDetails?.cardNumber} (
                    <Typography
                      color='primary'
                      sx={{
                        fontSize: '0.75em',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                      }}
                      noWrap
                    >
                      {ticketDetails?.cardBrand}
                    </Typography>
                    )
                  </Typography>
                </Grid>
              </Grid>
            )}
            {ticketDetails?.irregularities && ticketDetails?.irregularities && (
              <Grid container sx={{ marginTop: 2 }}>
                {ticketDetails?.irregularities && (
                  <Grid item xs={12}>
                    <Typography
                      color='primary'
                      sx={{
                        fontSize: '0.75em',
                        fontWeight: 'bold',
                      }}
                      noWrap
                    >
                      Irregularidades:&nbsp;
                    </Typography>
                    <Typography
                      color='primary'
                      sx={{
                        fontSize: '0.75em',
                      }}
                      noWrap
                    >
                      {ticketDetails?.irregularities.join(', ')}
                    </Typography>
                  </Grid>
                )}
                {ticketDetails?.observation && (
                  <Grid item xs={12}>
                    <Typography
                      color='primary'
                      sx={{
                        fontSize: '0.75em',
                        fontWeight: 'bold',
                      }}
                      noWrap
                    >
                      Observação:&nbsp;
                    </Typography>
                    <Typography
                      color='primary'
                      sx={{
                        fontSize: '0.75em',
                      }}
                      noWrap
                    >
                      {ticketDetails?.observation}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </CardContent>
        </Grid>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Enviar por email'
            type='submit'
            onClick={handleSendTicketByEmail}
            color='secondary'
            loading={sendTicketByEmailLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledModalWrapper>
    </Modal>
  );
};
