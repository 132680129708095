import { useState, useEffect } from 'react';
import { theme } from '../theme';
import useWindowDimensions from './useWidowDimensions';

const getCurrentScreenBreakpoint = (browserWidth: number) => {
  if (browserWidth >= theme.breakpoints.values.xl) return 'xl';
  else if (browserWidth >= theme.breakpoints.values.lg) return 'lg';
  else if (browserWidth >= theme.breakpoints.values.md) return 'md';
  else if (browserWidth >= theme.breakpoints.values.sm) return 'sm';
  else return 'xs';
};

export default function useCurrentBreakpoint() {
  const windowDimensions = useWindowDimensions();
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentScreenBreakpoint(windowDimensions.browserWidth),
  );

  useEffect(() => {
    setCurrentBreakpoint(
      getCurrentScreenBreakpoint(windowDimensions.browserWidth),
    );
  }, [windowDimensions]);

  return currentBreakpoint;
}
