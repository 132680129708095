// All user related database operations can be defined here.
import { api } from '..';
import {
  EditProfileResponse,
  EditEmailResponse,
  EditPhoneResponse,
  ChangePasswordResponse,
} from './types';

export * from './types';

export const fetchEditProfileService = async (params: {
  driverId: string;
  name: string;
  birth: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .put<EditProfileResponse>(
      `drivers/${driverId}/update`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchEditEmailService = async (params: {
  driverId: string;
  email: string;
  validationCode: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .put<EditEmailResponse>(
      `drivers/${driverId}/email`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchEditPhoneService = async (params: {
  driverId: string;
  cellphone: string;
  validationCode: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .put<EditPhoneResponse>(
      `drivers/${driverId}/cellphone`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchChangePasswordService = async (params: {
  driverId: string;
  oldPassword: string;
  newPassword: string;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .put<ChangePasswordResponse>(
      `drivers/${driverId}/changepassword`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
