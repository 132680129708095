import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { Grid, Typography, Box } from '@mui/material';
import { find, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ContentBox, Modal } from '../../components';
import { VEHICLES } from '../../routes';
import {
  fetchDeleteVehicle,
  fetchGetVehicleList,
  fetchSetDefaultVehicle,
  useDispatch,
  useSelector,
  vehiclesSlice,
} from '../../stores';
import { palette } from '../../theme';
import { handleApiReturn } from '../../utils';
import { CreateVehicleModal } from './modals/CreateVehicleModal';
import {
  ButtonsGrid,
  DeleteButton,
  StyledModalWrapper,
  VehicleButton,
} from './styles';

export const Vehicles: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { vehicles, vehiclesLoading, deleteVehicleIsOpen } = useSelector(
    (state) => state.vehiclesReducer,
  );
  const { deactivateDiscount } = useSelector(
    (state) => state.ticketPurchaseReducer,
  );
  const [vehicleOnFocus, setVehicleOnFocus] = useState<{
    id: number;
    plate: string;
    type: string;
    model: string;
    default: number;
    used: number;
  } | null>(null);

  const selectType = (type: string) => {
    switch (type) {
      case 'Carro':
        return <DirectionsCarIcon />;
      case 'Moto':
        return <TwoWheelerIcon />;
      case 'Ônibus':
        return <DirectionsBusIcon />;
      default:
        return <LocalShippingIcon />;
    }
  };

  const handleStandardizeVehicle = (vehicleId: number) => {
    dispatch(
      fetchSetDefaultVehicle({
        driverId: localStorage.getItem('NITROT:driverId') || '',
        vehicleId: vehicleId.toString(),
      }),
    ).then((res: any) => {
      handleApiReturn(
        res,
        () => {
          toast.success('Veículo padronizado com sucesso.');
          dispatch(
            fetchGetVehicleList({
              driverId: localStorage.getItem('NITROT:driverId') || '',
            }),
          );
        },
        null,
      );
    });
  };

  const handleDeleteVehicle = () => {
    if (vehicleOnFocus) {
      // Check if item to be deleted is default, and change default to another if yes
      if (vehicleOnFocus.default && vehicles.length > 1) {
        const item = find(vehicles, (x) => {
          return x.id !== vehicleOnFocus.id && x.default === 0;
        });

        if (item) {
          dispatch(
            fetchSetDefaultVehicle({
              driverId: localStorage.getItem('NITROT:driverId') || '',
              vehicleId: item.id.toString(),
            }),
          );
        }
      }

      dispatch(
        fetchDeleteVehicle({
          driverId: localStorage.getItem('NITROT:driverId') || '',
          vehicleId: vehicleOnFocus.id.toString(),
        }),
      ).then((res: any) => {
        handleApiReturn(
          res,
          () => {
            dispatch(
              fetchGetVehicleList({
                driverId: localStorage.getItem('NITROT:driverId') || '',
              }),
            );
            dispatch(vehiclesSlice.actions.toggleDeleteVehicleIsOpen());
            setVehicleOnFocus(null);
            toast.success('Veículo excluído com sucesso');
          },
          null,
        );
      });
    }
  };

  useEffect(() => {
    if (location.pathname === VEHICLES) {
      dispatch(
        fetchGetVehicleList({
          driverId: driverId.toString(),
        }),
      );
    }
  }, [location.pathname]);

  return (
    <ContentBox loading={vehiclesLoading}>
      {vehicles.length > 0 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: {
              xs: '20px',
              sm: 0,
            },
          }}
        >
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 0,
            }}
          >
            {map(vehicles, (item) => {
              return (
                <ButtonsGrid item xs={12}>
                  <VehicleButton
                    variant='outlined'
                    color={
                      !deactivateDiscount && item.used === 0
                        ? 'success'
                        : 'primary'
                    }
                    startIcon={selectType(item.type)}
                    endIcon={item.default === 1 && <CheckCircleOutlineIcon />}
                    onClick={() => handleStandardizeVehicle(item.id)}
                  >
                    <Typography
                      sx={{
                        marginLeft: {
                          xs: 0,
                          md: 'auto',
                        },
                        marginRight: 'auto',
                        paddingLeft: {
                          xs: 0,
                          md:
                            !deactivateDiscount && item.used === 0
                              ? '125px'
                              : 0,
                        },
                        paddingRight: item.default === 0 ? '25px' : 0,
                        fontSize: {
                          xs: '12px',
                          sm: '14px',
                        },
                      }}
                      noWrap
                    >
                      {item.plate}{' '}
                      {!deactivateDiscount && item.used === 0 && (
                        <Typography
                          variant='caption'
                          ml={1}
                          color={palette.success.main}
                          sx={{
                            fontSize: {
                              xs: '8px',
                              sm: '14px',
                            },
                          }}
                          noWrap
                        >
                          (COM DESCONTO)
                        </Typography>
                      )}
                    </Typography>
                  </VehicleButton>
                  <DeleteButton
                    aria-label='delete'
                    size='medium'
                    onClick={() => {
                      setVehicleOnFocus(item);
                      dispatch(
                        vehiclesSlice.actions.toggleDeleteVehicleIsOpen(),
                      );
                    }}
                  >
                    <DeleteIcon fontSize='inherit' sx={{ color: 'white' }} />
                  </DeleteButton>
                </ButtonsGrid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Grid
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color={palette.text.main}
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                lg: '18px',
              },
            }}
          >
            Nenhum veículo cadastrado
          </Typography>
        </Grid>
      )}
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexGrow: 1,
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Button
          variant='contained'
          label='Cadastrar veículo'
          type='submit'
          onClick={() =>
            dispatch(vehiclesSlice.actions.toggleCreateVehicleIsOpen())
          }
          color='secondary'
          loading={vehiclesLoading}
          style={{ width: '100%', marginTop: 10 }}
        />
      </Grid>
      <Modal
        open={deleteVehicleIsOpen}
        onClose={() => {
          dispatch(vehiclesSlice.actions.toggleDeleteVehicleIsOpen());
        }}
        title='Deseja deletar o veículo?'
      >
        <StyledModalWrapper>
          <Typography
            color='primary'
            m={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {vehicleOnFocus && selectType(vehicleOnFocus.type)}{' '}
            {vehicleOnFocus?.plate}
          </Typography>
          <Button
            variant='contained'
            label='Deletar'
            type='submit'
            onClick={handleDeleteVehicle}
            color='secondary'
            loading={vehiclesLoading}
            style={{ width: '100%' }}
          />
        </StyledModalWrapper>
      </Modal>
      <CreateVehicleModal />
    </ContentBox>
  );
};
