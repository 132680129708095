import { Grid, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { findIndex, map, split, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ContentBox } from '../../components';
import {
  fetchGetHistoryList,
  fetchGetIrregularityByPlate,
  irregularitiesSlice,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { IrregularityCard } from './IrregularityCard';
import { TicketCard } from './TicketCard';

export const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { history, historyLoading } = useSelector(
    (state) => state.historyReducer,
  );
  const { irregularityList, irregularityListText, irregularitiesLoading } =
    useSelector((state) => state.irregularitiesReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const [selectedVehicleIdx, setSelectedVehicleIdx] = useState(0);

  useEffect(() => {
    dispatch(
      fetchGetHistoryList({
        driverId: driverId.toString(),
        reset: true,
        size: 3,
      }),
    );
  }, []);

  useEffect(() => {
    vehicles.length > 0
      ? dispatch(
          fetchGetIrregularityByPlate({
            driverId: driverId.toString(),
            plate: vehicles[selectedVehicleIdx].plate,
          }),
        )
      : dispatch(irregularitiesSlice.actions.resetIrregularitiesList());
  }, [selectedVehicleIdx]);

  return (
    <ContentBox>
      <Grid
        container
        sx={{
          marginTop: {
            xs: '20px',
            sm: 0,
          },
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            sm={9}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '5vw', sm: '18px' }, fontWeight: 'bold' }}
            >
              Irregularidades do veículo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Select
              value={
                vehicles.length > 0
                  ? `${vehicles[selectedVehicleIdx].plate} (${vehicles[selectedVehicleIdx].type})`
                  : 0
              }
              onChange={(e) => {
                if (e && vehicles.length > 0) {
                  const idx = findIndex(vehicles, [
                    'plate',
                    trim(split(e.target.value.toString(), '(')[0]),
                  ]);
                  setSelectedVehicleIdx(idx);
                }
              }}
              sx={{ width: '100%' }}
            >
              {vehicles.length > 0 ? (
                map(vehicles, (item) => {
                  return (
                    <MenuItem value={`${item.plate} (${item.type})`}>
                      {item.plate} ({item.type})
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem value={0}>Nenhum veículo encontrado</MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {irregularityListText && (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                color='text'
                sx={{
                  marginBottom: '10px',
                  fontSize: { xs: '3.5vw', sm: '14px' },
                }}
              >
                {irregularityListText}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            sx={{
              width: '100%',
              minHeight: '150px',
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              borderRadius: '10px',
              backgroundColor: palette.grey[200],
              paddingBottom: irregularityList.length > 0 ? '10px' : 0,
            }}
          >
            {irregularitiesLoading ? (
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <IrregularityCard item={undefined} />
                </Skeleton>
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <IrregularityCard item={undefined} />
                </Skeleton>
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <IrregularityCard item={undefined} />
                </Skeleton>
              </Grid>
            ) : irregularityList.length > 0 ? (
              map(irregularityList, (item) => {
                if (item) {
                  return <IrregularityCard item={item} />;
                }
              })
            ) : (
              <Grid
                item
                sx={{
                  width: '100%',
                  height: '100%',
                  minHeight: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color='text'
                  sx={{
                    textAlign: 'center',
                    fontSize: { xs: '3.5vw', sm: '14px' },
                  }}
                >
                  Nenhuma encontrada
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '30px',
              marginBottom: '10px',
            }}
          >
            <Typography
              color='primary'
              sx={{ fontSize: { xs: '5vw', sm: '18px' }, fontWeight: 'bold' }}
            >
              Últimos três tickets adquiridos
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            sx={{
              width: '100%',
              display: 'flex',
              flexdirection: 'row',
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              borderRadius: '10px',
              backgroundColor: palette.grey[200],
              paddingBottom: history.length > 0 ? '10px' : 0,
            }}
          >
            {historyLoading ? (
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <TicketCard item={undefined} />
                </Skeleton>
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <TicketCard item={undefined} />
                </Skeleton>
                <Skeleton
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <TicketCard item={undefined} />
                </Skeleton>
              </Grid>
            ) : history.length > 0 ? (
              map(history, (item) => {
                if (item) {
                  return <TicketCard item={item} />;
                }
              })
            ) : (
              <Grid
                item
                sx={{
                  width: '100%',
                  height: '100%',
                  minHeight: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color='text'
                  sx={{
                    textAlign: 'center',
                    fontSize: { xs: '3.5vw', sm: '14px' },
                  }}
                >
                  Nenhum encontrado
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ContentBox>
  );
};
