// All user related database operations can be defined here.
import { api } from '..';
import {
  CreatePaymentMethodResponse,
  SetDefaultPaymentMethodResponse,
  DeletePaymentMethodResponse,
  GetPaymentMethodListResponse,
  GetBankListResponse,
} from './types';

export * from './types';

export const fetchCreatePaymentMethodService = async (params: {
  driverId: string;
  number: string;
  cvv: string;
  brand: string;
  validity: string;
  holder: string;
  driverName: string;
  bankId: number;
}) => {
  const { driverId, ...otherParams } = params;
  return await api
    .post<CreatePaymentMethodResponse>(
      `drivers/${driverId}/paymentmethods`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchSetDefaultPaymentMethodService = async (params: {
  driverId: string;
  paymentMethodId: string;
}) => {
  const { driverId, paymentMethodId } = params;
  return await api
    .put<SetDefaultPaymentMethodResponse>(
      `drivers/${driverId}/paymentmethods/${paymentMethodId}/default`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchDeletePaymentMethodService = async (params: {
  driverId: string;
  paymentMethodId: string;
}) => {
  const { driverId, paymentMethodId } = params;
  return await api
    .delete<DeletePaymentMethodResponse>(
      `drivers/${driverId}/paymentmethods/${paymentMethodId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetPaymentMethodListService = async (params: {
  driverId: string;
}) => {
  const { driverId } = params;
  return await api
    .get<GetPaymentMethodListResponse>(`drivers/${driverId}/paymentmethods`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetBankListService = async (params: { driverId: string }) => {
  const { driverId } = params;
  return await api
    .get<GetBankListResponse>(`drivers/${driverId}/paymentmethods/banks`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
