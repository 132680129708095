import { Typography, Grid, Paper, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { drawerWidth, ticketBarHeight } from '..';
import { useNavigate } from 'react-router-dom';
import { TICKET_PURCHASE } from '../../../routes';
import {
  fetchOpenPix,
  fetchOpenTicket,
  ticketPurchaseSlice,
  useDispatch,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { formatSecsToFullTime } from '../../../utils';
import { Button } from '../../Button';

export const TicketBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { openTicketData, loadingOpenTicket, ticketRenovate } = useSelector(
    (state) => state.ticketPurchaseReducer,
  );
  const { hasOpenPix } = useSelector((state) => state.pixReducer);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    dispatch(fetchOpenTicket({ driverId: driverId.toString() })).then(() => {
      dispatch(fetchOpenPix({ driverId: driverId.toString() }));
    });

    const unsubscribe = window.addEventListener('focus', () => {
      dispatch(fetchOpenTicket({ driverId: driverId.toString() })).then(() => {
        dispatch(fetchOpenPix({ driverId: driverId.toString() }));
      });
    });

    return () => unsubscribe;
  }, []);

  useEffect(() => {
    if (openTicketData) {
      const now = openTicketData?.serverDateTime
        ? moment(openTicketData?.serverDateTime)
        : moment();
      if (openTicketData.exitDateTime) {
        const expirationTime = parseInt(
          moment
            .duration(moment(openTicketData.exitDateTime).diff(now))
            .asSeconds()
            .toString(),
          10,
        );

        dispatch(ticketPurchaseSlice.actions.handleCountdown(true));
        setTimeLeft(expirationTime);
        dispatch(ticketPurchaseSlice.actions.setTimeLeft(expirationTime));
      } else {
        setTimeLeft(0);
        dispatch(ticketPurchaseSlice.actions.setTimeLeft(0));
      }
    }
  }, [openTicketData?.exitDateTime, openTicketData?.serverDateTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = timeLeft - 1;
      setTimeLeft(newTimeLeft);
      dispatch(ticketPurchaseSlice.actions.setTimeLeft(newTimeLeft));
    }, 1000);

    if (timeLeft <= 0) {
      dispatch(ticketPurchaseSlice.actions.handleCountdown(false));
      clearInterval(intervalId);
      return setTimeLeft(0);
    }

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (openTicketData && openTicketData.exitDateTime) {
      const now = openTicketData?.serverDateTime
        ? moment(openTicketData?.serverDateTime)
        : moment();
      const renovationCondition =
        openTicketData?.exitDateTime &&
        moment(openTicketData?.exitDateTime).isBefore(moment(now));

      if (renovationCondition) {
        dispatch(ticketPurchaseSlice.actions.setRenovate(renovationCondition));
      }
    } else {
      dispatch(ticketPurchaseSlice.actions.setRenovate(false));
    }
  }, [openTicketData?.exitDateTime, openTicketData?.serverDateTime]);

  const handleRenewTicket = () => {
    dispatch(ticketPurchaseSlice.actions.setNavigatingRenewTicket(true));
    dispatch(ticketPurchaseSlice.actions.setRenewTicket(true));
    navigate(TICKET_PURCHASE);
  };

  return timeLeft > 0 || ticketRenovate ? (
    <Grid
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)`, xs: '100%' },
        position: 'absolute',
        top: { sm: 64, xs: 56 },
        right: 0,
        borderRadius: 0,
      }}
    >
      {timeLeft > 0 ? (
        <Paper
          sx={{
            backgroundColor: palette.secondary.main,
            borderRadius: 0,
            height: ticketBarHeight,
            boxShadow: hasOpenPix ? 0 : `10px 10px 10px 1px rgba(0, 0, 0, 0.3)`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 10px 0 10px',
          }}
        >
          <Typography
            color='white'
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '18px',
              },
            }}
          >
            Tempo para o ticket expirar:&#8287;
          </Typography>
          <Typography
            color='white'
            sx={{
              fontWeight: 'bold',
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '18px',
              },
            }}
          >
            {loadingOpenTicket ? (
              <CircularProgress size={16} sx={{ color: 'white' }} />
            ) : (
              formatSecsToFullTime(timeLeft)
            )}
          </Typography>
        </Paper>
      ) : (
        <Paper
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: 0,
            height: ticketBarHeight,
            boxShadow: hasOpenPix ? 0 : `10px 10px 20px 5px rgba(0, 0, 0, 0.3)`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 10px 0 10px',
          }}
        >
          <Typography
            color='white'
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '18px',
              },
            }}
          >
            Deseja renovar seu ticket?
          </Typography>
          <Button
            variant='contained'
            label='Renovar ticket'
            onClick={handleRenewTicket}
            color='secondary'
          />
        </Paper>
      )}
    </Grid>
  ) : null;
};
