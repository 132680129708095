/* eslint-disable pii/no-phone-number */
import { Box, LinearProgress, TextField } from '@mui/material';
import { toUpper } from 'lodash';
import React, { useEffect, useState } from 'react';
import VMasker from 'vanilla-masker';
import { palette } from '../../theme';

export const Input = (props: {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  mapRef?: React.RefObject<HTMLDivElement>;
  counter?: boolean;
  passwordStrength?: number;
  mask?:
    | 'email'
    | 'phone'
    | 'cpf'
    | 'irregularityCode'
    | 'plate'
    | 'cardNumber'
    | 'cardValidity'
    | 'cardCVV'
    | 'date';
  [x: string]: any;
}) => {
  const {
    value,
    onChange,
    errorMessage,
    shrink,
    onClick,
    margin,
    mapRef,
    variant,
    counter = false,
    onFocus,
    onBlur,
    passwordStrength,
    mask,
    ...otherProps
  } = props;

  const handleValueMask = (value: string) => {
    if (!mask) return value;
    else {
      switch (mask) {
        case 'email':
          return value;
        case 'phone':
          if (value.length > 14) {
            return VMasker.toPattern(value.slice(0, -1), '(99)99999-9999');
          }
          return VMasker.toPattern(value, '(99)99999-9999');
        case 'cpf':
          if (value.length > 14) {
            return VMasker.toPattern(value.slice(0, -1), '999.999.999-99');
          }
          return VMasker.toPattern(value, '999.999.999-99');
        case 'irregularityCode':
          return VMasker.toPattern(value, '9999999999999999999999999');
        case 'plate':
          if (value.length > 7) {
            return toUpper(VMasker.toPattern(value.slice(0, -1), 'AAA9S99'));
          }
          return toUpper(VMasker.toPattern(value, 'AAA9S99'));
        case 'cardNumber':
          if (value.length > 19) {
            return VMasker.toPattern(value.slice(0, -1), '9999 9999 9999 9999');
          }
          return VMasker.toPattern(value, '9999 9999 9999 9999');
        case 'cardValidity':
          if (value.length > 5) {
            return VMasker.toPattern(value.slice(0, -1), '99/99');
          }
          return VMasker.toPattern(value, '99/99');
        case 'cardCVV':
          if (value.length > 3) {
            return VMasker.toPattern(value.slice(0, -1), '999');
          }
          return VMasker.toPattern(value, '999');
        case 'date':
          if (value.length > 10) {
            return VMasker.toPattern(value.slice(0, -1), '99/99/9999');
          }
          return VMasker.toPattern(value, '99/99/9999');
        default:
          return value;
      }
    }
  };

  const [handledValue, setHandledValue] = useState(handleValueMask(value));
  const [visible, setVisible] = useState(false);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState<
    'error' | 'secondary' | 'warning' | 'success' | 'primary'
  >('primary');
  const [hasText, setHasText] = useState(false);

  if (counter && !props.inputProps?.maxLength) {
    throw new Error('counter needs maxLength to be set on inputProps');
  }
  if (counter && props.type !== 'text') {
    throw new Error('invalid input type');
  }

  useEffect(() => {
    if (passwordStrength) {
      if (passwordStrength === 0) {
        setPasswordStrengthValue(0);
        setPasswordStrengthColor('primary');
      } else if (passwordStrength === 1) {
        setPasswordStrengthValue(25);
        setPasswordStrengthColor('error');
      } else if (passwordStrength === 2) {
        setPasswordStrengthValue(50);
        setPasswordStrengthColor('warning');
      } else if (passwordStrength === 3) {
        setPasswordStrengthValue(75);
        setPasswordStrengthColor('success');
      } else {
        setPasswordStrengthValue(100);
        setPasswordStrengthColor('success');
      }
    } else {
      setPasswordStrengthValue(0);
      setPasswordStrengthColor('primary');
    }
  }, [passwordStrength]);

  useEffect(() => {
    setHandledValue(handleValueMask(value));
  }, [value]);

  return (
    <>
      <TextField
        ref={mapRef}
        onChange={(e) => {
          if (passwordStrength) {
            e.target.value.length > 0 ? setHasText(true) : setHasText(false);
          }
          setHandledValue(handleValueMask(e.target.value));
          onChange && onChange(handleValueMask(e.target.value));
        }}
        color='primary'
        fullWidth
        variant={variant ? 'outlined' : 'standard'}
        size='small'
        margin={margin === undefined ? 'normal' : margin}
        error={errorMessage !== undefined}
        onFocus={(event) => {
          setVisible(true);
          onFocus && onFocus(event);
        }}
        onBlur={(event) => {
          setVisible(false);
          onBlur && onBlur(event);
        }}
        helperText={
          <Box
            component='span'
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <span>{errorMessage}</span>
            {visible && counter && (
              <span>
                {`${(props.value as string).length} / ${
                  props.inputProps.maxLength
                }`}
              </span>
            )}
          </Box>
        }
        InputLabelProps={{ shrink: shrink }}
        onClick={onClick}
        style={{ height: 40 }}
        value={handledValue}
        {...otherProps}
      />
      {passwordStrength !== undefined && (
        // hasText && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant='determinate'
            color={passwordStrengthColor}
            value={passwordStrengthValue}
            sx={{
              height: '2px',
            }}
          />
        </Box>
      )}
    </>
  );
};
